export function GesturePrimitives(){
    return [
        {
            "key": "a07ff089-2ca2-1341-cc58-74509f1d8577",
            "gesture": [
              {
                "thumb0": {
                  "x": 0.23995332419872284,
                  "y": 1.5958237648010254,
                  "z": -0.08416889607906342
                },
                "index0": {
                  "x": 0.19980105757713318,
                  "y": 1.704073190689087,
                  "z": -0.12006179988384247
                },
                "middle0": {
                  "x": 0.21477162837982178,
                  "y": 1.722002387046814,
                  "z": -0.15168854594230652
                },
                "ring0": {
                  "x": 0.22610649466514587,
                  "y": 1.7120800018310547,
                  "z": -0.18100009858608246
                },
                "pinky0": {
                  "x": 0.2393585592508316,
                  "y": 1.6829904317855835,
                  "z": -0.21828502416610718
                },
                "thumb1": {
                  "x": 0.25181740522384644,
                  "y": 1.593376636505127,
                  "z": 0.04306342825293541
                },
                "index1": {
                  "x": 0.26296424865722656,
                  "y": 1.7049585580825806,
                  "z": 0.0777403712272644
                },
                "middle1": {
                  "x": 0.28877729177474976,
                  "y": 1.723021149635315,
                  "z": 0.0833616554737091
                },
                "ring1": {
                  "x": 0.31361252069473267,
                  "y": 1.7169990539550781,
                  "z": 0.09806832671165466
                },
                "pinky1": {
                  "x": 0.3477979600429535,
                  "y": 1.695521354675293,
                  "z": 0.11622896045446396
                }
              },
              {
                "thumb0": {
                  "x": 0.24370619654655457,
                  "y": 1.5902003049850464,
                  "z": -0.07618051767349243
                },
                "index0": {
                  "x": 0.20877808332443237,
                  "y": 1.698988914489746,
                  "z": -0.11038770526647568
                },
                "middle0": {
                  "x": 0.2216324657201767,
                  "y": 1.717925786972046,
                  "z": -0.14301835000514984
                },
                "ring0": {
                  "x": 0.23262718319892883,
                  "y": 1.708653450012207,
                  "z": -0.17275960743427277
                },
                "pinky0": {
                  "x": 0.24674662947654724,
                  "y": 1.6798622608184814,
                  "z": -0.20975422859191895
                },
                "thumb1": {
                  "x": 0.25460436940193176,
                  "y": 1.5871005058288574,
                  "z": 0.039113715291023254
                },
                "index1": {
                  "x": 0.2682441771030426,
                  "y": 1.700331211090088,
                  "z": 0.07050853967666626
                },
                "middle1": {
                  "x": 0.29356649518013,
                  "y": 1.718707799911499,
                  "z": 0.07674102485179901
                },
                "ring1": {
                  "x": 0.3188649117946625,
                  "y": 1.713046669960022,
                  "z": 0.09157843887805939
                },
                "pinky1": {
                  "x": 0.35694020986557007,
                  "y": 1.6899404525756836,
                  "z": 0.10668466985225677
                }
              },
              {
                "thumb0": {
                  "x": 0.24504004418849945,
                  "y": 1.5865801572799683,
                  "z": -0.07828991860151291
                },
                "index0": {
                  "x": 0.21470436453819275,
                  "y": 1.6957170963287354,
                  "z": -0.10994153469800949
                },
                "middle0": {
                  "x": 0.22738167643547058,
                  "y": 1.7147160768508911,
                  "z": -0.14302697777748108
                },
                "ring0": {
                  "x": 0.2375929057598114,
                  "y": 1.7054800987243652,
                  "z": -0.17375481128692627
                },
                "pinky0": {
                  "x": 0.24962584674358368,
                  "y": 1.6765470504760742,
                  "z": -0.21117202937602997
                },
                "thumb1": {
                  "x": 0.25706762075424194,
                  "y": 1.5825917720794678,
                  "z": 0.03651756793260574
                },
                "index1": {
                  "x": 0.27359017729759216,
                  "y": 1.6948989629745483,
                  "z": 0.059467971324920654
                },
                "middle1": {
                  "x": 0.2965897023677826,
                  "y": 1.7141730785369873,
                  "z": 0.06784546375274658
                },
                "ring1": {
                  "x": 0.3214171826839447,
                  "y": 1.7094756364822388,
                  "z": 0.08320196717977524
                },
                "pinky1": {
                  "x": 0.360490620136261,
                  "y": 1.6855833530426025,
                  "z": 0.09649492055177689
                }
              },
              {
                "thumb0": {
                  "x": 0.24047327041625977,
                  "y": 1.5863975286483765,
                  "z": -0.08172611147165298
                },
                "index0": {
                  "x": 0.22021692991256714,
                  "y": 1.6956475973129272,
                  "z": -0.1127355694770813
                },
                "middle0": {
                  "x": 0.23833394050598145,
                  "y": 1.7121399641036987,
                  "z": -0.1427527815103531
                },
                "ring0": {
                  "x": 0.2473635971546173,
                  "y": 1.703217625617981,
                  "z": -0.17374613881111145
                },
                "pinky0": {
                  "x": 0.2578275501728058,
                  "y": 1.674399495124817,
                  "z": -0.21064238250255585
                },
                "thumb1": {
                  "x": 0.2609111964702606,
                  "y": 1.582842230796814,
                  "z": 0.03884062170982361
                },
                "index1": {
                  "x": 0.28023603558540344,
                  "y": 1.6929445266723633,
                  "z": 0.05477127805352211
                },
                "middle1": {
                  "x": 0.3023146092891693,
                  "y": 1.7118785381317139,
                  "z": 0.06185724213719368
                },
                "ring1": {
                  "x": 0.32540765404701233,
                  "y": 1.7086724042892456,
                  "z": 0.07840651273727417
                },
                "pinky1": {
                  "x": 0.36100828647613525,
                  "y": 1.6857985258102417,
                  "z": 0.09311661869287491
                }
              },
              {
                "thumb0": {
                  "x": 0.18623045086860657,
                  "y": 1.6182585954666138,
                  "z": -0.09030572324991226
                },
                "index0": {
                  "x": 0.2596980929374695,
                  "y": 1.6880847215652466,
                  "z": -0.11884863674640656
                },
                "middle0": {
                  "x": 0.2844415307044983,
                  "y": 1.6116282939910889,
                  "z": -0.11211983859539032
                },
                "ring0": {
                  "x": 0.2941262125968933,
                  "y": 1.6001598834991455,
                  "z": -0.12642718851566315
                },
                "pinky0": {
                  "x": 0.30169862508773804,
                  "y": 1.5951200723648071,
                  "z": -0.15604804456233978
                },
                "thumb1": {
                  "x": 0.2504395842552185,
                  "y": 1.6031547784805298,
                  "z": 0.06298936158418655
                },
                "index1": {
                  "x": 0.30667635798454285,
                  "y": 1.689581274986267,
                  "z": 0.05033167824149132
                },
                "middle1": {
                  "x": 0.33372199535369873,
                  "y": 1.6263189315795898,
                  "z": 0.02936212718486786
                },
                "ring1": {
                  "x": 0.3495161831378937,
                  "y": 1.615344762802124,
                  "z": 0.03443818539381027
                },
                "pinky1": {
                  "x": 0.37133651971817017,
                  "y": 1.6165893077850342,
                  "z": 0.051553621888160706
                }
              },
              {
                "thumb0": {
                  "x": 0.18247689306735992,
                  "y": 1.6630594730377197,
                  "z": -0.14796343445777893
                },
                "index0": {
                  "x": 0.2824251055717468,
                  "y": 1.6523454189300537,
                  "z": -0.10429942607879639
                },
                "middle0": {
                  "x": 0.2462557703256607,
                  "y": 1.5784530639648438,
                  "z": -0.11530320346355438
                },
                "ring0": {
                  "x": 0.24646231532096863,
                  "y": 1.5679419040679932,
                  "z": -0.1331493854522705
                },
                "pinky0": {
                  "x": 0.25535011291503906,
                  "y": 1.561638355255127,
                  "z": -0.14877232909202576
                },
                "thumb1": {
                  "x": 0.26086679100990295,
                  "y": 1.648224115371704,
                  "z": 0.09550362825393677
                },
                "index1": {
                  "x": 0.32945311069488525,
                  "y": 1.6562916040420532,
                  "z": 0.01758495718240738
                },
                "middle1": {
                  "x": 0.31056836247444153,
                  "y": 1.5716512203216553,
                  "z": 0.06631799042224884
                },
                "ring1": {
                  "x": 0.32137665152549744,
                  "y": 1.5662968158721924,
                  "z": 0.08183881640434265
                },
                "pinky1": {
                  "x": 0.3359701931476593,
                  "y": 1.5632073879241943,
                  "z": 0.08003212511539459
                }
              },
              {
                "thumb0": {
                  "x": 0.19922155141830444,
                  "y": 1.6636126041412354,
                  "z": -0.16411806643009186
                },
                "index0": {
                  "x": 0.27980777621269226,
                  "y": 1.6247096061706543,
                  "z": -0.08684348315000534
                },
                "middle0": {
                  "x": 0.22578616440296173,
                  "y": 1.5673481225967407,
                  "z": -0.11901845782995224
                },
                "ring0": {
                  "x": 0.2309539020061493,
                  "y": 1.56013822555542,
                  "z": -0.14543992280960083
                },
                "pinky0": {
                  "x": 0.24298329651355743,
                  "y": 1.5527561902999878,
                  "z": -0.157830610871315
                },
                "thumb1": {
                  "x": 0.2909197509288788,
                  "y": 1.665655493736267,
                  "z": 0.10910478234291077
                },
                "index1": {
                  "x": 0.31746646761894226,
                  "y": 1.6227326393127441,
                  "z": 0.00803644210100174
                },
                "middle1": {
                  "x": 0.296825647354126,
                  "y": 1.5695551633834839,
                  "z": 0.08697734028100967
                },
                "ring1": {
                  "x": 0.3173992931842804,
                  "y": 1.5658963918685913,
                  "z": 0.09769344329833984
                },
                "pinky1": {
                  "x": 0.32789504528045654,
                  "y": 1.5568134784698486,
                  "z": 0.09504315257072449
                }
              },
              {
                "thumb0": {
                  "x": 0.209577739238739,
                  "y": 1.652780294418335,
                  "z": -0.1580934375524521
                },
                "index0": {
                  "x": 0.2860221862792969,
                  "y": 1.597515344619751,
                  "z": -0.07692575454711914
                },
                "middle0": {
                  "x": 0.22587823867797852,
                  "y": 1.5505115985870361,
                  "z": -0.11231377720832825
                },
                "ring0": {
                  "x": 0.22942952811717987,
                  "y": 1.5442088842391968,
                  "z": -0.13915663957595825
                },
                "pinky0": {
                  "x": 0.2402036339044571,
                  "y": 1.5364559888839722,
                  "z": -0.15182429552078247
                },
                "thumb1": {
                  "x": 0.30594170093536377,
                  "y": 1.6647192239761353,
                  "z": 0.11125825345516205
                },
                "index1": {
                  "x": 0.3149571418762207,
                  "y": 1.6042588949203491,
                  "z": 0.004136905074119568
                },
                "middle1": {
                  "x": 0.2808716893196106,
                  "y": 1.5660362243652344,
                  "z": 0.07918918132781982
                },
                "ring1": {
                  "x": 0.31077030301094055,
                  "y": 1.560575246810913,
                  "z": 0.09778453409671783
                },
                "pinky1": {
                  "x": 0.3207751214504242,
                  "y": 1.5490788221359253,
                  "z": 0.09829095005989075
                }
              },
              {
                "thumb0": {
                  "x": 0.21987281739711761,
                  "y": 1.652854323387146,
                  "z": -0.14071771502494812
                },
                "index0": {
                  "x": 0.29311123490333557,
                  "y": 1.5909712314605713,
                  "z": -0.05847056955099106
                },
                "middle0": {
                  "x": 0.23177462816238403,
                  "y": 1.5466716289520264,
                  "z": -0.09137439727783203
                },
                "ring0": {
                  "x": 0.23318779468536377,
                  "y": 1.5403133630752563,
                  "z": -0.11895362287759781
                },
                "pinky0": {
                  "x": 0.244620680809021,
                  "y": 1.5335313081741333,
                  "z": -0.13496115803718567
                },
                "thumb1": {
                  "x": 0.298623263835907,
                  "y": 1.6552621126174927,
                  "z": 0.09862084686756134
                },
                "index1": {
                  "x": 0.31055229902267456,
                  "y": 1.5908459424972534,
                  "z": -0.008199557662010193
                },
                "middle1": {
                  "x": 0.2745099365711212,
                  "y": 1.5543694496154785,
                  "z": 0.06819243729114532
                },
                "ring1": {
                  "x": 0.30741673707962036,
                  "y": 1.5505645275115967,
                  "z": 0.08735568076372147
                },
                "pinky1": {
                  "x": 0.32006382942199707,
                  "y": 1.5397701263427734,
                  "z": 0.08819735795259476
                }
              },
              {
                "thumb0": {
                  "x": 0.22252391278743744,
                  "y": 1.654438853263855,
                  "z": -0.12760555744171143
                },
                "index0": {
                  "x": 0.2958029806613922,
                  "y": 1.5934406518936157,
                  "z": -0.044816210865974426
                },
                "middle0": {
                  "x": 0.2324344664812088,
                  "y": 1.547163724899292,
                  "z": -0.07702488452196121
                },
                "ring0": {
                  "x": 0.23375482857227325,
                  "y": 1.5401618480682373,
                  "z": -0.10325421392917633
                },
                "pinky0": {
                  "x": 0.2457607239484787,
                  "y": 1.5337903499603271,
                  "z": -0.1204204335808754
                },
                "thumb1": {
                  "x": 0.2930963337421417,
                  "y": 1.6567940711975098,
                  "z": 0.09172926098108292
                },
                "index1": {
                  "x": 0.30326947569847107,
                  "y": 1.5922584533691406,
                  "z": -0.017545394599437714
                },
                "middle1": {
                  "x": 0.2691842317581177,
                  "y": 1.5562490224838257,
                  "z": 0.062071237713098526
                },
                "ring1": {
                  "x": 0.29869696497917175,
                  "y": 1.550870656967163,
                  "z": 0.07703609764575958
                },
                "pinky1": {
                  "x": 0.31092408299446106,
                  "y": 1.5401217937469482,
                  "z": 0.07715499401092529
                }
              },
              {
                "thumb0": {
                  "x": 0.2198265641927719,
                  "y": 1.653117299079895,
                  "z": -0.11884091049432755
                },
                "index0": {
                  "x": 0.2923123240470886,
                  "y": 1.591392993927002,
                  "z": -0.0363449826836586
                },
                "middle0": {
                  "x": 0.2291828989982605,
                  "y": 1.5452547073364258,
                  "z": -0.07101281732320786
                },
                "ring0": {
                  "x": 0.2305757701396942,
                  "y": 1.5387006998062134,
                  "z": -0.0980716124176979
                },
                "pinky0": {
                  "x": 0.2435266077518463,
                  "y": 1.531983733177185,
                  "z": -0.11206982284784317
                },
                "thumb1": {
                  "x": 0.2848585844039917,
                  "y": 1.6517012119293213,
                  "z": 0.08580946177244186
                },
                "index1": {
                  "x": 0.30415695905685425,
                  "y": 1.5853086709976196,
                  "z": -0.021650664508342743
                },
                "middle1": {
                  "x": 0.2806800305843353,
                  "y": 1.550104022026062,
                  "z": 0.06264059245586395
                },
                "ring1": {
                  "x": 0.3045782148838043,
                  "y": 1.5465582609176636,
                  "z": 0.07563486695289612
                },
                "pinky1": {
                  "x": 0.31486037373542786,
                  "y": 1.5356595516204834,
                  "z": 0.07545806467533112
                }
              },
              {
                "thumb0": {
                  "x": 0.21478284895420074,
                  "y": 1.652726411819458,
                  "z": -0.12044134736061096
                },
                "index0": {
                  "x": 0.29023924469947815,
                  "y": 1.592119812965393,
                  "z": -0.04001861810684204
                },
                "middle0": {
                  "x": 0.2252311408519745,
                  "y": 1.5442649126052856,
                  "z": -0.07663123309612274
                },
                "ring0": {
                  "x": 0.22774936258792877,
                  "y": 1.539952039718628,
                  "z": -0.10797666013240814
                },
                "pinky0": {
                  "x": 0.24040508270263672,
                  "y": 1.5322319269180298,
                  "z": -0.11782311648130417
                },
                "thumb1": {
                  "x": 0.28830090165138245,
                  "y": 1.653035044670105,
                  "z": 0.08648869395256042
                },
                "index1": {
                  "x": 0.30335965752601624,
                  "y": 1.5885519981384277,
                  "z": -0.017966851592063904
                },
                "middle1": {
                  "x": 0.28714945912361145,
                  "y": 1.551148772239685,
                  "z": 0.06695068627595901
                },
                "ring1": {
                  "x": 0.30981680750846863,
                  "y": 1.5480760335922241,
                  "z": 0.07961253076791763
                },
                "pinky1": {
                  "x": 0.3192996084690094,
                  "y": 1.536694049835205,
                  "z": 0.0793456882238388
                }
              },
              {
                "thumb0": {
                  "x": 0.16469663381576538,
                  "y": 1.6542372703552246,
                  "z": -0.21162304282188416
                },
                "index0": {
                  "x": 0.24971409142017365,
                  "y": 1.611079216003418,
                  "z": -0.13304269313812256
                },
                "middle0": {
                  "x": 0.18963198363780975,
                  "y": 1.553950548171997,
                  "z": -0.1595032811164856
                },
                "ring0": {
                  "x": 0.1899402141571045,
                  "y": 1.5444815158843994,
                  "z": -0.18184028565883636
                },
                "pinky0": {
                  "x": 0.20027503371238708,
                  "y": 1.538273572921753,
                  "z": -0.19808602333068848
                },
                "thumb1": {
                  "x": 0.2903716564178467,
                  "y": 1.6701871156692505,
                  "z": 0.17278680205345154
                },
                "index1": {
                  "x": 0.310501366853714,
                  "y": 1.615847110748291,
                  "z": 0.06566806882619858
                },
                "middle1": {
                  "x": 0.27803871035575867,
                  "y": 1.565381646156311,
                  "z": 0.1422412395477295
                },
                "ring1": {
                  "x": 0.3063206970691681,
                  "y": 1.5650018453598022,
                  "z": 0.1637183576822281
                },
                "pinky1": {
                  "x": 0.3201272785663605,
                  "y": 1.5556217432022095,
                  "z": 0.16378234326839447
                }
              },
              {
                "thumb0": {
                  "x": 0.09672477096319199,
                  "y": 1.6363574266433716,
                  "z": -0.316522479057312
                },
                "index0": {
                  "x": 0.1892681121826172,
                  "y": 1.6179543733596802,
                  "z": -0.2268587350845337
                },
                "middle0": {
                  "x": 0.12629717588424683,
                  "y": 1.5516772270202637,
                  "z": -0.24500519037246704
                },
                "ring0": {
                  "x": 0.13806989789009094,
                  "y": 1.542824387550354,
                  "z": -0.2831747531890869
                },
                "pinky0": {
                  "x": 0.14686504006385803,
                  "y": 1.5322260856628418,
                  "z": -0.2836499512195587
                },
                "thumb1": {
                  "x": 0.30581754446029663,
                  "y": 1.6820292472839355,
                  "z": 0.2530542016029358
                },
                "index1": {
                  "x": 0.3268841505050659,
                  "y": 1.633985996246338,
                  "z": 0.1368933618068695
                },
                "middle1": {
                  "x": 0.2796705365180969,
                  "y": 1.583046555519104,
                  "z": 0.20184409618377686
                },
                "ring1": {
                  "x": 0.3134368360042572,
                  "y": 1.5779621601104736,
                  "z": 0.23070192337036133
                },
                "pinky1": {
                  "x": 0.33060625195503235,
                  "y": 1.5682979822158813,
                  "z": 0.23201371729373932
                }
              },
              {
                "thumb0": {
                  "x": 0.1015843003988266,
                  "y": 1.6402497291564941,
                  "z": -0.3046759366989136
                },
                "index0": {
                  "x": 0.19874300062656403,
                  "y": 1.622697114944458,
                  "z": -0.2238083779811859
                },
                "middle0": {
                  "x": 0.13525624573230743,
                  "y": 1.5583709478378296,
                  "z": -0.24069716036319733
                },
                "ring0": {
                  "x": 0.14184458553791046,
                  "y": 1.5468350648880005,
                  "z": -0.2681128680706024
                },
                "pinky0": {
                  "x": 0.1528197079896927,
                  "y": 1.537139892578125,
                  "z": -0.2748986482620239
                },
                "thumb1": {
                  "x": 0.30672234296798706,
                  "y": 1.6802570819854736,
                  "z": 0.25804537534713745
                },
                "index1": {
                  "x": 0.32778891921043396,
                  "y": 1.632213830947876,
                  "z": 0.14188453555107117
                },
                "middle1": {
                  "x": 0.28057533502578735,
                  "y": 1.581274390220642,
                  "z": 0.20683526992797852
                },
                "ring1": {
                  "x": 0.31434163451194763,
                  "y": 1.5761898756027222,
                  "z": 0.235693097114563
                },
                "pinky1": {
                  "x": 0.3315110504627228,
                  "y": 1.5665258169174194,
                  "z": 0.23700487613677979
                }
              },
              {
                "thumb0": {
                  "x": 0.10752258449792862,
                  "y": 1.6420634984970093,
                  "z": -0.29305851459503174
                },
                "index0": {
                  "x": 0.20596937835216522,
                  "y": 1.619254469871521,
                  "z": -0.21392881870269775
                },
                "middle0": {
                  "x": 0.14130429923534393,
                  "y": 1.558595895767212,
                  "z": -0.23036974668502808
                },
                "ring0": {
                  "x": 0.14572899043560028,
                  "y": 1.5472290515899658,
                  "z": -0.25582438707351685
                },
                "pinky0": {
                  "x": 0.15738970041275024,
                  "y": 1.5377483367919922,
                  "z": -0.2675822377204895
                },
                "thumb1": {
                  "x": 0.30672234296798706,
                  "y": 1.6802570819854736,
                  "z": 0.25804537534713745
                },
                "index1": {
                  "x": 0.32778891921043396,
                  "y": 1.632213830947876,
                  "z": 0.14188453555107117
                },
                "middle1": {
                  "x": 0.28057533502578735,
                  "y": 1.581274390220642,
                  "z": 0.20683526992797852
                },
                "ring1": {
                  "x": 0.31434163451194763,
                  "y": 1.5761898756027222,
                  "z": 0.235693097114563
                },
                "pinky1": {
                  "x": 0.3315110504627228,
                  "y": 1.5665258169174194,
                  "z": 0.23700487613677979
                }
              },
              {
                "thumb0": {
                  "x": 0.10923321545124054,
                  "y": 1.641281247138977,
                  "z": -0.28420501947402954
                },
                "index0": {
                  "x": 0.20689885318279266,
                  "y": 1.6163337230682373,
                  "z": -0.2083427459001541
                },
                "middle0": {
                  "x": 0.14348137378692627,
                  "y": 1.5565658807754517,
                  "z": -0.22978799045085907
                },
                "ring0": {
                  "x": 0.15015283226966858,
                  "y": 1.5468541383743286,
                  "z": -0.2611083388328552
                },
                "pinky0": {
                  "x": 0.15957804024219513,
                  "y": 1.5362238883972168,
                  "z": -0.26833081245422363
                },
                "thumb1": {
                  "x": 0.30672234296798706,
                  "y": 1.6802570819854736,
                  "z": 0.25804537534713745
                },
                "index1": {
                  "x": 0.32778891921043396,
                  "y": 1.632213830947876,
                  "z": 0.14188453555107117
                },
                "middle1": {
                  "x": 0.28057533502578735,
                  "y": 1.581274390220642,
                  "z": 0.20683526992797852
                },
                "ring1": {
                  "x": 0.31434163451194763,
                  "y": 1.5761898756027222,
                  "z": 0.235693097114563
                },
                "pinky1": {
                  "x": 0.3315110504627228,
                  "y": 1.5665258169174194,
                  "z": 0.23700487613677979
                }
              },
              {
                "thumb0": {
                  "x": 0.1133016049861908,
                  "y": 1.6408745050430298,
                  "z": -0.2807304561138153
                },
                "index0": {
                  "x": 0.21061305701732635,
                  "y": 1.614707589149475,
                  "z": -0.20425261557102203
                },
                "middle0": {
                  "x": 0.14703790843486786,
                  "y": 1.5556493997573853,
                  "z": -0.22358402609825134
                },
                "ring0": {
                  "x": 0.1532643437385559,
                  "y": 1.5457803010940552,
                  "z": -0.24984295666217804
                },
                "pinky0": {
                  "x": 0.1636243462562561,
                  "y": 1.536078929901123,
                  "z": -0.26082026958465576
                },
                "thumb1": {
                  "x": 0.30672234296798706,
                  "y": 1.6802570819854736,
                  "z": 0.25804537534713745
                },
                "index1": {
                  "x": 0.32778891921043396,
                  "y": 1.632213830947876,
                  "z": 0.14188453555107117
                },
                "middle1": {
                  "x": 0.28057533502578735,
                  "y": 1.581274390220642,
                  "z": 0.20683526992797852
                },
                "ring1": {
                  "x": 0.31434163451194763,
                  "y": 1.5761898756027222,
                  "z": 0.235693097114563
                },
                "pinky1": {
                  "x": 0.3315110504627228,
                  "y": 1.5665258169174194,
                  "z": 0.23700487613677979
                }
              },
              {
                "thumb0": {
                  "x": 0.11872648447751999,
                  "y": 1.6404743194580078,
                  "z": -0.26914143562316895
                },
                "index0": {
                  "x": 0.21543417870998383,
                  "y": 1.6116944551467896,
                  "z": -0.19467252492904663
                },
                "middle0": {
                  "x": 0.15477001667022705,
                  "y": 1.5543047189712524,
                  "z": -0.20969265699386597
                },
                "ring0": {
                  "x": 0.15550880134105682,
                  "y": 1.5423128604888916,
                  "z": -0.23067860305309296
                },
                "pinky0": {
                  "x": 0.16637106239795685,
                  "y": 1.5341134071350098,
                  "z": -0.24840454757213593
                },
                "thumb1": {
                  "x": 0.30672234296798706,
                  "y": 1.6802570819854736,
                  "z": 0.25804537534713745
                },
                "index1": {
                  "x": 0.32778891921043396,
                  "y": 1.632213830947876,
                  "z": 0.14188453555107117
                },
                "middle1": {
                  "x": 0.28057533502578735,
                  "y": 1.581274390220642,
                  "z": 0.20683526992797852
                },
                "ring1": {
                  "x": 0.31434163451194763,
                  "y": 1.5761898756027222,
                  "z": 0.235693097114563
                },
                "pinky1": {
                  "x": 0.3315110504627228,
                  "y": 1.5665258169174194,
                  "z": 0.23700487613677979
                }
              },
              {
                "thumb0": {
                  "x": 0.12255408614873886,
                  "y": 1.640385627746582,
                  "z": -0.262523353099823
                },
                "index0": {
                  "x": 0.219424307346344,
                  "y": 1.609760046005249,
                  "z": -0.1915200799703598
                },
                "middle0": {
                  "x": 0.16731740534305573,
                  "y": 1.5561193227767944,
                  "z": -0.19758865237236023
                },
                "ring0": {
                  "x": 0.16338300704956055,
                  "y": 1.5417112112045288,
                  "z": -0.2178899049758911
                },
                "pinky0": {
                  "x": 0.16980965435504913,
                  "y": 1.533115267753601,
                  "z": -0.23957520723342896
                },
                "thumb1": {
                  "x": 0.30672234296798706,
                  "y": 1.6802570819854736,
                  "z": 0.25804537534713745
                },
                "index1": {
                  "x": 0.32778891921043396,
                  "y": 1.632213830947876,
                  "z": 0.14188453555107117
                },
                "middle1": {
                  "x": 0.28057533502578735,
                  "y": 1.581274390220642,
                  "z": 0.20683526992797852
                },
                "ring1": {
                  "x": 0.31434163451194763,
                  "y": 1.5761898756027222,
                  "z": 0.235693097114563
                },
                "pinky1": {
                  "x": 0.3315110504627228,
                  "y": 1.5665258169174194,
                  "z": 0.23700487613677979
                }
              },
              {
                "thumb0": {
                  "x": 0.13706618547439575,
                  "y": 1.6397193670272827,
                  "z": -0.2356521189212799
                },
                "index0": {
                  "x": 0.23805022239685059,
                  "y": 1.612567663192749,
                  "z": -0.19367021322250366
                },
                "middle0": {
                  "x": 0.2261343002319336,
                  "y": 1.568060040473938,
                  "z": -0.18660740554332733
                },
                "ring0": {
                  "x": 0.22642172873020172,
                  "y": 1.549657940864563,
                  "z": -0.19649237394332886
                },
                "pinky0": {
                  "x": 0.22369247674942017,
                  "y": 1.533351182937622,
                  "z": -0.21861818432807922
                },
                "thumb1": {
                  "x": 0.30672234296798706,
                  "y": 1.6802570819854736,
                  "z": 0.25804537534713745
                },
                "index1": {
                  "x": 0.32778891921043396,
                  "y": 1.632213830947876,
                  "z": 0.14188453555107117
                },
                "middle1": {
                  "x": 0.28057533502578735,
                  "y": 1.581274390220642,
                  "z": 0.20683526992797852
                },
                "ring1": {
                  "x": 0.31434163451194763,
                  "y": 1.5761898756027222,
                  "z": 0.235693097114563
                },
                "pinky1": {
                  "x": 0.3315110504627228,
                  "y": 1.5665258169174194,
                  "z": 0.23700487613677979
                }
              },
              {
                "thumb0": {
                  "x": 0.17405207455158234,
                  "y": 1.6053088903427124,
                  "z": -0.1771741807460785
                },
                "index0": {
                  "x": 0.24189679324626923,
                  "y": 1.641116976737976,
                  "z": -0.21948879957199097
                },
                "middle0": {
                  "x": 0.2643181085586548,
                  "y": 1.6352094411849976,
                  "z": -0.24449676275253296
                },
                "ring0": {
                  "x": 0.27197688817977905,
                  "y": 1.6086500883102417,
                  "z": -0.2640154957771301
                },
                "pinky0": {
                  "x": 0.2643716633319855,
                  "y": 1.5628209114074707,
                  "z": -0.2752828896045685
                },
                "thumb1": {
                  "x": 0.2718423306941986,
                  "y": 1.6242250204086304,
                  "z": 0.1959659308195114
                },
                "index1": {
                  "x": 0.3463129997253418,
                  "y": 1.6428537368774414,
                  "z": 0.14884619414806366
                },
                "middle1": {
                  "x": 0.37406402826309204,
                  "y": 1.639127254486084,
                  "z": 0.14560839533805847
                },
                "ring1": {
                  "x": 0.39551618695259094,
                  "y": 1.6262121200561523,
                  "z": 0.15904287993907928
                },
                "pinky1": {
                  "x": 0.40444329380989075,
                  "y": 1.5946118831634521,
                  "z": 0.18247440457344055
                }
              }
            ],
            "words": "line"
          },
          {
            "key": "4f871493-ebf4-80b5-fc2b-b387d4e73a0d",
            "gesture": [
              {
                "thumb0": {
                  "x": 0.17311471700668335,
                  "y": 1.6184148788452148,
                  "z": -0.10179133713245392
                },
                "index0": {
                  "x": 0.2149219661951065,
                  "y": 1.697919487953186,
                  "z": -0.132189080119133
                },
                "middle0": {
                  "x": 0.24282214045524597,
                  "y": 1.7073752880096436,
                  "z": -0.15074726939201355
                },
                "ring0": {
                  "x": 0.26029735803604126,
                  "y": 1.6939119100570679,
                  "z": -0.1754833161830902
                },
                "pinky0": {
                  "x": 0.27978017926216125,
                  "y": 1.6575521230697632,
                  "z": -0.20728152990341187
                },
                "thumb1": {
                  "x": 0.23362992703914642,
                  "y": 1.6286264657974243,
                  "z": 0.09577582031488419
                },
                "index1": {
                  "x": 0.28744688630104065,
                  "y": 1.7103230953216553,
                  "z": 0.07984559237957001
                },
                "middle1": {
                  "x": 0.31209126114845276,
                  "y": 1.7157225608825684,
                  "z": 0.06465339660644531
                },
                "ring1": {
                  "x": 0.3382515609264374,
                  "y": 1.702852725982666,
                  "z": 0.06671637296676636
                },
                "pinky1": {
                  "x": 0.3720453977584839,
                  "y": 1.6714608669281006,
                  "z": 0.07451916486024857
                }
              },
              {
                "thumb0": {
                  "x": 0.17043179273605347,
                  "y": 1.6361583471298218,
                  "z": -0.11504562199115753
                },
                "index0": {
                  "x": 0.24353010952472687,
                  "y": 1.6251575946807861,
                  "z": -0.12317029386758804
                },
                "middle0": {
                  "x": 0.24081650376319885,
                  "y": 1.5973292589187622,
                  "z": -0.1289738416671753
                },
                "ring0": {
                  "x": 0.25019925832748413,
                  "y": 1.5914490222930908,
                  "z": -0.13768506050109863
                },
                "pinky0": {
                  "x": 0.25706467032432556,
                  "y": 1.5850725173950195,
                  "z": -0.1521114557981491
                },
                "thumb1": {
                  "x": 0.24908339977264404,
                  "y": 1.643975853919983,
                  "z": 0.097342848777771
                },
                "index1": {
                  "x": 0.30949583649635315,
                  "y": 1.6542607545852661,
                  "z": 0.06598694622516632
                },
                "middle1": {
                  "x": 0.31429311633110046,
                  "y": 1.6206657886505127,
                  "z": 0.0592469684779644
                },
                "ring1": {
                  "x": 0.32467395067214966,
                  "y": 1.6113148927688599,
                  "z": 0.052304528653621674
                },
                "pinky1": {
                  "x": 0.3423164486885071,
                  "y": 1.6081267595291138,
                  "z": 0.054395657032728195
                }
              },
              {
                "thumb0": {
                  "x": 0.19333010911941528,
                  "y": 1.647140622138977,
                  "z": -0.11671147495508194
                },
                "index0": {
                  "x": 0.21885696053504944,
                  "y": 1.6165218353271484,
                  "z": -0.11126139760017395
                },
                "middle0": {
                  "x": 0.20739713311195374,
                  "y": 1.593625545501709,
                  "z": -0.14173144102096558
                },
                "ring0": {
                  "x": 0.2212371528148651,
                  "y": 1.5879515409469604,
                  "z": -0.14521804451942444
                },
                "pinky0": {
                  "x": 0.22815828025341034,
                  "y": 1.5763485431671143,
                  "z": -0.14472556114196777
                },
                "thumb1": {
                  "x": 0.29630374908447266,
                  "y": 1.6579004526138306,
                  "z": 0.08377435803413391
                },
                "index1": {
                  "x": 0.2975267767906189,
                  "y": 1.6175382137298584,
                  "z": 0.05672836676239967
                },
                "middle1": {
                  "x": 0.30466872453689575,
                  "y": 1.599212884902954,
                  "z": 0.0966978520154953
                },
                "ring1": {
                  "x": 0.31496429443359375,
                  "y": 1.5912247896194458,
                  "z": 0.0951542779803276
                },
                "pinky1": {
                  "x": 0.3204905688762665,
                  "y": 1.5771496295928955,
                  "z": 0.09544185549020767
                }
              },
              {
                "thumb0": {
                  "x": 0.21173422038555145,
                  "y": 1.6395543813705444,
                  "z": -0.11042053997516632
                },
                "index0": {
                  "x": 0.2089182287454605,
                  "y": 1.5951826572418213,
                  "z": -0.10260163992643356
                },
                "middle0": {
                  "x": 0.21218442916870117,
                  "y": 1.5769987106323242,
                  "z": -0.1388455629348755
                },
                "ring0": {
                  "x": 0.2207043468952179,
                  "y": 1.5684285163879395,
                  "z": -0.1297173649072647
                },
                "pinky0": {
                  "x": 0.2774345278739929,
                  "y": 1.578859806060791,
                  "z": -0.09325914829969406
                },
                "thumb1": {
                  "x": 0.2906874120235443,
                  "y": 1.6378910541534424,
                  "z": 0.06709488481283188
                },
                "index1": {
                  "x": 0.2928576171398163,
                  "y": 1.602313756942749,
                  "z": 0.09185000509023666
                },
                "middle1": {
                  "x": 0.29663771390914917,
                  "y": 1.5893614292144775,
                  "z": 0.09016624838113785
                },
                "ring1": {
                  "x": 0.30161070823669434,
                  "y": 1.5782936811447144,
                  "z": 0.08397625386714935
                },
                "pinky1": {
                  "x": 0.31000766158103943,
                  "y": 1.5718899965286255,
                  "z": 0.061263781040906906
                }
              },
              {
                "thumb0": {
                  "x": 0.24297727644443512,
                  "y": 1.6638263463974,
                  "z": -0.1227145716547966
                },
                "index0": {
                  "x": 0.23788154125213623,
                  "y": 1.594655990600586,
                  "z": -0.10955425351858139
                },
                "middle0": {
                  "x": 0.2360914796590805,
                  "y": 1.5804516077041626,
                  "z": -0.13168677687644958
                },
                "ring0": {
                  "x": 0.2362373173236847,
                  "y": 1.5699539184570312,
                  "z": -0.11582611501216888
                },
                "pinky0": {
                  "x": 0.29706069827079773,
                  "y": 1.5554537773132324,
                  "z": -0.07594868540763855
                },
                "thumb1": {
                  "x": 0.29721227288246155,
                  "y": 1.6368885040283203,
                  "z": 0.04882854223251343
                },
                "index1": {
                  "x": 0.29468515515327454,
                  "y": 1.601908802986145,
                  "z": 0.07617875933647156
                },
                "middle1": {
                  "x": 0.29835045337677,
                  "y": 1.5870829820632935,
                  "z": 0.08915288746356964
                },
                "ring1": {
                  "x": 0.2912856936454773,
                  "y": 1.5738102197647095,
                  "z": 0.08194813132286072
                },
                "pinky1": {
                  "x": 0.3045399785041809,
                  "y": 1.5575134754180908,
                  "z": 0.013603553175926208
                }
              },
              {
                "thumb0": {
                  "x": 0.2503521740436554,
                  "y": 1.6690083742141724,
                  "z": -0.10182961821556091
                },
                "index0": {
                  "x": 0.24263840913772583,
                  "y": 1.5986906290054321,
                  "z": -0.08607030659914017
                },
                "middle0": {
                  "x": 0.24167823791503906,
                  "y": 1.5844991207122803,
                  "z": -0.10716991126537323
                },
                "ring0": {
                  "x": 0.23790737986564636,
                  "y": 1.5734766721725464,
                  "z": -0.09140324592590332
                },
                "pinky0": {
                  "x": 0.28603535890579224,
                  "y": 1.5508626699447632,
                  "z": -0.041203610599040985
                },
                "thumb1": {
                  "x": 0.2965577244758606,
                  "y": 1.638053059577942,
                  "z": 0.03152000159025192
                },
                "index1": {
                  "x": 0.288301557302475,
                  "y": 1.6013538837432861,
                  "z": 0.04915648698806763
                },
                "middle1": {
                  "x": 0.30025094747543335,
                  "y": 1.5871570110321045,
                  "z": 0.07055684924125671
                },
                "ring1": {
                  "x": 0.29138368368148804,
                  "y": 1.5740230083465576,
                  "z": 0.06708385795354843
                },
                "pinky1": {
                  "x": 0.298870712518692,
                  "y": 1.5532987117767334,
                  "z": -0.004103057086467743
                }
              },
              {
                "thumb0": {
                  "x": 0.24443887174129486,
                  "y": 1.6659079790115356,
                  "z": -0.1039339154958725
                },
                "index0": {
                  "x": 0.23919790983200073,
                  "y": 1.594348430633545,
                  "z": -0.08799726516008377
                },
                "middle0": {
                  "x": 0.23958420753479004,
                  "y": 1.5802181959152222,
                  "z": -0.10839032381772995
                },
                "ring0": {
                  "x": 0.23328077793121338,
                  "y": 1.56839919090271,
                  "z": -0.09147563576698303
                },
                "pinky0": {
                  "x": 0.2846226692199707,
                  "y": 1.5452823638916016,
                  "z": -0.03466760367155075
                },
                "thumb1": {
                  "x": 0.30023184418678284,
                  "y": 1.6386518478393555,
                  "z": 0.028325140476226807
                },
                "index1": {
                  "x": 0.27987250685691833,
                  "y": 1.6074750423431396,
                  "z": 0.05307571589946747
                },
                "middle1": {
                  "x": 0.2993983030319214,
                  "y": 1.5928127765655518,
                  "z": 0.0669780969619751
                },
                "ring1": {
                  "x": 0.2979307174682617,
                  "y": 1.5791962146759033,
                  "z": 0.0675850585103035
                },
                "pinky1": {
                  "x": 0.29639574885368347,
                  "y": 1.567393183708191,
                  "z": 0.05848139151930809
                }
              },
              {
                "thumb0": {
                  "x": 0.23860278725624084,
                  "y": 1.6662503480911255,
                  "z": -0.10552306473255157
                },
                "index0": {
                  "x": 0.23356203734874725,
                  "y": 1.595801830291748,
                  "z": -0.08551893383264542
                },
                "middle0": {
                  "x": 0.23501600325107574,
                  "y": 1.5809006690979004,
                  "z": -0.10873600095510483
                },
                "ring0": {
                  "x": 0.22873015701770782,
                  "y": 1.570002555847168,
                  "z": -0.09207312762737274
                },
                "pinky0": {
                  "x": 0.27899956703186035,
                  "y": 1.547927975654602,
                  "z": -0.032328732311725616
                },
                "thumb1": {
                  "x": 0.2985980808734894,
                  "y": 1.6374690532684326,
                  "z": 0.029024586081504822
                },
                "index1": {
                  "x": 0.28092119097709656,
                  "y": 1.6072343587875366,
                  "z": 0.05363629385828972
                },
                "middle1": {
                  "x": 0.29951560497283936,
                  "y": 1.592920184135437,
                  "z": 0.06781846284866333
                },
                "ring1": {
                  "x": 0.29974600672721863,
                  "y": 1.5783798694610596,
                  "z": 0.06883551180362701
                },
                "pinky1": {
                  "x": 0.29613906145095825,
                  "y": 1.5667340755462646,
                  "z": 0.061429500579833984
                }
              },
              {
                "thumb0": {
                  "x": 0.23440660536289215,
                  "y": 1.665862798690796,
                  "z": -0.10367879271507263
                },
                "index0": {
                  "x": 0.23144909739494324,
                  "y": 1.5944406986236572,
                  "z": -0.08580220490694046
                },
                "middle0": {
                  "x": 0.23440933227539062,
                  "y": 1.5806553363800049,
                  "z": -0.11004220694303513
                },
                "ring0": {
                  "x": 0.22596679627895355,
                  "y": 1.5690884590148926,
                  "z": -0.09081749618053436
                },
                "pinky0": {
                  "x": 0.2786259055137634,
                  "y": 1.5475287437438965,
                  "z": -0.033507563173770905
                },
                "thumb1": {
                  "x": 0.29225388169288635,
                  "y": 1.6383205652236938,
                  "z": 0.030064880847930908
                },
                "index1": {
                  "x": 0.29080602526664734,
                  "y": 1.6035780906677246,
                  "z": 0.060775890946388245
                },
                "middle1": {
                  "x": 0.29665830731391907,
                  "y": 1.5893772840499878,
                  "z": 0.07045532763004303
                },
                "ring1": {
                  "x": 0.2981072962284088,
                  "y": 1.5752623081207275,
                  "z": 0.07074020057916641
                },
                "pinky1": {
                  "x": 0.2951972782611847,
                  "y": 1.5634212493896484,
                  "z": 0.060683272778987885
                }
              },
              {
                "thumb0": {
                  "x": 0.19801194965839386,
                  "y": 1.6804128885269165,
                  "z": -0.17192068696022034
                },
                "index0": {
                  "x": 0.20218254625797272,
                  "y": 1.6078131198883057,
                  "z": -0.15574827790260315
                },
                "middle0": {
                  "x": 0.20166610181331635,
                  "y": 1.5943506956100464,
                  "z": -0.17448532581329346
                },
                "ring0": {
                  "x": 0.20014740526676178,
                  "y": 1.5826817750930786,
                  "z": -0.15847203135490417
                },
                "pinky0": {
                  "x": 0.24512414634227753,
                  "y": 1.5665112733840942,
                  "z": -0.11154439300298691
                },
                "thumb1": {
                  "x": 0.30162474513053894,
                  "y": 1.6601624488830566,
                  "z": 0.10066789388656616
                },
                "index1": {
                  "x": 0.2934126853942871,
                  "y": 1.6233388185501099,
                  "z": 0.11943725496530533
                },
                "middle1": {
                  "x": 0.30158528685569763,
                  "y": 1.608826756477356,
                  "z": 0.13614417612552643
                },
                "ring1": {
                  "x": 0.292425274848938,
                  "y": 1.5963222980499268,
                  "z": 0.1298036277294159
                },
                "pinky1": {
                  "x": 0.30320867896080017,
                  "y": 1.5745435953140259,
                  "z": 0.0588563047349453
                }
              },
              {
                "thumb0": {
                  "x": 0.1494836062192917,
                  "y": 1.6830945014953613,
                  "z": -0.2574419379234314
                },
                "index0": {
                  "x": 0.1605844348669052,
                  "y": 1.6133204698562622,
                  "z": -0.22919899225234985
                },
                "middle0": {
                  "x": 0.1576453000307083,
                  "y": 1.597772240638733,
                  "z": -0.2497885525226593
                },
                "ring0": {
                  "x": 0.15865616500377655,
                  "y": 1.5875654220581055,
                  "z": -0.23062540590763092
                },
                "pinky0": {
                  "x": 0.20622046291828156,
                  "y": 1.573682188987732,
                  "z": -0.18888318538665771
                },
                "thumb1": {
                  "x": 0.3035815358161926,
                  "y": 1.671247959136963,
                  "z": 0.1656026542186737
                },
                "index1": {
                  "x": 0.2938678562641144,
                  "y": 1.6347838640213013,
                  "z": 0.1697501242160797
                },
                "middle1": {
                  "x": 0.3018840253353119,
                  "y": 1.6178046464920044,
                  "z": 0.19640016555786133
                },
                "ring1": {
                  "x": 0.2940058708190918,
                  "y": 1.605066180229187,
                  "z": 0.19022676348686218
                },
                "pinky1": {
                  "x": 0.30711594223976135,
                  "y": 1.5872251987457275,
                  "z": 0.12301644682884216
                }
              },
              {
                "thumb0": {
                  "x": 0.13427966833114624,
                  "y": 1.6772491931915283,
                  "z": -0.26514822244644165
                },
                "index0": {
                  "x": 0.14731678366661072,
                  "y": 1.6080734729766846,
                  "z": -0.2366471290588379
                },
                "middle0": {
                  "x": 0.14552903175354004,
                  "y": 1.5925276279449463,
                  "z": -0.25610291957855225
                },
                "ring0": {
                  "x": 0.14660246670246124,
                  "y": 1.5822851657867432,
                  "z": -0.23709732294082642
                },
                "pinky0": {
                  "x": 0.19434724748134613,
                  "y": 1.5694973468780518,
                  "z": -0.19625750184059143
                },
                "thumb1": {
                  "x": 0.3086070418357849,
                  "y": 1.6717880964279175,
                  "z": 0.17476464807987213
                },
                "index1": {
                  "x": 0.29889336228370667,
                  "y": 1.6353238821029663,
                  "z": 0.17891211807727814
                },
                "middle1": {
                  "x": 0.3069095015525818,
                  "y": 1.6183446645736694,
                  "z": 0.20556217432022095
                },
                "ring1": {
                  "x": 0.2990313768386841,
                  "y": 1.605606198310852,
                  "z": 0.19938874244689941
                },
                "pinky1": {
                  "x": 0.31214141845703125,
                  "y": 1.5877652168273926,
                  "z": 0.1321784257888794
                }
              },
              {
                "thumb0": {
                  "x": 0.13285686075687408,
                  "y": 1.675624132156372,
                  "z": -0.2663425803184509
                },
                "index0": {
                  "x": 0.14589396119117737,
                  "y": 1.6064484119415283,
                  "z": -0.23784154653549194
                },
                "middle0": {
                  "x": 0.14410622417926788,
                  "y": 1.59090256690979,
                  "z": -0.2572973370552063
                },
                "ring0": {
                  "x": 0.1451796591281891,
                  "y": 1.5806602239608765,
                  "z": -0.23829174041748047
                },
                "pinky0": {
                  "x": 0.19292443990707397,
                  "y": 1.5678722858428955,
                  "z": -0.1974518895149231
                },
                "thumb1": {
                  "x": 0.3441397547721863,
                  "y": 1.6563743352890015,
                  "z": 0.21583397686481476
                },
                "index1": {
                  "x": 0.3344261348247528,
                  "y": 1.6199101209640503,
                  "z": 0.21998146176338196
                },
                "middle1": {
                  "x": 0.34244221448898315,
                  "y": 1.6029309034347534,
                  "z": 0.24663148820400238
                },
                "ring1": {
                  "x": 0.3345641493797302,
                  "y": 1.590192437171936,
                  "z": 0.24045808613300323
                },
                "pinky1": {
                  "x": 0.3476741909980774,
                  "y": 1.5723514556884766,
                  "z": 0.1732477843761444
                }
              },
              {
                "thumb0": {
                  "x": 0.13449159264564514,
                  "y": 1.6761748790740967,
                  "z": -0.2631126940250397
                },
                "index0": {
                  "x": 0.14752870798110962,
                  "y": 1.606999158859253,
                  "z": -0.2346116006374359
                },
                "middle0": {
                  "x": 0.14574092626571655,
                  "y": 1.5914533138275146,
                  "z": -0.25406742095947266
                },
                "ring0": {
                  "x": 0.14681439101696014,
                  "y": 1.5812108516693115,
                  "z": -0.23506179451942444
                },
                "pinky0": {
                  "x": 0.19455915689468384,
                  "y": 1.5684230327606201,
                  "z": -0.19422200322151184
                },
                "thumb1": {
                  "x": 0.3547523021697998,
                  "y": 1.6439080238342285,
                  "z": 0.20336708426475525
                },
                "index1": {
                  "x": 0.34503862261772156,
                  "y": 1.607443928718567,
                  "z": 0.20751455426216125
                },
                "middle1": {
                  "x": 0.3530547320842743,
                  "y": 1.5904645919799805,
                  "z": 0.23416459560394287
                },
                "ring1": {
                  "x": 0.34517666697502136,
                  "y": 1.5777262449264526,
                  "z": 0.22799119353294373
                },
                "pinky1": {
                  "x": 0.3582867383956909,
                  "y": 1.5598851442337036,
                  "z": 0.1607808768749237
                }
              },
              {
                "thumb0": {
                  "x": 0.13324205577373505,
                  "y": 1.675895094871521,
                  "z": -0.2611038088798523
                },
                "index0": {
                  "x": 0.14627918601036072,
                  "y": 1.6067193746566772,
                  "z": -0.23260271549224854
                },
                "middle0": {
                  "x": 0.14449143409729004,
                  "y": 1.591173529624939,
                  "z": -0.25205856561660767
                },
                "ring0": {
                  "x": 0.14556485414505005,
                  "y": 1.5809311866760254,
                  "z": -0.23305296897888184
                },
                "pinky0": {
                  "x": 0.19330966472625732,
                  "y": 1.5681432485580444,
                  "z": -0.19221311807632446
                },
                "thumb1": {
                  "x": 0.36407923698425293,
                  "y": 1.6596086025238037,
                  "z": 0.1997363567352295
                },
                "index1": {
                  "x": 0.3543655276298523,
                  "y": 1.6231443881988525,
                  "z": 0.2038838118314743
                },
                "middle1": {
                  "x": 0.3623816668987274,
                  "y": 1.6061651706695557,
                  "z": 0.23053385317325592
                },
                "ring1": {
                  "x": 0.3545036017894745,
                  "y": 1.5934267044067383,
                  "z": 0.22436045110225677
                },
                "pinky1": {
                  "x": 0.36761361360549927,
                  "y": 1.5755857229232788,
                  "z": 0.15715014934539795
                }
              },
              {
                "thumb0": {
                  "x": 0.1343577802181244,
                  "y": 1.676819086074829,
                  "z": -0.25542429089546204
                },
                "index0": {
                  "x": 0.14739488065242767,
                  "y": 1.6076433658599854,
                  "z": -0.22692319750785828
                },
                "middle0": {
                  "x": 0.1456071436405182,
                  "y": 1.592097520828247,
                  "z": -0.24637898802757263
                },
                "ring0": {
                  "x": 0.1466805636882782,
                  "y": 1.581855058670044,
                  "z": -0.22737336158752441
                },
                "pinky0": {
                  "x": 0.19442534446716309,
                  "y": 1.5690672397613525,
                  "z": -0.18653357028961182
                },
                "thumb1": {
                  "x": 0.31709733605384827,
                  "y": 1.665503740310669,
                  "z": 0.16305911540985107
                },
                "index1": {
                  "x": 0.30738365650177,
                  "y": 1.6290396451950073,
                  "z": 0.16720658540725708
                },
                "middle1": {
                  "x": 0.31539979577064514,
                  "y": 1.6120604276657104,
                  "z": 0.1938566267490387
                },
                "ring1": {
                  "x": 0.3075217008590698,
                  "y": 1.599321961402893,
                  "z": 0.18768322467803955
                },
                "pinky1": {
                  "x": 0.3206317126750946,
                  "y": 1.5814809799194336,
                  "z": 0.12047290056943893
                }
              },
              {
                "thumb0": {
                  "x": 0.18472814559936523,
                  "y": 1.6596094369888306,
                  "z": -0.18799671530723572
                },
                "index0": {
                  "x": 0.2217334657907486,
                  "y": 1.612894892692566,
                  "z": -0.19163191318511963
                },
                "middle0": {
                  "x": 0.20651349425315857,
                  "y": 1.5888783931732178,
                  "z": -0.19456912577152252
                },
                "ring0": {
                  "x": 0.21207593381404877,
                  "y": 1.580140471458435,
                  "z": -0.20286574959754944
                },
                "pinky0": {
                  "x": 0.26283228397369385,
                  "y": 1.577083945274353,
                  "z": -0.23525294661521912
                },
                "thumb1": {
                  "x": 0.2994117736816406,
                  "y": 1.6692357063293457,
                  "z": 0.16567401587963104
                },
                "index1": {
                  "x": 0.30447688698768616,
                  "y": 1.6284440755844116,
                  "z": 0.1581268310546875
                },
                "middle1": {
                  "x": 0.3029226064682007,
                  "y": 1.6095198392868042,
                  "z": 0.16702044010162354
                },
                "ring1": {
                  "x": 0.31039780378341675,
                  "y": 1.6070542335510254,
                  "z": 0.12520290911197662
                },
                "pinky1": {
                  "x": 0.36599910259246826,
                  "y": 1.6128796339035034,
                  "z": 0.12283540517091751
                }
              },
              {
                "thumb0": {
                  "x": 0.22357679903507233,
                  "y": 1.6230621337890625,
                  "z": -0.13022305071353912
                },
                "index0": {
                  "x": 0.24205859005451202,
                  "y": 1.6154688596725464,
                  "z": -0.16022637486457825
                },
                "middle0": {
                  "x": 0.23847179114818573,
                  "y": 1.5986820459365845,
                  "z": -0.16846302151679993
                },
                "ring0": {
                  "x": 0.2425628900527954,
                  "y": 1.5909037590026855,
                  "z": -0.179316446185112
                },
                "pinky0": {
                  "x": 0.23776769638061523,
                  "y": 1.5803741216659546,
                  "z": -0.1905527114868164
                },
                "thumb1": {
                  "x": 0.2875101566314697,
                  "y": 1.6573673486709595,
                  "z": 0.13157512247562408
                },
                "index1": {
                  "x": 0.30963632464408875,
                  "y": 1.6357170343399048,
                  "z": 0.10318157076835632
                },
                "middle1": {
                  "x": 0.32803991436958313,
                  "y": 1.6348284482955933,
                  "z": 0.09156925976276398
                },
                "ring1": {
                  "x": 0.350985586643219,
                  "y": 1.634027123451233,
                  "z": 0.09475637972354889
                },
                "pinky1": {
                  "x": 0.3629206120967865,
                  "y": 1.6174373626708984,
                  "z": 0.1142527163028717
                }
              },
              {
                "thumb0": {
                  "x": 0.25060325860977173,
                  "y": 1.6028043031692505,
                  "z": -0.11422552168369293
                },
                "index0": {
                  "x": 0.2633307874202728,
                  "y": 1.615227222442627,
                  "z": -0.1432218849658966
                },
                "middle0": {
                  "x": 0.27000442147254944,
                  "y": 1.6115249395370483,
                  "z": -0.1548403799533844
                },
                "ring0": {
                  "x": 0.2707209885120392,
                  "y": 1.61420738697052,
                  "z": -0.17239508032798767
                },
                "pinky0": {
                  "x": 0.2611979842185974,
                  "y": 1.607832670211792,
                  "z": -0.1904016137123108
                },
                "thumb1": {
                  "x": 0.29451966285705566,
                  "y": 1.6279869079589844,
                  "z": 0.09186390787363052
                },
                "index1": {
                  "x": 0.32411879301071167,
                  "y": 1.6549737453460693,
                  "z": 0.07722844928503036
                },
                "middle1": {
                  "x": 0.34435775876045227,
                  "y": 1.6643900871276855,
                  "z": 0.0810932144522667
                },
                "ring1": {
                  "x": 0.3610854744911194,
                  "y": 1.6556575298309326,
                  "z": 0.09235683083534241
                },
                "pinky1": {
                  "x": 0.3733551800251007,
                  "y": 1.6386539936065674,
                  "z": 0.11564121395349503
                }
              },
              {
                "thumb0": {
                  "x": 0.26710352301597595,
                  "y": 1.5810669660568237,
                  "z": -0.1102716252207756
                },
                "index0": {
                  "x": 0.27545425295829773,
                  "y": 1.6311389207839966,
                  "z": -0.12406236678361893
                },
                "middle0": {
                  "x": 0.27856212854385376,
                  "y": 1.643129587173462,
                  "z": -0.14570119976997375
                },
                "ring0": {
                  "x": 0.2673494219779968,
                  "y": 1.6492512226104736,
                  "z": -0.16975952684879303
                },
                "pinky0": {
                  "x": 0.2464592009782791,
                  "y": 1.6387094259262085,
                  "z": -0.19861149787902832
                },
                "thumb1": {
                  "x": 0.31183212995529175,
                  "y": 1.5939499139785767,
                  "z": 0.06934213638305664
                },
                "index1": {
                  "x": 0.32079797983169556,
                  "y": 1.6624735593795776,
                  "z": 0.07185885310173035
                },
                "middle1": {
                  "x": 0.3353598117828369,
                  "y": 1.6794534921646118,
                  "z": 0.08655533194541931
                },
                "ring1": {
                  "x": 0.34262555837631226,
                  "y": 1.68070387840271,
                  "z": 0.11370279639959335
                },
                "pinky1": {
                  "x": 0.3598391115665436,
                  "y": 1.6595171689987183,
                  "z": 0.1425195038318634
                }
              },
              {
                "thumb0": {
                  "x": 0.27962544560432434,
                  "y": 1.5726362466812134,
                  "z": -0.09594213962554932
                },
                "index0": {
                  "x": 0.2742806077003479,
                  "y": 1.6475297212600708,
                  "z": -0.10326895862817764
                },
                "middle0": {
                  "x": 0.27192220091819763,
                  "y": 1.6657686233520508,
                  "z": -0.1298619508743286
                },
                "ring0": {
                  "x": 0.26320159435272217,
                  "y": 1.6611543893814087,
                  "z": -0.15654054284095764
                },
                "pinky0": {
                  "x": 0.253284215927124,
                  "y": 1.6361500024795532,
                  "z": -0.1911158561706543
                },
                "thumb1": {
                  "x": 0.31575727462768555,
                  "y": 1.5667827129364014,
                  "z": 0.05376989021897316
                },
                "index1": {
                  "x": 0.3094087839126587,
                  "y": 1.6608495712280273,
                  "z": 0.07313773781061172
                },
                "middle1": {
                  "x": 0.32144567370414734,
                  "y": 1.681741714477539,
                  "z": 0.09590362012386322
                },
                "ring1": {
                  "x": 0.3280835747718811,
                  "y": 1.6787196397781372,
                  "z": 0.12663502991199493
                },
                "pinky1": {
                  "x": 0.34424611926078796,
                  "y": 1.6574714183807373,
                  "z": 0.16095460951328278
                }
              }
            ],
            "words": "line"
          },
          {
            "key": "1f1459f2-688e-5d2a-2366-5cb00328eb2c",
            "gesture": [
              {
                "thumb0": {
                  "x": 0.20332227647304535,
                  "y": 1.566207766532898,
                  "z": -0.06489003449678421
                },
                "index0": {
                  "x": 0.1692747175693512,
                  "y": 1.6637295484542847,
                  "z": -0.08093655109405518
                },
                "middle0": {
                  "x": 0.173727348446846,
                  "y": 1.6871461868286133,
                  "z": -0.11066711694002151
                },
                "ring0": {
                  "x": 0.18081443011760712,
                  "y": 1.6809413433074951,
                  "z": -0.14031726121902466
                },
                "pinky0": {
                  "x": 0.19579772651195526,
                  "y": 1.6559512615203857,
                  "z": -0.1765589714050293
                },
                "thumb1": {
                  "x": 0.1812409609556198,
                  "y": 1.559246301651001,
                  "z": 0.06146813556551933
                },
                "index1": {
                  "x": 0.19035428762435913,
                  "y": 1.669471263885498,
                  "z": 0.08839905261993408
                },
                "middle1": {
                  "x": 0.21588289737701416,
                  "y": 1.6907511949539185,
                  "z": 0.09314516931772232
                },
                "ring1": {
                  "x": 0.24239833652973175,
                  "y": 1.6892917156219482,
                  "z": 0.11054037511348724
                },
                "pinky1": {
                  "x": 0.286295086145401,
                  "y": 1.6686625480651855,
                  "z": 0.12187626957893372
                }
              },
              {
                "thumb0": {
                  "x": 0.20514386892318726,
                  "y": 1.5646584033966064,
                  "z": -0.06636679917573929
                },
                "index0": {
                  "x": 0.17103029787540436,
                  "y": 1.6610920429229736,
                  "z": -0.08104066550731659
                },
                "middle0": {
                  "x": 0.1741333156824112,
                  "y": 1.6858201026916504,
                  "z": -0.11197174340486526
                },
                "ring0": {
                  "x": 0.18021629750728607,
                  "y": 1.6797022819519043,
                  "z": -0.1417190581560135
                },
                "pinky0": {
                  "x": 0.19377340376377106,
                  "y": 1.6549361944198608,
                  "z": -0.1784229576587677
                },
                "thumb1": {
                  "x": 0.18334797024726868,
                  "y": 1.558754324913025,
                  "z": 0.06317050755023956
                },
                "index1": {
                  "x": 0.19309018552303314,
                  "y": 1.6699559688568115,
                  "z": 0.09130758047103882
                },
                "middle1": {
                  "x": 0.21582657098770142,
                  "y": 1.6911753416061401,
                  "z": 0.09810735285282135
                },
                "ring1": {
                  "x": 0.2420424222946167,
                  "y": 1.6898643970489502,
                  "z": 0.1160234808921814
                },
                "pinky1": {
                  "x": 0.28568899631500244,
                  "y": 1.6697170734405518,
                  "z": 0.1277150809764862
                }
              },
              {
                "thumb0": {
                  "x": 0.20467641949653625,
                  "y": 1.5671952962875366,
                  "z": -0.06673921644687653
                },
                "index0": {
                  "x": 0.17865756154060364,
                  "y": 1.6513597965240479,
                  "z": -0.07848595082759857
                },
                "middle0": {
                  "x": 0.1704482138156891,
                  "y": 1.684147834777832,
                  "z": -0.11128658801317215
                },
                "ring0": {
                  "x": 0.17640963196754456,
                  "y": 1.6787270307540894,
                  "z": -0.14134442806243896
                },
                "pinky0": {
                  "x": 0.1915735900402069,
                  "y": 1.654147982597351,
                  "z": -0.17710618674755096
                },
                "thumb1": {
                  "x": 0.18480990827083588,
                  "y": 1.5580837726593018,
                  "z": 0.06266160309314728
                },
                "index1": {
                  "x": 0.19427187740802765,
                  "y": 1.669412612915039,
                  "z": 0.09199350327253342
                },
                "middle1": {
                  "x": 0.21613922715187073,
                  "y": 1.690586805343628,
                  "z": 0.09986408054828644
                },
                "ring1": {
                  "x": 0.2416415810585022,
                  "y": 1.689225435256958,
                  "z": 0.11838062107563019
                },
                "pinky1": {
                  "x": 0.28543245792388916,
                  "y": 1.6689304113388062,
                  "z": 0.12982434034347534
                }
              },
              {
                "thumb0": {
                  "x": 0.23648378252983093,
                  "y": 1.6239731311798096,
                  "z": -0.054848939180374146
                },
                "index0": {
                  "x": 0.2217685431241989,
                  "y": 1.6364704370498657,
                  "z": -0.06833912432193756
                },
                "middle0": {
                  "x": 0.19677990674972534,
                  "y": 1.7084749937057495,
                  "z": -0.08011782169342041
                },
                "ring0": {
                  "x": 0.19174431264400482,
                  "y": 1.7140153646469116,
                  "z": -0.11321098357439041
                },
                "pinky0": {
                  "x": 0.2111872136592865,
                  "y": 1.6941872835159302,
                  "z": -0.14562007784843445
                },
                "thumb1": {
                  "x": 0.1769810914993286,
                  "y": 1.5576590299606323,
                  "z": 0.06786656379699707
                },
                "index1": {
                  "x": 0.1831851303577423,
                  "y": 1.6678950786590576,
                  "z": 0.10225287824869156
                },
                "middle1": {
                  "x": 0.20378898084163666,
                  "y": 1.6882797479629517,
                  "z": 0.10928638279438019
                },
                "ring1": {
                  "x": 0.2291376143693924,
                  "y": 1.6871047019958496,
                  "z": 0.12696123123168945
                },
                "pinky1": {
                  "x": 0.27323853969573975,
                  "y": 1.6672260761260986,
                  "z": 0.1364714801311493
                }
              },
              {
                "thumb0": {
                  "x": 0.2928670048713684,
                  "y": 1.6147531270980835,
                  "z": -0.07240375131368637
                },
                "index0": {
                  "x": 0.283135324716568,
                  "y": 1.6192253828048706,
                  "z": -0.07405795902013779
                },
                "middle0": {
                  "x": 0.25620368123054504,
                  "y": 1.6915932893753052,
                  "z": -0.0838484913110733
                },
                "ring0": {
                  "x": 0.25219741463661194,
                  "y": 1.6958760023117065,
                  "z": -0.1113147959113121
                },
                "pinky0": {
                  "x": 0.2693663537502289,
                  "y": 1.6796290874481201,
                  "z": -0.14261512458324432
                },
                "thumb1": {
                  "x": 0.17767968773841858,
                  "y": 1.5492029190063477,
                  "z": 0.06846531480550766
                },
                "index1": {
                  "x": 0.18382886052131653,
                  "y": 1.654228687286377,
                  "z": 0.1120515987277031
                },
                "middle1": {
                  "x": 0.20671719312667847,
                  "y": 1.6745702028274536,
                  "z": 0.12533316016197205
                },
                "ring1": {
                  "x": 0.23027054965496063,
                  "y": 1.671478271484375,
                  "z": 0.14178311824798584
                },
                "pinky1": {
                  "x": 0.27484872937202454,
                  "y": 1.6491131782531738,
                  "z": 0.14670631289482117
                }
              },
              {
                "thumb0": {
                  "x": 0.2722673714160919,
                  "y": 1.6155287027359009,
                  "z": -0.07412495464086533
                },
                "index0": {
                  "x": 0.26304855942726135,
                  "y": 1.620251178741455,
                  "z": -0.07409977912902832
                },
                "middle0": {
                  "x": 0.2332141101360321,
                  "y": 1.697401762008667,
                  "z": -0.0875396877527237
                },
                "ring0": {
                  "x": 0.2328089028596878,
                  "y": 1.6983698606491089,
                  "z": -0.11413168162107468
                },
                "pinky0": {
                  "x": 0.2532315254211426,
                  "y": 1.6811084747314453,
                  "z": -0.14438560605049133
                },
                "thumb1": {
                  "x": 0.17229416966438293,
                  "y": 1.5555857419967651,
                  "z": 0.07655055820941925
                },
                "index1": {
                  "x": 0.1803242266178131,
                  "y": 1.6606857776641846,
                  "z": 0.11644750833511353
                },
                "middle1": {
                  "x": 0.2018611580133438,
                  "y": 1.6805930137634277,
                  "z": 0.12480487674474716
                },
                "ring1": {
                  "x": 0.22654570639133453,
                  "y": 1.6780917644500732,
                  "z": 0.14081767201423645
                },
                "pinky1": {
                  "x": 0.26916807889938354,
                  "y": 1.6575332880020142,
                  "z": 0.1498480886220932
                }
              },
              {
                "thumb0": {
                  "x": 0.27015194296836853,
                  "y": 1.6132179498672485,
                  "z": -0.0737801194190979
                },
                "index0": {
                  "x": 0.26180270314216614,
                  "y": 1.6188932657241821,
                  "z": -0.07380136102437973
                },
                "middle0": {
                  "x": 0.23206409811973572,
                  "y": 1.6973439455032349,
                  "z": -0.08677571266889572
                },
                "ring0": {
                  "x": 0.2324025183916092,
                  "y": 1.69804048538208,
                  "z": -0.11361151933670044
                },
                "pinky0": {
                  "x": 0.25348031520843506,
                  "y": 1.6805517673492432,
                  "z": -0.14369046688079834
                },
                "thumb1": {
                  "x": 0.17286448180675507,
                  "y": 1.559194803237915,
                  "z": 0.07372598350048065
                },
                "index1": {
                  "x": 0.1779581606388092,
                  "y": 1.664454460144043,
                  "z": 0.11557167768478394
                },
                "middle1": {
                  "x": 0.1992601454257965,
                  "y": 1.6839407682418823,
                  "z": 0.12200552225112915
                },
                "ring1": {
                  "x": 0.22304749488830566,
                  "y": 1.6822214126586914,
                  "z": 0.140743225812912
                },
                "pinky1": {
                  "x": 0.265754371881485,
                  "y": 1.6622785329818726,
                  "z": 0.14979863166809082
                }
              },
              {
                "thumb0": {
                  "x": 0.2418268471956253,
                  "y": 1.6137973070144653,
                  "z": -0.06542645394802094
                },
                "index0": {
                  "x": 0.23171532154083252,
                  "y": 1.6289887428283691,
                  "z": -0.07666973769664764
                },
                "middle0": {
                  "x": 0.20630612969398499,
                  "y": 1.698740839958191,
                  "z": -0.08972010016441345
                },
                "ring0": {
                  "x": 0.20707140862941742,
                  "y": 1.6998636722564697,
                  "z": -0.11688102781772614
                },
                "pinky0": {
                  "x": 0.22858570516109467,
                  "y": 1.6810657978057861,
                  "z": -0.14638292789459229
                },
                "thumb1": {
                  "x": 0.17457428574562073,
                  "y": 1.564318299293518,
                  "z": 0.06663665175437927
                },
                "index1": {
                  "x": 0.1804153323173523,
                  "y": 1.6712104082107544,
                  "z": 0.10896705090999603
                },
                "middle1": {
                  "x": 0.20083250105381012,
                  "y": 1.690480351448059,
                  "z": 0.11523466557264328
                },
                "ring1": {
                  "x": 0.22508524358272552,
                  "y": 1.6885104179382324,
                  "z": 0.13332825899124146
                },
                "pinky1": {
                  "x": 0.26718422770500183,
                  "y": 1.6685526371002197,
                  "z": 0.14377416670322418
                }
              },
              {
                "thumb0": {
                  "x": 0.1957237720489502,
                  "y": 1.5952863693237305,
                  "z": -0.06635148078203201
                },
                "index0": {
                  "x": 0.17213758826255798,
                  "y": 1.6617298126220703,
                  "z": -0.08268776535987854
                },
                "middle0": {
                  "x": 0.16414064168930054,
                  "y": 1.6907955408096313,
                  "z": -0.10360440611839294
                },
                "ring0": {
                  "x": 0.16582705080509186,
                  "y": 1.6912965774536133,
                  "z": -0.13273167610168457
                },
                "pinky0": {
                  "x": 0.18736572563648224,
                  "y": 1.6696802377700806,
                  "z": -0.16343960165977478
                },
                "thumb1": {
                  "x": 0.18561187386512756,
                  "y": 1.569545865058899,
                  "z": 0.05945204198360443
                },
                "index1": {
                  "x": 0.18666282296180725,
                  "y": 1.6760197877883911,
                  "z": 0.1022258773446083
                },
                "middle1": {
                  "x": 0.20584379136562347,
                  "y": 1.6949477195739746,
                  "z": 0.10926319658756256
                },
                "ring1": {
                  "x": 0.2286100536584854,
                  "y": 1.6933009624481201,
                  "z": 0.1285661906003952
                },
                "pinky1": {
                  "x": 0.2681811451911926,
                  "y": 1.6743319034576416,
                  "z": 0.14407849311828613
                }
              },
              {
                "thumb0": {
                  "x": 0.19441325962543488,
                  "y": 1.5738390684127808,
                  "z": -0.0771029070019722
                },
                "index0": {
                  "x": 0.1633475422859192,
                  "y": 1.6621198654174805,
                  "z": -0.08885882049798965
                },
                "middle0": {
                  "x": 0.16281762719154358,
                  "y": 1.6839630603790283,
                  "z": -0.11530366539955139
                },
                "ring0": {
                  "x": 0.155264213681221,
                  "y": 1.683792233467102,
                  "z": -0.1446768045425415
                },
                "pinky0": {
                  "x": 0.162958562374115,
                  "y": 1.663041591644287,
                  "z": -0.18394069373607635
                },
                "thumb1": {
                  "x": 0.2001190334558487,
                  "y": 1.5684120655059814,
                  "z": 0.0452619269490242
                },
                "index1": {
                  "x": 0.20033963024616241,
                  "y": 1.6767449378967285,
                  "z": 0.08359194546937943
                },
                "middle1": {
                  "x": 0.21735651791095734,
                  "y": 1.6958848237991333,
                  "z": 0.09287784993648529
                },
                "ring1": {
                  "x": 0.2375989854335785,
                  "y": 1.6947011947631836,
                  "z": 0.11492919921875
                },
                "pinky1": {
                  "x": 0.2694515585899353,
                  "y": 1.6770036220550537,
                  "z": 0.13931366801261902
                }
              },
              {
                "thumb0": {
                  "x": 0.20735283195972443,
                  "y": 1.562447428703308,
                  "z": -0.08073316514492035
                },
                "index0": {
                  "x": 0.17316585779190063,
                  "y": 1.654822587966919,
                  "z": -0.08961476385593414
                },
                "middle0": {
                  "x": 0.17656272649765015,
                  "y": 1.6771202087402344,
                  "z": -0.12118091434240341
                },
                "ring0": {
                  "x": 0.16902974247932434,
                  "y": 1.6748796701431274,
                  "z": -0.15161541104316711
                },
                "pinky0": {
                  "x": 0.16834820806980133,
                  "y": 1.6534717082977295,
                  "z": -0.1917763501405716
                },
                "thumb1": {
                  "x": 0.2115398347377777,
                  "y": 1.5606898069381714,
                  "z": 0.035688549280166626
                },
                "index1": {
                  "x": 0.21195711195468903,
                  "y": 1.6698740720748901,
                  "z": 0.07277467846870422
                },
                "middle1": {
                  "x": 0.23002727329730988,
                  "y": 1.6890562772750854,
                  "z": 0.08380944281816483
                },
                "ring1": {
                  "x": 0.24744471907615662,
                  "y": 1.6867746114730835,
                  "z": 0.10660658776760101
                },
                "pinky1": {
                  "x": 0.27195629477500916,
                  "y": 1.6691259145736694,
                  "z": 0.1362493932247162
                }
              },
              {
                "thumb0": {
                  "x": 0.21406786143779755,
                  "y": 1.5665582418441772,
                  "z": -0.08081624656915665
                },
                "index0": {
                  "x": 0.17946720123291016,
                  "y": 1.6595110893249512,
                  "z": -0.09079308807849884
                },
                "middle0": {
                  "x": 0.18279466032981873,
                  "y": 1.6815024614334106,
                  "z": -0.12207023799419403
                },
                "ring0": {
                  "x": 0.17554005980491638,
                  "y": 1.6790677309036255,
                  "z": -0.15343981981277466
                },
                "pinky0": {
                  "x": 0.17393746972084045,
                  "y": 1.657310128211975,
                  "z": -0.192447692155838
                },
                "thumb1": {
                  "x": 0.20859257876873016,
                  "y": 1.5591208934783936,
                  "z": 0.0377962589263916
                },
                "index1": {
                  "x": 0.2106865495443344,
                  "y": 1.669692873954773,
                  "z": 0.07436145842075348
                },
                "middle1": {
                  "x": 0.22968117892742157,
                  "y": 1.68883216381073,
                  "z": 0.08495043218135834
                },
                "ring1": {
                  "x": 0.24656349420547485,
                  "y": 1.6860724687576294,
                  "z": 0.10789956897497177
                },
                "pinky1": {
                  "x": 0.27191630005836487,
                  "y": 1.6684030294418335,
                  "z": 0.13605564832687378
                }
              },
              {
                "thumb0": {
                  "x": 0.21298383176326752,
                  "y": 1.5675462484359741,
                  "z": -0.07961759716272354
                },
                "index0": {
                  "x": 0.17922408878803253,
                  "y": 1.66059410572052,
                  "z": -0.09047672152519226
                },
                "middle0": {
                  "x": 0.18211492896080017,
                  "y": 1.6827692985534668,
                  "z": -0.12135884165763855
                },
                "ring0": {
                  "x": 0.17608386278152466,
                  "y": 1.6800622940063477,
                  "z": -0.15291999280452728
                },
                "pinky0": {
                  "x": 0.17381437122821808,
                  "y": 1.658589482307434,
                  "z": -0.1910921186208725
                },
                "thumb1": {
                  "x": 0.2084420770406723,
                  "y": 1.556688904762268,
                  "z": 0.03838580101728439
                },
                "index1": {
                  "x": 0.20852665603160858,
                  "y": 1.6685973405838013,
                  "z": 0.0746658444404602
                },
                "middle1": {
                  "x": 0.2276955544948578,
                  "y": 1.6881219148635864,
                  "z": 0.0849701464176178
                },
                "ring1": {
                  "x": 0.24402698874473572,
                  "y": 1.6861368417739868,
                  "z": 0.10968250781297684
                },
                "pinky1": {
                  "x": 0.27104783058166504,
                  "y": 1.668636441230774,
                  "z": 0.13712647557258606
                }
              },
              {
                "thumb0": {
                  "x": 0.21366512775421143,
                  "y": 1.5661306381225586,
                  "z": -0.08078543096780777
                },
                "index0": {
                  "x": 0.18036915361881256,
                  "y": 1.6590657234191895,
                  "z": -0.09225615859031677
                },
                "middle0": {
                  "x": 0.18339651823043823,
                  "y": 1.680873155593872,
                  "z": -0.12259714305400848
                },
                "ring0": {
                  "x": 0.17800630629062653,
                  "y": 1.6780531406402588,
                  "z": -0.15388928353786469
                },
                "pinky0": {
                  "x": 0.1766662448644638,
                  "y": 1.6568032503128052,
                  "z": -0.19105742871761322
                },
                "thumb1": {
                  "x": 0.20913584530353546,
                  "y": 1.5546658039093018,
                  "z": 0.037766292691230774
                },
                "index1": {
                  "x": 0.20839929580688477,
                  "y": 1.6665403842926025,
                  "z": 0.07363616675138474
                },
                "middle1": {
                  "x": 0.22711528837680817,
                  "y": 1.6867809295654297,
                  "z": 0.08482842147350311
                },
                "ring1": {
                  "x": 0.24290738999843597,
                  "y": 1.6851508617401123,
                  "z": 0.11000493168830872
                },
                "pinky1": {
                  "x": 0.2698756754398346,
                  "y": 1.6676925420761108,
                  "z": 0.136837899684906
                }
              },
              {
                "thumb0": {
                  "x": 0.2160201519727707,
                  "y": 1.5646963119506836,
                  "z": -0.08242253959178925
                },
                "index0": {
                  "x": 0.18458512425422668,
                  "y": 1.6571383476257324,
                  "z": -0.09622307866811752
                },
                "middle0": {
                  "x": 0.18849118053913116,
                  "y": 1.6779577732086182,
                  "z": -0.12537100911140442
                },
                "ring0": {
                  "x": 0.1846139132976532,
                  "y": 1.674939751625061,
                  "z": -0.15591995418071747
                },
                "pinky0": {
                  "x": 0.18537721037864685,
                  "y": 1.6535223722457886,
                  "z": -0.1928616166114807
                },
                "thumb1": {
                  "x": 0.20825442671775818,
                  "y": 1.553999423980713,
                  "z": 0.03741665184497833
                },
                "index1": {
                  "x": 0.20967021584510803,
                  "y": 1.666128396987915,
                  "z": 0.07304279506206512
                },
                "middle1": {
                  "x": 0.22898253798484802,
                  "y": 1.686193585395813,
                  "z": 0.08379655331373215
                },
                "ring1": {
                  "x": 0.2466319501399994,
                  "y": 1.6849408149719238,
                  "z": 0.10954228043556213
                },
                "pinky1": {
                  "x": 0.2773324251174927,
                  "y": 1.6662461757659912,
                  "z": 0.13742834329605103
                }
              },
              {
                "thumb0": {
                  "x": 0.21783511340618134,
                  "y": 1.5623109340667725,
                  "z": -0.08616292476654053
                },
                "index0": {
                  "x": 0.1882198452949524,
                  "y": 1.654707670211792,
                  "z": -0.10259875655174255
                },
                "middle0": {
                  "x": 0.19325999915599823,
                  "y": 1.6745226383209229,
                  "z": -0.13121947646141052
                },
                "ring0": {
                  "x": 0.19051040709018707,
                  "y": 1.6711353063583374,
                  "z": -0.16145679354667664
                },
                "pinky0": {
                  "x": 0.19352251291275024,
                  "y": 1.6490705013275146,
                  "z": -0.19738861918449402
                },
                "thumb1": {
                  "x": 0.20867855846881866,
                  "y": 1.5554451942443848,
                  "z": 0.041463762521743774
                },
                "index1": {
                  "x": 0.21088072657585144,
                  "y": 1.666162371635437,
                  "z": 0.0782267302274704
                },
                "middle1": {
                  "x": 0.2303309142589569,
                  "y": 1.6858412027359009,
                  "z": 0.08914352208375931
                },
                "ring1": {
                  "x": 0.24782367050647736,
                  "y": 1.6838194131851196,
                  "z": 0.11368735879659653
                },
                "pinky1": {
                  "x": 0.276439905166626,
                  "y": 1.665294885635376,
                  "z": 0.14005616307258606
                }
              },
              {
                "thumb0": {
                  "x": 0.2049548327922821,
                  "y": 1.560387134552002,
                  "z": -0.08176032453775406
                },
                "index0": {
                  "x": 0.18638382852077484,
                  "y": 1.6524407863616943,
                  "z": -0.10796578973531723
                },
                "middle0": {
                  "x": 0.19650989770889282,
                  "y": 1.6710563898086548,
                  "z": -0.13579310476779938
                },
                "ring0": {
                  "x": 0.20104607939720154,
                  "y": 1.6658636331558228,
                  "z": -0.1639716625213623
                },
                "pinky0": {
                  "x": 0.21314802765846252,
                  "y": 1.6413038969039917,
                  "z": -0.19734132289886475
                },
                "thumb1": {
                  "x": 0.2046337127685547,
                  "y": 1.5525684356689453,
                  "z": 0.04910648986697197
                },
                "index1": {
                  "x": 0.213942751288414,
                  "y": 1.662840723991394,
                  "z": 0.08397867530584335
                },
                "middle1": {
                  "x": 0.23482505977153778,
                  "y": 1.6818119287490845,
                  "z": 0.09260579943656921
                },
                "ring1": {
                  "x": 0.2550566792488098,
                  "y": 1.6794097423553467,
                  "z": 0.11357582360506058
                },
                "pinky1": {
                  "x": 0.283165842294693,
                  "y": 1.660948634147644,
                  "z": 0.13568946719169617
                }
              },
              {
                "thumb0": {
                  "x": 0.2014242708683014,
                  "y": 1.561426043510437,
                  "z": -0.085698701441288
                },
                "index0": {
                  "x": 0.19240614771842957,
                  "y": 1.6500893831253052,
                  "z": -0.11447091400623322
                },
                "middle0": {
                  "x": 0.20442983508110046,
                  "y": 1.667328119277954,
                  "z": -0.14233005046844482
                },
                "ring0": {
                  "x": 0.21608126163482666,
                  "y": 1.6591761112213135,
                  "z": -0.1678716242313385
                },
                "pinky0": {
                  "x": 0.23086874186992645,
                  "y": 1.6317946910858154,
                  "z": -0.19875961542129517
                },
                "thumb1": {
                  "x": 0.20460668206214905,
                  "y": 1.5516228675842285,
                  "z": 0.06156592443585396
                },
                "index1": {
                  "x": 0.22620514035224915,
                  "y": 1.6597553491592407,
                  "z": 0.08598493784666061
                },
                "middle1": {
                  "x": 0.2484879195690155,
                  "y": 1.6773337125778198,
                  "z": 0.08998161554336548
                },
                "ring1": {
                  "x": 0.2731860280036926,
                  "y": 1.6752028465270996,
                  "z": 0.10607566684484482
                },
                "pinky1": {
                  "x": 0.3097374439239502,
                  "y": 1.654325008392334,
                  "z": 0.11843861639499664
                }
              },
              {
                "thumb0": {
                  "x": 0.19118477404117584,
                  "y": 1.5632423162460327,
                  "z": -0.08546826243400574
                },
                "index0": {
                  "x": 0.19580794870853424,
                  "y": 1.646682620048523,
                  "z": -0.11399566382169724
                },
                "middle0": {
                  "x": 0.21091176569461823,
                  "y": 1.6622943878173828,
                  "z": -0.14005312323570251
                },
                "ring0": {
                  "x": 0.22787931561470032,
                  "y": 1.6518396139144897,
                  "z": -0.16241157054901123
                },
                "pinky0": {
                  "x": 0.24291011691093445,
                  "y": 1.6238396167755127,
                  "z": -0.19144010543823242
                },
                "thumb1": {
                  "x": 0.21372894942760468,
                  "y": 1.5518993139266968,
                  "z": 0.06282845139503479
                },
                "index1": {
                  "x": 0.23914723098278046,
                  "y": 1.6537564992904663,
                  "z": 0.08214838802814484
                },
                "middle1": {
                  "x": 0.2617955803871155,
                  "y": 1.6704597473144531,
                  "z": 0.08601020276546478
                },
                "ring1": {
                  "x": 0.28732243180274963,
                  "y": 1.6674312353134155,
                  "z": 0.0999923050403595
                },
                "pinky1": {
                  "x": 0.32459935545921326,
                  "y": 1.643836259841919,
                  "z": 0.1099686399102211
                }
              },
              {
                "thumb0": {
                  "x": 0.18821538984775543,
                  "y": 1.562636137008667,
                  "z": -0.08416374772787094
                },
                "index0": {
                  "x": 0.19970324635505676,
                  "y": 1.643488883972168,
                  "z": -0.11399906128644943
                },
                "middle0": {
                  "x": 0.2162405252456665,
                  "y": 1.6577839851379395,
                  "z": -0.13954293727874756
                },
                "ring0": {
                  "x": 0.23362424969673157,
                  "y": 1.6465342044830322,
                  "z": -0.16103079915046692
                },
                "pinky0": {
                  "x": 0.2489645630121231,
                  "y": 1.6172943115234375,
                  "z": -0.18871134519577026
                },
                "thumb1": {
                  "x": 0.21369126439094543,
                  "y": 1.5546209812164307,
                  "z": 0.06713417172431946
                },
                "index1": {
                  "x": 0.24520304799079895,
                  "y": 1.6485928297042847,
                  "z": 0.07788034528493881
                },
                "middle1": {
                  "x": 0.2679678499698639,
                  "y": 1.6645817756652832,
                  "z": 0.08123329281806946
                },
                "ring1": {
                  "x": 0.295022577047348,
                  "y": 1.6600507497787476,
                  "z": 0.09159744530916214
                },
                "pinky1": {
                  "x": 0.33143511414527893,
                  "y": 1.6340036392211914,
                  "z": 0.09954381734132767
                }
              },
              {
                "thumb0": {
                  "x": 0.19163687527179718,
                  "y": 1.5627632141113281,
                  "z": -0.0840810090303421
                },
                "index0": {
                  "x": 0.2012137472629547,
                  "y": 1.6442532539367676,
                  "z": -0.11301008611917496
                },
                "middle0": {
                  "x": 0.21652303636074066,
                  "y": 1.6588146686553955,
                  "z": -0.13853858411312103
                },
                "ring0": {
                  "x": 0.23229411244392395,
                  "y": 1.6487592458724976,
                  "z": -0.16147619485855103
                },
                "pinky0": {
                  "x": 0.24693424999713898,
                  "y": 1.6198779344558716,
                  "z": -0.19000080227851868
                },
                "thumb1": {
                  "x": 0.21252737939357758,
                  "y": 1.557200312614441,
                  "z": 0.06556840986013412
                },
                "index1": {
                  "x": 0.24516530334949493,
                  "y": 1.6511166095733643,
                  "z": 0.07605191320180893
                },
                "middle1": {
                  "x": 0.2676396071910858,
                  "y": 1.6673007011413574,
                  "z": 0.08071280270814896
                },
                "ring1": {
                  "x": 0.29412516951560974,
                  "y": 1.662688970565796,
                  "z": 0.09210396558046341
                },
                "pinky1": {
                  "x": 0.33045876026153564,
                  "y": 1.6363123655319214,
                  "z": 0.10171645879745483
                }
              },
              {
                "thumb0": {
                  "x": 0.19068582355976105,
                  "y": 1.5643864870071411,
                  "z": -0.08583999425172806
                },
                "index0": {
                  "x": 0.20022764801979065,
                  "y": 1.6458134651184082,
                  "z": -0.11463344842195511
                },
                "middle0": {
                  "x": 0.21610508859157562,
                  "y": 1.6601547002792358,
                  "z": -0.13984325528144836
                },
                "ring0": {
                  "x": 0.2318108081817627,
                  "y": 1.6503700017929077,
                  "z": -0.16263823211193085
                },
                "pinky0": {
                  "x": 0.2467532455921173,
                  "y": 1.6215133666992188,
                  "z": -0.19065341353416443
                },
                "thumb1": {
                  "x": 0.21059918403625488,
                  "y": 1.5594043731689453,
                  "z": 0.06680037826299667
                },
                "index1": {
                  "x": 0.2407328188419342,
                  "y": 1.6547294855117798,
                  "z": 0.07747381180524826
                },
                "middle1": {
                  "x": 0.2626481354236603,
                  "y": 1.6715681552886963,
                  "z": 0.08134941011667252
                },
                "ring1": {
                  "x": 0.2888377606868744,
                  "y": 1.667715072631836,
                  "z": 0.09222803264856339
                },
                "pinky1": {
                  "x": 0.32595524191856384,
                  "y": 1.6431801319122314,
                  "z": 0.10158578306436539
                }
              }
            ],
            "words": "point"
          },
          {
            "key": "9f7c9acd-c9e0-5051-71d5-889f13f8b145",
            "gesture": [
              {
                "thumb0": {
                  "x": 0.18702536821365356,
                  "y": 1.5649107694625854,
                  "z": -0.06778886169195175
                },
                "index0": {
                  "x": 0.1633119434118271,
                  "y": 1.6643297672271729,
                  "z": -0.09232433140277863
                },
                "middle0": {
                  "x": 0.1782156378030777,
                  "y": 1.6839834451675415,
                  "z": -0.12024156749248505
                },
                "ring0": {
                  "x": 0.18997879326343536,
                  "y": 1.6769814491271973,
                  "z": -0.1477797031402588
                },
                "pinky0": {
                  "x": 0.20667019486427307,
                  "y": 1.6511492729187012,
                  "z": -0.18246957659721375
                },
                "thumb1": {
                  "x": 0.19637064635753632,
                  "y": 1.5573170185089111,
                  "z": 0.04766843095421791
                },
                "index1": {
                  "x": 0.1905219554901123,
                  "y": 1.6598190069198608,
                  "z": 0.09070998430252075
                },
                "middle1": {
                  "x": 0.2169221192598343,
                  "y": 1.6811792850494385,
                  "z": 0.09193433076143265
                },
                "ring1": {
                  "x": 0.24598494172096252,
                  "y": 1.6802995204925537,
                  "z": 0.10430748015642166
                },
                "pinky1": {
                  "x": 0.28757017850875854,
                  "y": 1.6594041585922241,
                  "z": 0.11489605158567429
                }
              },
              {
                "thumb0": {
                  "x": 0.18728603422641754,
                  "y": 1.5646891593933105,
                  "z": -0.06944634020328522
                },
                "index0": {
                  "x": 0.16581882536411285,
                  "y": 1.6627600193023682,
                  "z": -0.09316065907478333
                },
                "middle0": {
                  "x": 0.1798897236585617,
                  "y": 1.6828657388687134,
                  "z": -0.12212168425321579
                },
                "ring0": {
                  "x": 0.19072912633419037,
                  "y": 1.6760551929473877,
                  "z": -0.15017348527908325
                },
                "pinky0": {
                  "x": 0.20683740079402924,
                  "y": 1.6500405073165894,
                  "z": -0.18489858508110046
                },
                "thumb1": {
                  "x": 0.19881922006607056,
                  "y": 1.5586426258087158,
                  "z": 0.04552946239709854
                },
                "index1": {
                  "x": 0.19312739372253418,
                  "y": 1.6592320203781128,
                  "z": 0.08685770630836487
                },
                "middle1": {
                  "x": 0.21904246509075165,
                  "y": 1.6812959909439087,
                  "z": 0.09082122147083282
                },
                "ring1": {
                  "x": 0.24805666506290436,
                  "y": 1.680207371711731,
                  "z": 0.10363087058067322
                },
                "pinky1": {
                  "x": 0.28955507278442383,
                  "y": 1.6580663919448853,
                  "z": 0.112090103328228
                }
              },
              {
                "thumb0": {
                  "x": 0.18812346458435059,
                  "y": 1.563784122467041,
                  "z": -0.07023017108440399
                },
                "index0": {
                  "x": 0.16800826787948608,
                  "y": 1.6611994504928589,
                  "z": -0.09329897165298462
                },
                "middle0": {
                  "x": 0.18149922788143158,
                  "y": 1.6816054582595825,
                  "z": -0.12251181900501251
                },
                "ring0": {
                  "x": 0.19145555794239044,
                  "y": 1.675150752067566,
                  "z": -0.15095923840999603
                },
                "pinky0": {
                  "x": 0.20737126469612122,
                  "y": 1.6491800546646118,
                  "z": -0.18570080399513245
                },
                "thumb1": {
                  "x": 0.20128650963306427,
                  "y": 1.5627727508544922,
                  "z": 0.0429256372153759
                },
                "index1": {
                  "x": 0.19414563477039337,
                  "y": 1.6591882705688477,
                  "z": 0.08121893554925919
                },
                "middle1": {
                  "x": 0.2183692306280136,
                  "y": 1.6830641031265259,
                  "z": 0.08969216048717499
                },
                "ring1": {
                  "x": 0.24723312258720398,
                  "y": 1.6816121339797974,
                  "z": 0.10202372819185257
                },
                "pinky1": {
                  "x": 0.289087176322937,
                  "y": 1.657987356185913,
                  "z": 0.10724927484989166
                }
              },
              {
                "thumb0": {
                  "x": 0.18668930232524872,
                  "y": 1.562254786491394,
                  "z": -0.07304603606462479
                },
                "index0": {
                  "x": 0.16759082674980164,
                  "y": 1.6589245796203613,
                  "z": -0.09549202024936676
                },
                "middle0": {
                  "x": 0.17992940545082092,
                  "y": 1.6798681020736694,
                  "z": -0.12567651271820068
                },
                "ring0": {
                  "x": 0.18876998126506805,
                  "y": 1.6736711263656616,
                  "z": -0.15487545728683472
                },
                "pinky0": {
                  "x": 0.20457400381565094,
                  "y": 1.6473124027252197,
                  "z": -0.18933799862861633
                },
                "thumb1": {
                  "x": 0.21204352378845215,
                  "y": 1.5696144104003906,
                  "z": 0.036180280148983
                },
                "index1": {
                  "x": 0.20185987651348114,
                  "y": 1.6524276733398438,
                  "z": 0.06147756427526474
                },
                "middle1": {
                  "x": 0.21605321764945984,
                  "y": 1.6861099004745483,
                  "z": 0.08216583728790283
                },
                "ring1": {
                  "x": 0.24311712384223938,
                  "y": 1.686594009399414,
                  "z": 0.09743784368038177
                },
                "pinky1": {
                  "x": 0.28407174348831177,
                  "y": 1.6652183532714844,
                  "z": 0.10555323213338852
                }
              },
              {
                "thumb0": {
                  "x": 0.1794854998588562,
                  "y": 1.5615431070327759,
                  "z": -0.07222997397184372
                },
                "index0": {
                  "x": 0.16094300150871277,
                  "y": 1.6577714681625366,
                  "z": -0.09594681113958359
                },
                "middle0": {
                  "x": 0.17374761402606964,
                  "y": 1.6784685850143433,
                  "z": -0.12608033418655396
                },
                "ring0": {
                  "x": 0.18249405920505524,
                  "y": 1.6723618507385254,
                  "z": -0.15553036332130432
                },
                "pinky0": {
                  "x": 0.19904404878616333,
                  "y": 1.6458503007888794,
                  "z": -0.1899091899394989
                },
                "thumb1": {
                  "x": 0.3040737509727478,
                  "y": 1.594145655632019,
                  "z": -0.0038176923990249634
                },
                "index1": {
                  "x": 0.30418094992637634,
                  "y": 1.6150383949279785,
                  "z": 0.007238216698169708
                },
                "middle1": {
                  "x": 0.2956271469593048,
                  "y": 1.685170292854309,
                  "z": 0.03692271560430527
                },
                "ring1": {
                  "x": 0.31825801730155945,
                  "y": 1.6872833967208862,
                  "z": 0.058374401181936264
                },
                "pinky1": {
                  "x": 0.35570770502090454,
                  "y": 1.6653633117675781,
                  "z": 0.06404505670070648
                }
              },
              {
                "thumb0": {
                  "x": 0.17231164872646332,
                  "y": 1.5616079568862915,
                  "z": -0.06387066096067429
                },
                "index0": {
                  "x": 0.15247882902622223,
                  "y": 1.6572095155715942,
                  "z": -0.08926551789045334
                },
                "middle0": {
                  "x": 0.1654527485370636,
                  "y": 1.6771371364593506,
                  "z": -0.11810892075300217
                },
                "ring0": {
                  "x": 0.17421148717403412,
                  "y": 1.671195387840271,
                  "z": -0.14720523357391357
                },
                "pinky0": {
                  "x": 0.19178850948810577,
                  "y": 1.644821286201477,
                  "z": -0.1806773990392685
                },
                "thumb1": {
                  "x": 0.3406829237937927,
                  "y": 1.5839430093765259,
                  "z": -0.010538972914218903
                },
                "index1": {
                  "x": 0.34528180956840515,
                  "y": 1.5857200622558594,
                  "z": -0.009611204266548157
                },
                "middle1": {
                  "x": 0.3366261124610901,
                  "y": 1.6525459289550781,
                  "z": 0.014657355844974518
                },
                "ring1": {
                  "x": 0.35477688908576965,
                  "y": 1.653847098350525,
                  "z": 0.03645530343055725
                },
                "pinky1": {
                  "x": 0.38702234625816345,
                  "y": 1.6294357776641846,
                  "z": 0.038977280259132385
                }
              },
              {
                "thumb0": {
                  "x": 0.1739731878042221,
                  "y": 1.5634191036224365,
                  "z": -0.0665488988161087
                },
                "index0": {
                  "x": 0.15164943039417267,
                  "y": 1.659805417060852,
                  "z": -0.08991716802120209
                },
                "middle0": {
                  "x": 0.16454964876174927,
                  "y": 1.6797640323638916,
                  "z": -0.11839011311531067
                },
                "ring0": {
                  "x": 0.17180539667606354,
                  "y": 1.6743654012680054,
                  "z": -0.14815446734428406
                },
                "pinky0": {
                  "x": 0.18832597136497498,
                  "y": 1.6487243175506592,
                  "z": -0.182390958070755
                },
                "thumb1": {
                  "x": 0.3131462335586548,
                  "y": 1.60610830783844,
                  "z": 0.004101254045963287
                },
                "index1": {
                  "x": 0.31981590390205383,
                  "y": 1.606945514678955,
                  "z": 0.002642117440700531
                },
                "middle1": {
                  "x": 0.3103683888912201,
                  "y": 1.6696898937225342,
                  "z": 0.021085120737552643
                },
                "ring1": {
                  "x": 0.327603280544281,
                  "y": 1.6735225915908813,
                  "z": 0.04124230146408081
                },
                "pinky1": {
                  "x": 0.35893571376800537,
                  "y": 1.6509209871292114,
                  "z": 0.04405877739191055
                }
              },
              {
                "thumb0": {
                  "x": 0.17302945256233215,
                  "y": 1.5641071796417236,
                  "z": -0.06777500361204147
                },
                "index0": {
                  "x": 0.14794927835464478,
                  "y": 1.6606987714767456,
                  "z": -0.09111034125089645
                },
                "middle0": {
                  "x": 0.16199997067451477,
                  "y": 1.6803267002105713,
                  "z": -0.11870130896568298
                },
                "ring0": {
                  "x": 0.1693209707736969,
                  "y": 1.6752318143844604,
                  "z": -0.14812961220741272
                },
                "pinky0": {
                  "x": 0.1857040524482727,
                  "y": 1.6503888368606567,
                  "z": -0.18230947852134705
                },
                "thumb1": {
                  "x": 0.30810868740081787,
                  "y": 1.6074233055114746,
                  "z": 0.0026940926909446716
                },
                "index1": {
                  "x": 0.31660962104797363,
                  "y": 1.6113232374191284,
                  "z": 0.0004657134413719177
                },
                "middle1": {
                  "x": 0.30762210488319397,
                  "y": 1.668806791305542,
                  "z": 0.016645170748233795
                },
                "ring1": {
                  "x": 0.3239436149597168,
                  "y": 1.6736844778060913,
                  "z": 0.034508801996707916
                },
                "pinky1": {
                  "x": 0.3547114133834839,
                  "y": 1.6530085802078247,
                  "z": 0.039455167949199677
                }
              },
              {
                "thumb0": {
                  "x": 0.1739412248134613,
                  "y": 1.566956639289856,
                  "z": -0.0723380371928215
                },
                "index0": {
                  "x": 0.14718568325042725,
                  "y": 1.662861943244934,
                  "z": -0.09461477398872375
                },
                "middle0": {
                  "x": 0.1614687591791153,
                  "y": 1.6821389198303223,
                  "z": -0.1219262033700943
                },
                "ring0": {
                  "x": 0.16790182888507843,
                  "y": 1.676985740661621,
                  "z": -0.15223200619220734
                },
                "pinky0": {
                  "x": 0.1842816025018692,
                  "y": 1.651893973350525,
                  "z": -0.18664619326591492
                },
                "thumb1": {
                  "x": 0.28009676933288574,
                  "y": 1.631568193435669,
                  "z": 0.011907681822776794
                },
                "index1": {
                  "x": 0.29204362630844116,
                  "y": 1.634147047996521,
                  "z": 0.008170612156391144
                },
                "middle1": {
                  "x": 0.28019770979881287,
                  "y": 1.6947038173675537,
                  "z": 0.024628348648548126
                },
                "ring1": {
                  "x": 0.2964981198310852,
                  "y": 1.6994872093200684,
                  "z": 0.041609786450862885
                },
                "pinky1": {
                  "x": 0.32821980118751526,
                  "y": 1.6796488761901855,
                  "z": 0.04737325757741928
                }
              },
              {
                "thumb0": {
                  "x": 0.17372740805149078,
                  "y": 1.5706226825714111,
                  "z": -0.0775066390633583
                },
                "index0": {
                  "x": 0.14362843334674835,
                  "y": 1.6674816608428955,
                  "z": -0.09903786331415176
                },
                "middle0": {
                  "x": 0.15891849994659424,
                  "y": 1.6868036985397339,
                  "z": -0.1257288157939911
                },
                "ring0": {
                  "x": 0.16554412245750427,
                  "y": 1.682166576385498,
                  "z": -0.1563509851694107
                },
                "pinky0": {
                  "x": 0.18290090560913086,
                  "y": 1.6575208902359009,
                  "z": -0.1911042183637619
                },
                "thumb1": {
                  "x": 0.153473362326622,
                  "y": 1.608431339263916,
                  "z": 0.0802706629037857
                },
                "index1": {
                  "x": 0.1555650234222412,
                  "y": 1.6720975637435913,
                  "z": 0.08330625295639038
                },
                "middle1": {
                  "x": 0.16120104491710663,
                  "y": 1.7093045711517334,
                  "z": 0.1103585809469223
                },
                "ring1": {
                  "x": 0.18515737354755402,
                  "y": 1.7077194452285767,
                  "z": 0.11355705559253693
                },
                "pinky1": {
                  "x": 0.22330383956432343,
                  "y": 1.6936239004135132,
                  "z": 0.11796941608190536
                }
              },
              {
                "thumb0": {
                  "x": 0.18572625517845154,
                  "y": 1.565191626548767,
                  "z": -0.07529627531766891
                },
                "index0": {
                  "x": 0.15543434023857117,
                  "y": 1.6627235412597656,
                  "z": -0.09701841324567795
                },
                "middle0": {
                  "x": 0.1703776866197586,
                  "y": 1.6814305782318115,
                  "z": -0.12373746931552887
                },
                "ring0": {
                  "x": 0.17643332481384277,
                  "y": 1.6767444610595703,
                  "z": -0.1539229154586792
                },
                "pinky0": {
                  "x": 0.19272150099277496,
                  "y": 1.6521836519241333,
                  "z": -0.18834348022937775
                },
                "thumb1": {
                  "x": 0.18481668829917908,
                  "y": 1.5673762559890747,
                  "z": 0.06148689612746239
                },
                "index1": {
                  "x": 0.17839974164962769,
                  "y": 1.6693589687347412,
                  "z": 0.08939480781555176
                },
                "middle1": {
                  "x": 0.20676806569099426,
                  "y": 1.6906720399856567,
                  "z": 0.08895692974328995
                },
                "ring1": {
                  "x": 0.23589612543582916,
                  "y": 1.688807487487793,
                  "z": 0.09697088599205017
                },
                "pinky1": {
                  "x": 0.2741932272911072,
                  "y": 1.6705207824707031,
                  "z": 0.1119164526462555
                }
              },
              {
                "thumb0": {
                  "x": 0.1935126632452011,
                  "y": 1.55961012840271,
                  "z": -0.0718080922961235
                },
                "index0": {
                  "x": 0.16543874144554138,
                  "y": 1.6580864191055298,
                  "z": -0.09411586821079254
                },
                "middle0": {
                  "x": 0.18078312277793884,
                  "y": 1.6765719652175903,
                  "z": -0.12092593312263489
                },
                "ring0": {
                  "x": 0.1868824064731598,
                  "y": 1.6718056201934814,
                  "z": -0.15097227692604065
                },
                "pinky0": {
                  "x": 0.202731192111969,
                  "y": 1.6469826698303223,
                  "z": -0.18500137329101562
                },
                "thumb1": {
                  "x": 0.19661808013916016,
                  "y": 1.5473246574401855,
                  "z": 0.05742836371064186
                },
                "index1": {
                  "x": 0.19914565980434418,
                  "y": 1.66041898727417,
                  "z": 0.08641817420721054
                },
                "middle1": {
                  "x": 0.22787828743457794,
                  "y": 1.6803680658340454,
                  "z": 0.0871717780828476
                },
                "ring1": {
                  "x": 0.2553377151489258,
                  "y": 1.6779139041900635,
                  "z": 0.1003221720457077
                },
                "pinky1": {
                  "x": 0.2928975224494934,
                  "y": 1.656868577003479,
                  "z": 0.11515096575021744
                }
              },
              {
                "thumb0": {
                  "x": 0.1963990181684494,
                  "y": 1.5587691068649292,
                  "z": -0.06857293099164963
                },
                "index0": {
                  "x": 0.16997544467449188,
                  "y": 1.657800316810608,
                  "z": -0.0911749005317688
                },
                "middle0": {
                  "x": 0.18532179296016693,
                  "y": 1.676331639289856,
                  "z": -0.11827059835195541
                },
                "ring0": {
                  "x": 0.1910286247730255,
                  "y": 1.6715478897094727,
                  "z": -0.14846810698509216
                },
                "pinky0": {
                  "x": 0.20705752074718475,
                  "y": 1.6464216709136963,
                  "z": -0.18221551179885864
                },
                "thumb1": {
                  "x": 0.2042534202337265,
                  "y": 1.5531163215637207,
                  "z": 0.058252591639757156
                },
                "index1": {
                  "x": 0.20430001616477966,
                  "y": 1.6641182899475098,
                  "z": 0.09233257174491882
                },
                "middle1": {
                  "x": 0.23039981722831726,
                  "y": 1.6849949359893799,
                  "z": 0.09738176316022873
                },
                "ring1": {
                  "x": 0.25811275839805603,
                  "y": 1.6824023723602295,
                  "z": 0.11184030026197433
                },
                "pinky1": {
                  "x": 0.29623085260391235,
                  "y": 1.6603283882141113,
                  "z": 0.12552745640277863
                }
              },
              {
                "thumb0": {
                  "x": 0.19810351729393005,
                  "y": 1.5585737228393555,
                  "z": -0.06801021844148636
                },
                "index0": {
                  "x": 0.17243020236492157,
                  "y": 1.6577881574630737,
                  "z": -0.09003622829914093
                },
                "middle0": {
                  "x": 0.1876811534166336,
                  "y": 1.6767433881759644,
                  "z": -0.11740647256374359
                },
                "ring0": {
                  "x": 0.19388450682163239,
                  "y": 1.6717089414596558,
                  "z": -0.1471886932849884
                },
                "pinky0": {
                  "x": 0.20975665748119354,
                  "y": 1.646751046180725,
                  "z": -0.18113768100738525
                },
                "thumb1": {
                  "x": 0.20795591175556183,
                  "y": 1.5584970712661743,
                  "z": 0.05991680547595024
                },
                "index1": {
                  "x": 0.20489981770515442,
                  "y": 1.664528250694275,
                  "z": 0.09509284794330597
                },
                "middle1": {
                  "x": 0.22910210490226746,
                  "y": 1.6853089332580566,
                  "z": 0.1008138656616211
                },
                "ring1": {
                  "x": 0.25697895884513855,
                  "y": 1.6828958988189697,
                  "z": 0.11518514901399612
                },
                "pinky1": {
                  "x": 0.29624271392822266,
                  "y": 1.6602885723114014,
                  "z": 0.12679755687713623
                }
              },
              {
                "thumb0": {
                  "x": 0.19851207733154297,
                  "y": 1.557778239250183,
                  "z": -0.06788045912981033
                },
                "index0": {
                  "x": 0.17367443442344666,
                  "y": 1.6572760343551636,
                  "z": -0.08856599777936935
                },
                "middle0": {
                  "x": 0.18893983960151672,
                  "y": 1.6767041683197021,
                  "z": -0.1157991960644722
                },
                "ring0": {
                  "x": 0.19474299252033234,
                  "y": 1.6721822023391724,
                  "z": -0.1459200382232666
                },
                "pinky0": {
                  "x": 0.21043428778648376,
                  "y": 1.6477160453796387,
                  "z": -0.1805737316608429
                },
                "thumb1": {
                  "x": 0.21181921660900116,
                  "y": 1.5663295984268188,
                  "z": 0.06164771690964699
                },
                "index1": {
                  "x": 0.20397962629795074,
                  "y": 1.6651090383529663,
                  "z": 0.09636916220188141
                },
                "middle1": {
                  "x": 0.22706282138824463,
                  "y": 1.6847704648971558,
                  "z": 0.10108354687690735
                },
                "ring1": {
                  "x": 0.2549700140953064,
                  "y": 1.682909607887268,
                  "z": 0.11541974544525146
                },
                "pinky1": {
                  "x": 0.2944470942020416,
                  "y": 1.660142421722412,
                  "z": 0.12587100267410278
                }
              },
              {
                "thumb0": {
                  "x": 0.19804008305072784,
                  "y": 1.5571995973587036,
                  "z": -0.06773319095373154
                },
                "index0": {
                  "x": 0.17454569041728973,
                  "y": 1.656856894493103,
                  "z": -0.0888989046216011
                },
                "middle0": {
                  "x": 0.18974995613098145,
                  "y": 1.676254153251648,
                  "z": -0.11630704998970032
                },
                "ring0": {
                  "x": 0.19527211785316467,
                  "y": 1.6716200113296509,
                  "z": -0.14664095640182495
                },
                "pinky0": {
                  "x": 0.21092252433300018,
                  "y": 1.6467753648757935,
                  "z": -0.18115535378456116
                },
                "thumb1": {
                  "x": 0.20102812349796295,
                  "y": 1.5641611814498901,
                  "z": 0.0646076649427414
                },
                "index1": {
                  "x": 0.20263534784317017,
                  "y": 1.6652171611785889,
                  "z": 0.09533947706222534
                },
                "middle1": {
                  "x": 0.2253486067056656,
                  "y": 1.684712529182434,
                  "z": 0.0984978899359703
                },
                "ring1": {
                  "x": 0.25446635484695435,
                  "y": 1.6823737621307373,
                  "z": 0.10904552787542343
                },
                "pinky1": {
                  "x": 0.29220640659332275,
                  "y": 1.6603344678878784,
                  "z": 0.11991774290800095
                }
              },
              {
                "thumb0": {
                  "x": 0.19540223479270935,
                  "y": 1.5573618412017822,
                  "z": -0.06795033812522888
                },
                "index0": {
                  "x": 0.1722693145275116,
                  "y": 1.6570026874542236,
                  "z": -0.08936133235692978
                },
                "middle0": {
                  "x": 0.18759766221046448,
                  "y": 1.6764237880706787,
                  "z": -0.1169496551156044
                },
                "ring0": {
                  "x": 0.1931712031364441,
                  "y": 1.671930193901062,
                  "z": -0.14755094051361084
                },
                "pinky0": {
                  "x": 0.20869550108909607,
                  "y": 1.6469351053237915,
                  "z": -0.1822812557220459
                },
                "thumb1": {
                  "x": 0.1987791657447815,
                  "y": 1.5631415843963623,
                  "z": 0.06291630119085312
                },
                "index1": {
                  "x": 0.20095497369766235,
                  "y": 1.6667407751083374,
                  "z": 0.09460973739624023
                },
                "middle1": {
                  "x": 0.2236119657754898,
                  "y": 1.6865099668502808,
                  "z": 0.09828110784292221
                },
                "ring1": {
                  "x": 0.25289812684059143,
                  "y": 1.68353271484375,
                  "z": 0.10821385681629181
                },
                "pinky1": {
                  "x": 0.2907088100910187,
                  "y": 1.6622867584228516,
                  "z": 0.12112691253423691
                }
              },
              {
                "thumb0": {
                  "x": 0.19461113214492798,
                  "y": 1.5572282075881958,
                  "z": -0.06791359186172485
                },
                "index0": {
                  "x": 0.17139749228954315,
                  "y": 1.6569663286209106,
                  "z": -0.08905269205570221
                },
                "middle0": {
                  "x": 0.18666881322860718,
                  "y": 1.6764955520629883,
                  "z": -0.11672566086053848
                },
                "ring0": {
                  "x": 0.1922519952058792,
                  "y": 1.6720339059829712,
                  "z": -0.1473534256219864
                },
                "pinky0": {
                  "x": 0.20792189240455627,
                  "y": 1.646978735923767,
                  "z": -0.18207195401191711
                },
                "thumb1": {
                  "x": 0.1986323595046997,
                  "y": 1.5619559288024902,
                  "z": 0.06203368306159973
                },
                "index1": {
                  "x": 0.20050328969955444,
                  "y": 1.6663801670074463,
                  "z": 0.09421595185995102
                },
                "middle1": {
                  "x": 0.22254693508148193,
                  "y": 1.6868458986282349,
                  "z": 0.09946293383836746
                },
                "ring1": {
                  "x": 0.2517752945423126,
                  "y": 1.683657169342041,
                  "z": 0.10947112739086151
                },
                "pinky1": {
                  "x": 0.28968507051467896,
                  "y": 1.6624653339385986,
                  "z": 0.12248978018760681
                }
              },
              {
                "thumb0": {
                  "x": 0.19482241570949554,
                  "y": 1.557013988494873,
                  "z": -0.06796922534704208
                },
                "index0": {
                  "x": 0.17232143878936768,
                  "y": 1.6571568250656128,
                  "z": -0.09017015993595123
                },
                "middle0": {
                  "x": 0.18772178888320923,
                  "y": 1.676215648651123,
                  "z": -0.11807995289564133
                },
                "ring0": {
                  "x": 0.1931079626083374,
                  "y": 1.6714133024215698,
                  "z": -0.14873316884040833
                },
                "pinky0": {
                  "x": 0.20870710909366608,
                  "y": 1.645867109298706,
                  "z": -0.18332412838935852
                },
                "thumb1": {
                  "x": 0.19945786893367767,
                  "y": 1.5611796379089355,
                  "z": 0.061617959290742874
                },
                "index1": {
                  "x": 0.20087142288684845,
                  "y": 1.6648188829421997,
                  "z": 0.09343639016151428
                },
                "middle1": {
                  "x": 0.2223532348871231,
                  "y": 1.6860450506210327,
                  "z": 0.09955406934022903
                },
                "ring1": {
                  "x": 0.2507810890674591,
                  "y": 1.6839584112167358,
                  "z": 0.11163800209760666
                },
                "pinky1": {
                  "x": 0.2892702519893646,
                  "y": 1.6624187231063843,
                  "z": 0.12280069291591644
                }
              },
              {
                "thumb0": {
                  "x": 0.19611699879169464,
                  "y": 1.5568164587020874,
                  "z": -0.0683785006403923
                },
                "index0": {
                  "x": 0.17274928092956543,
                  "y": 1.6570029258728027,
                  "z": -0.09032700210809708
                },
                "middle0": {
                  "x": 0.1875789314508438,
                  "y": 1.6761016845703125,
                  "z": -0.11832362413406372
                },
                "ring0": {
                  "x": 0.1926322728395462,
                  "y": 1.6711958646774292,
                  "z": -0.14878150820732117
                },
                "pinky0": {
                  "x": 0.2079596370458603,
                  "y": 1.6457959413528442,
                  "z": -0.18370011448860168
                },
                "thumb1": {
                  "x": 0.20034945011138916,
                  "y": 1.5619431734085083,
                  "z": 0.06208358705043793
                },
                "index1": {
                  "x": 0.2006533145904541,
                  "y": 1.6646664142608643,
                  "z": 0.09432706236839294
                },
                "middle1": {
                  "x": 0.22169525921344757,
                  "y": 1.6859139204025269,
                  "z": 0.10062382370233536
                },
                "ring1": {
                  "x": 0.25001898407936096,
                  "y": 1.6840295791625977,
                  "z": 0.11266748607158661
                },
                "pinky1": {
                  "x": 0.28838297724723816,
                  "y": 1.6627821922302246,
                  "z": 0.12361297011375427
                }
              },
              {
                "thumb0": {
                  "x": 0.19688229262828827,
                  "y": 1.55772066116333,
                  "z": -0.0681731104850769
                },
                "index0": {
                  "x": 0.17271816730499268,
                  "y": 1.657196283340454,
                  "z": -0.0895518884062767
                },
                "middle0": {
                  "x": 0.1879342943429947,
                  "y": 1.6762053966522217,
                  "z": -0.11717839539051056
                },
                "ring0": {
                  "x": 0.19295309484004974,
                  "y": 1.671555757522583,
                  "z": -0.14784348011016846
                },
                "pinky0": {
                  "x": 0.20827320218086243,
                  "y": 1.6462982892990112,
                  "z": -0.18284685909748077
                },
                "thumb1": {
                  "x": 0.19913281500339508,
                  "y": 1.5627946853637695,
                  "z": 0.06342227756977081
                },
                "index1": {
                  "x": 0.19982632994651794,
                  "y": 1.6649086475372314,
                  "z": 0.09555011242628098
                },
                "middle1": {
                  "x": 0.2207508385181427,
                  "y": 1.6859890222549438,
                  "z": 0.10187200456857681
                },
                "ring1": {
                  "x": 0.24920369684696198,
                  "y": 1.6837875843048096,
                  "z": 0.11329551786184311
                },
                "pinky1": {
                  "x": 0.2870772182941437,
                  "y": 1.662932276725769,
                  "z": 0.1251162737607956
                }
              },
              {
                "thumb0": {
                  "x": 0.1977284550666809,
                  "y": 1.557204008102417,
                  "z": -0.0677177831530571
                },
                "index0": {
                  "x": 0.17313158512115479,
                  "y": 1.6569608449935913,
                  "z": -0.08849366009235382
                },
                "middle0": {
                  "x": 0.1879923790693283,
                  "y": 1.6759921312332153,
                  "z": -0.1161498874425888
                },
                "ring0": {
                  "x": 0.19256116449832916,
                  "y": 1.671463966369629,
                  "z": -0.14672473073005676
                },
                "pinky0": {
                  "x": 0.2076474130153656,
                  "y": 1.6463836431503296,
                  "z": -0.18202358484268188
                },
                "thumb1": {
                  "x": 0.19930124282836914,
                  "y": 1.5630416870117188,
                  "z": 0.06363768130540848
                },
                "index1": {
                  "x": 0.1997573971748352,
                  "y": 1.6647809743881226,
                  "z": 0.09590590000152588
                },
                "middle1": {
                  "x": 0.2205706238746643,
                  "y": 1.6858614683151245,
                  "z": 0.1022372916340828
                },
                "ring1": {
                  "x": 0.2489837259054184,
                  "y": 1.6837996244430542,
                  "z": 0.11382229626178741
                },
                "pinky1": {
                  "x": 0.28683432936668396,
                  "y": 1.6630250215530396,
                  "z": 0.12559829652309418
                }
              }
            ],
            "words": "point"
          },
          {
            "key": "9458d9af-68e1-e137-d7c8-546055a92cdd",
            "gesture": [
              {
                "thumb0": {
                  "x": 0.1997506320476532,
                  "y": 1.5609585046768188,
                  "z": -0.05699910968542099
                },
                "index0": {
                  "x": 0.19598239660263062,
                  "y": 1.650904893875122,
                  "z": -0.07745261490345001
                },
                "middle0": {
                  "x": 0.21910826861858368,
                  "y": 1.6638301610946655,
                  "z": -0.09998968988656998
                },
                "ring0": {
                  "x": 0.23630720376968384,
                  "y": 1.653059482574463,
                  "z": -0.12333352863788605
                },
                "pinky0": {
                  "x": 0.24811998009681702,
                  "y": 1.6271700859069824,
                  "z": -0.15536442399024963
                },
                "thumb1": {
                  "x": 0.21094690263271332,
                  "y": 1.5605114698410034,
                  "z": 0.038048453629016876
                },
                "index1": {
                  "x": 0.23693542182445526,
                  "y": 1.6563875675201416,
                  "z": 0.058937303721904755
                },
                "middle1": {
                  "x": 0.26008012890815735,
                  "y": 1.669175148010254,
                  "z": 0.059357307851314545
                },
                "ring1": {
                  "x": 0.283401221036911,
                  "y": 1.664973258972168,
                  "z": 0.07303550839424133
                },
                "pinky1": {
                  "x": 0.3189513087272644,
                  "y": 1.6379650831222534,
                  "z": 0.08217881619930267
                }
              },
              {
                "thumb0": {
                  "x": 0.19816337525844574,
                  "y": 1.5592639446258545,
                  "z": -0.04371040314435959
                },
                "index0": {
                  "x": 0.20038406550884247,
                  "y": 1.6480058431625366,
                  "z": -0.06487008929252625
                },
                "middle0": {
                  "x": 0.22446738183498383,
                  "y": 1.6618181467056274,
                  "z": -0.08742807060480118
                },
                "ring0": {
                  "x": 0.24358884990215302,
                  "y": 1.6499196290969849,
                  "z": -0.10918808728456497
                },
                "pinky0": {
                  "x": 0.2576134204864502,
                  "y": 1.6228008270263672,
                  "z": -0.14004042744636536
                },
                "thumb1": {
                  "x": 0.20170928537845612,
                  "y": 1.5649752616882324,
                  "z": 0.041838593780994415
                },
                "index1": {
                  "x": 0.23499302566051483,
                  "y": 1.6545599699020386,
                  "z": 0.05288653448224068
                },
                "middle1": {
                  "x": 0.25735974311828613,
                  "y": 1.6686350107192993,
                  "z": 0.05430392175912857
                },
                "ring1": {
                  "x": 0.2828081250190735,
                  "y": 1.6626317501068115,
                  "z": 0.061724983155727386
                },
                "pinky1": {
                  "x": 0.31745052337646484,
                  "y": 1.6349923610687256,
                  "z": 0.06765741109848022
                }
              },
              {
                "thumb0": {
                  "x": 0.19828058779239655,
                  "y": 1.5591334104537964,
                  "z": -0.04310169816017151
                },
                "index0": {
                  "x": 0.20001427829265594,
                  "y": 1.64743971824646,
                  "z": -0.06354271620512009
                },
                "middle0": {
                  "x": 0.22370244562625885,
                  "y": 1.6621887683868408,
                  "z": -0.08641545474529266
                },
                "ring0": {
                  "x": 0.24316728115081787,
                  "y": 1.6507341861724854,
                  "z": -0.10844878852367401
                },
                "pinky0": {
                  "x": 0.25783467292785645,
                  "y": 1.6236388683319092,
                  "z": -0.1393386721611023
                },
                "thumb1": {
                  "x": 0.20027194917201996,
                  "y": 1.5641568899154663,
                  "z": 0.04054548591375351
                },
                "index1": {
                  "x": 0.23373474180698395,
                  "y": 1.6526436805725098,
                  "z": 0.0495309978723526
                },
                "middle1": {
                  "x": 0.2555505931377411,
                  "y": 1.667474389076233,
                  "z": 0.05117839202284813
                },
                "ring1": {
                  "x": 0.28118112683296204,
                  "y": 1.6616359949111938,
                  "z": 0.05792772397398949
                },
                "pinky1": {
                  "x": 0.3154109716415405,
                  "y": 1.634934663772583,
                  "z": 0.06422941386699677
                }
              },
              {
                "thumb0": {
                  "x": 0.20126226544380188,
                  "y": 1.5589160919189453,
                  "z": -0.048046551644802094
                },
                "index0": {
                  "x": 0.1960090547800064,
                  "y": 1.6487270593643188,
                  "z": -0.06763094663619995
                },
                "middle0": {
                  "x": 0.21791931986808777,
                  "y": 1.6644151210784912,
                  "z": -0.09169746935367584
                },
                "ring0": {
                  "x": 0.23666511476039886,
                  "y": 1.6528236865997314,
                  "z": -0.1144680604338646
                },
                "pinky0": {
                  "x": 0.24991104006767273,
                  "y": 1.6261695623397827,
                  "z": -0.14612293243408203
                },
                "thumb1": {
                  "x": 0.20599059760570526,
                  "y": 1.5669019222259521,
                  "z": 0.031068041920661926
                },
                "index1": {
                  "x": 0.2343740612268448,
                  "y": 1.6555476188659668,
                  "z": 0.045670222491025925
                },
                "middle1": {
                  "x": 0.2565288841724396,
                  "y": 1.6700011491775513,
                  "z": 0.048128001391887665
                },
                "ring1": {
                  "x": 0.2815701365470886,
                  "y": 1.663507342338562,
                  "z": 0.05563545227050781
                },
                "pinky1": {
                  "x": 0.31471267342567444,
                  "y": 1.6372934579849243,
                  "z": 0.06563268601894379
                }
              },
              {
                "thumb0": {
                  "x": 0.2369593381881714,
                  "y": 1.5593461990356445,
                  "z": -0.06442707777023315
                },
                "index0": {
                  "x": 0.22821222245693207,
                  "y": 1.6342432498931885,
                  "z": -0.07425414025783539
                },
                "middle0": {
                  "x": 0.22925513982772827,
                  "y": 1.6576744318008423,
                  "z": -0.09915068000555038
                },
                "ring0": {
                  "x": 0.23349255323410034,
                  "y": 1.6484196186065674,
                  "z": -0.12548352777957916
                },
                "pinky0": {
                  "x": 0.23114463686943054,
                  "y": 1.6287721395492554,
                  "z": -0.15803919732570648
                },
                "thumb1": {
                  "x": 0.2483569234609604,
                  "y": 1.5703792572021484,
                  "z": 0.010524772107601166
                },
                "index1": {
                  "x": 0.2599741816520691,
                  "y": 1.6260586977005005,
                  "z": 0.027158886194229126
                },
                "middle1": {
                  "x": 0.27772289514541626,
                  "y": 1.6338558197021484,
                  "z": 0.034640900790691376
                },
                "ring1": {
                  "x": 0.2965061664581299,
                  "y": 1.6293929815292358,
                  "z": 0.04503419250249863
                },
                "pinky1": {
                  "x": 0.3152008056640625,
                  "y": 1.6145013570785522,
                  "z": 0.06491178274154663
                }
              },
              {
                "thumb0": {
                  "x": 0.28652289509773254,
                  "y": 1.5476038455963135,
                  "z": -0.0445706769824028
                },
                "index0": {
                  "x": 0.28213024139404297,
                  "y": 1.574466347694397,
                  "z": -0.054101645946502686
                },
                "middle0": {
                  "x": 0.2852849066257477,
                  "y": 1.6101731061935425,
                  "z": -0.06396079808473587
                },
                "ring0": {
                  "x": 0.27523550391197205,
                  "y": 1.623748779296875,
                  "z": -0.08274086564779282
                },
                "pinky0": {
                  "x": 0.2574358284473419,
                  "y": 1.61115300655365,
                  "z": -0.1056070327758789
                },
                "thumb1": {
                  "x": 0.2697986662387848,
                  "y": 1.558822512626648,
                  "z": -0.02189822494983673
                },
                "index1": {
                  "x": 0.26644405722618103,
                  "y": 1.5705347061157227,
                  "z": -0.00884866714477539
                },
                "middle1": {
                  "x": 0.27465301752090454,
                  "y": 1.582428216934204,
                  "z": -0.00017891079187393188
                },
                "ring1": {
                  "x": 0.2799190580844879,
                  "y": 1.5879813432693481,
                  "z": 0.01619972288608551
                },
                "pinky1": {
                  "x": 0.2853516638278961,
                  "y": 1.59091317653656,
                  "z": 0.0342714786529541
                }
              },
              {
                "thumb0": {
                  "x": 0.3102423846721649,
                  "y": 1.53726065158844,
                  "z": -0.02983492612838745
                },
                "index0": {
                  "x": 0.31059765815734863,
                  "y": 1.556002140045166,
                  "z": -0.04029268026351929
                },
                "middle0": {
                  "x": 0.31411004066467285,
                  "y": 1.5875862836837769,
                  "z": -0.05307511240243912
                },
                "ring0": {
                  "x": 0.3014153838157654,
                  "y": 1.608351707458496,
                  "z": -0.07313939183950424
                },
                "pinky0": {
                  "x": 0.27512189745903015,
                  "y": 1.6032465696334839,
                  "z": -0.09696894884109497
                },
                "thumb1": {
                  "x": 0.28215059638023376,
                  "y": 1.5526134967803955,
                  "z": -0.03666875511407852
                },
                "index1": {
                  "x": 0.2892882525920868,
                  "y": 1.55967116355896,
                  "z": -0.027941249310970306
                },
                "middle1": {
                  "x": 0.2984456717967987,
                  "y": 1.5976169109344482,
                  "z": -0.02504590153694153
                },
                "ring1": {
                  "x": 0.29770171642303467,
                  "y": 1.613222360610962,
                  "z": -0.0023504048585891724
                },
                "pinky1": {
                  "x": 0.29325711727142334,
                  "y": 1.6075069904327393,
                  "z": 0.02920108288526535
                }
              },
              {
                "thumb0": {
                  "x": 0.28923138976097107,
                  "y": 1.5550950765609741,
                  "z": -0.051465295255184174
                },
                "index0": {
                  "x": 0.29273104667663574,
                  "y": 1.569962739944458,
                  "z": -0.05982048064470291
                },
                "middle0": {
                  "x": 0.2950681149959564,
                  "y": 1.6157680749893188,
                  "z": -0.07184026390314102
                },
                "ring0": {
                  "x": 0.2782798111438751,
                  "y": 1.6324657201766968,
                  "z": -0.0939013659954071
                },
                "pinky0": {
                  "x": 0.25119492411613464,
                  "y": 1.623748540878296,
                  "z": -0.11830919235944748
                },
                "thumb1": {
                  "x": 0.28918230533599854,
                  "y": 1.5526683330535889,
                  "z": -0.02438180148601532
                },
                "index1": {
                  "x": 0.303291380405426,
                  "y": 1.5715473890304565,
                  "z": -0.021054789423942566
                },
                "middle1": {
                  "x": 0.29910778999328613,
                  "y": 1.6341928243637085,
                  "z": -0.011277958750724792
                },
                "ring1": {
                  "x": 0.2958774268627167,
                  "y": 1.6431593894958496,
                  "z": 0.019044414162635803
                },
                "pinky1": {
                  "x": 0.2947362959384918,
                  "y": 1.6276344060897827,
                  "z": 0.053311385214328766
                }
              },
              {
                "thumb0": {
                  "x": 0.21815721690654755,
                  "y": 1.6215158700942993,
                  "z": -0.24119198322296143
                },
                "index0": {
                  "x": 0.21831849217414856,
                  "y": 1.62055504322052,
                  "z": -0.23820683360099792
                },
                "middle0": {
                  "x": 0.21393167972564697,
                  "y": 1.6851434707641602,
                  "z": -0.2636770009994507
                },
                "ring0": {
                  "x": 0.19338253140449524,
                  "y": 1.6842769384384155,
                  "z": -0.28141874074935913
                },
                "pinky0": {
                  "x": 0.16413193941116333,
                  "y": 1.66380774974823,
                  "z": -0.300780713558197
                },
                "thumb1": {
                  "x": 0.3487429916858673,
                  "y": 1.608543038368225,
                  "z": 0.16095468401908875
                },
                "index1": {
                  "x": 0.3487285375595093,
                  "y": 1.6131041049957275,
                  "z": 0.16633634269237518
                },
                "middle1": {
                  "x": 0.3467474579811096,
                  "y": 1.6971710920333862,
                  "z": 0.18495912849903107
                },
                "ring1": {
                  "x": 0.33830681443214417,
                  "y": 1.6987906694412231,
                  "z": 0.22111141681671143
                },
                "pinky1": {
                  "x": 0.333921879529953,
                  "y": 1.6714259386062622,
                  "z": 0.25086474418640137
                }
              },
              {
                "thumb0": {
                  "x": 0.19334717094898224,
                  "y": 1.600835919380188,
                  "z": -0.2814536392688751
                },
                "index0": {
                  "x": 0.1983494758605957,
                  "y": 1.6002888679504395,
                  "z": -0.2778418958187103
                },
                "middle0": {
                  "x": 0.16490232944488525,
                  "y": 1.684630036354065,
                  "z": -0.30577218532562256
                },
                "ring0": {
                  "x": 0.13212068378925323,
                  "y": 1.6760025024414062,
                  "z": -0.3211829662322998
                },
                "pinky0": {
                  "x": 0.10537309944629669,
                  "y": 1.6381958723068237,
                  "z": -0.3362075388431549
                },
                "thumb1": {
                  "x": 0.3700356185436249,
                  "y": 1.610600233078003,
                  "z": 0.2330525815486908
                },
                "index1": {
                  "x": 0.3361089825630188,
                  "y": 1.6157922744750977,
                  "z": 0.2313857525587082
                },
                "middle1": {
                  "x": 0.327569842338562,
                  "y": 1.6045165061950684,
                  "z": 0.2563899755477905
                },
                "ring1": {
                  "x": 0.334227979183197,
                  "y": 1.5914759635925293,
                  "z": 0.2629842162132263
                },
                "pinky1": {
                  "x": 0.3427852988243103,
                  "y": 1.5769741535186768,
                  "z": 0.252872496843338
                }
              },
              {
                "thumb0": {
                  "x": 0.20379683375358582,
                  "y": 1.5925620794296265,
                  "z": -0.26447242498397827
                },
                "index0": {
                  "x": 0.2061537206172943,
                  "y": 1.5930533409118652,
                  "z": -0.2611207365989685
                },
                "middle0": {
                  "x": 0.17673428356647491,
                  "y": 1.6790345907211304,
                  "z": -0.2888542115688324
                },
                "ring0": {
                  "x": 0.14229242503643036,
                  "y": 1.6712737083435059,
                  "z": -0.3056786060333252
                },
                "pinky0": {
                  "x": 0.11591976135969162,
                  "y": 1.633381724357605,
                  "z": -0.32231608033180237
                },
                "thumb1": {
                  "x": 0.3700356185436249,
                  "y": 1.610600233078003,
                  "z": 0.2330525815486908
                },
                "index1": {
                  "x": 0.3361089825630188,
                  "y": 1.6157922744750977,
                  "z": 0.2313857525587082
                },
                "middle1": {
                  "x": 0.327569842338562,
                  "y": 1.6045165061950684,
                  "z": 0.2563899755477905
                },
                "ring1": {
                  "x": 0.334227979183197,
                  "y": 1.5914759635925293,
                  "z": 0.2629842162132263
                },
                "pinky1": {
                  "x": 0.3427852988243103,
                  "y": 1.5769741535186768,
                  "z": 0.252872496843338
                }
              },
              {
                "thumb0": {
                  "x": 0.22000129520893097,
                  "y": 1.5817984342575073,
                  "z": -0.2423924207687378
                },
                "index0": {
                  "x": 0.21209180355072021,
                  "y": 1.5959782600402832,
                  "z": -0.24405638873577118
                },
                "middle0": {
                  "x": 0.18977849185466766,
                  "y": 1.6774265766143799,
                  "z": -0.2738213837146759
                },
                "ring0": {
                  "x": 0.16254019737243652,
                  "y": 1.66679048538208,
                  "z": -0.2949894368648529
                },
                "pinky0": {
                  "x": 0.13580690324306488,
                  "y": 1.6301168203353882,
                  "z": -0.3131483495235443
                },
                "thumb1": {
                  "x": 0.3700356185436249,
                  "y": 1.610600233078003,
                  "z": 0.2330525815486908
                },
                "index1": {
                  "x": 0.3361089825630188,
                  "y": 1.6157922744750977,
                  "z": 0.2313857525587082
                },
                "middle1": {
                  "x": 0.327569842338562,
                  "y": 1.6045165061950684,
                  "z": 0.2563899755477905
                },
                "ring1": {
                  "x": 0.334227979183197,
                  "y": 1.5914759635925293,
                  "z": 0.2629842162132263
                },
                "pinky1": {
                  "x": 0.3427852988243103,
                  "y": 1.5769741535186768,
                  "z": 0.252872496843338
                }
              },
              {
                "thumb0": {
                  "x": 0.23441290855407715,
                  "y": 1.5728155374526978,
                  "z": -0.2093641459941864
                },
                "index0": {
                  "x": 0.2289171665906906,
                  "y": 1.6198782920837402,
                  "z": -0.2265835553407669
                },
                "middle0": {
                  "x": 0.1999305784702301,
                  "y": 1.6754704713821411,
                  "z": -0.252470463514328
                },
                "ring0": {
                  "x": 0.17673538625240326,
                  "y": 1.6640050411224365,
                  "z": -0.27618521451950073
                },
                "pinky0": {
                  "x": 0.152682825922966,
                  "y": 1.6271507740020752,
                  "z": -0.2987807095050812
                },
                "thumb1": {
                  "x": 0.3700356185436249,
                  "y": 1.610600233078003,
                  "z": 0.2330525815486908
                },
                "index1": {
                  "x": 0.3361089825630188,
                  "y": 1.6157922744750977,
                  "z": 0.2313857525587082
                },
                "middle1": {
                  "x": 0.327569842338562,
                  "y": 1.6045165061950684,
                  "z": 0.2563899755477905
                },
                "ring1": {
                  "x": 0.334227979183197,
                  "y": 1.5914759635925293,
                  "z": 0.2629842162132263
                },
                "pinky1": {
                  "x": 0.3427852988243103,
                  "y": 1.5769741535186768,
                  "z": 0.252872496843338
                }
              },
              {
                "thumb0": {
                  "x": 0.23720642924308777,
                  "y": 1.5825563669204712,
                  "z": -0.17874044179916382
                },
                "index0": {
                  "x": 0.22961653769016266,
                  "y": 1.6460341215133667,
                  "z": -0.21016298234462738
                },
                "middle0": {
                  "x": 0.21399910748004913,
                  "y": 1.6667320728302002,
                  "z": -0.23833855986595154
                },
                "ring0": {
                  "x": 0.19773037731647491,
                  "y": 1.6553045511245728,
                  "z": -0.2619532644748688
                },
                "pinky0": {
                  "x": 0.17827898263931274,
                  "y": 1.6237053871154785,
                  "z": -0.2841191589832306
                },
                "thumb1": {
                  "x": 0.3700356185436249,
                  "y": 1.610600233078003,
                  "z": 0.2330525815486908
                },
                "index1": {
                  "x": 0.3361089825630188,
                  "y": 1.6157922744750977,
                  "z": 0.2313857525587082
                },
                "middle1": {
                  "x": 0.327569842338562,
                  "y": 1.6045165061950684,
                  "z": 0.2563899755477905
                },
                "ring1": {
                  "x": 0.334227979183197,
                  "y": 1.5914759635925293,
                  "z": 0.2629842162132263
                },
                "pinky1": {
                  "x": 0.3427852988243103,
                  "y": 1.5769741535186768,
                  "z": 0.252872496843338
                }
              },
              {
                "thumb0": {
                  "x": 0.1938384771347046,
                  "y": 1.608843207359314,
                  "z": -0.1428823471069336
                },
                "index0": {
                  "x": 0.19698357582092285,
                  "y": 1.6654959917068481,
                  "z": -0.1926259547472
                },
                "middle0": {
                  "x": 0.21279945969581604,
                  "y": 1.6638596057891846,
                  "z": -0.2141868770122528
                },
                "ring0": {
                  "x": 0.2179068922996521,
                  "y": 1.644719123840332,
                  "z": -0.2343665063381195
                },
                "pinky0": {
                  "x": 0.21529227495193481,
                  "y": 1.6111379861831665,
                  "z": -0.2556624710559845
                },
                "thumb1": {
                  "x": 0.2712371349334717,
                  "y": 1.611985445022583,
                  "z": 0.14084886014461517
                },
                "index1": {
                  "x": 0.30887219309806824,
                  "y": 1.6658236980438232,
                  "z": 0.16931569576263428
                },
                "middle1": {
                  "x": 0.33034661412239075,
                  "y": 1.6712714433670044,
                  "z": 0.17882022261619568
                },
                "ring1": {
                  "x": 0.34965649247169495,
                  "y": 1.6563425064086914,
                  "z": 0.18842396140098572
                },
                "pinky1": {
                  "x": 0.3687884509563446,
                  "y": 1.6220834255218506,
                  "z": 0.20081642270088196
                }
              },
              {
                "thumb0": {
                  "x": 0.1746043860912323,
                  "y": 1.5986557006835938,
                  "z": -0.12397962063550949
                },
                "index0": {
                  "x": 0.19248180091381073,
                  "y": 1.6608526706695557,
                  "z": -0.1676301509141922
                },
                "middle0": {
                  "x": 0.2152334600687027,
                  "y": 1.6629503965377808,
                  "z": -0.1844862997531891
                },
                "ring0": {
                  "x": 0.22700978815555573,
                  "y": 1.64876127243042,
                  "z": -0.20311090350151062
                },
                "pinky0": {
                  "x": 0.23693042993545532,
                  "y": 1.6155380010604858,
                  "z": -0.22453218698501587
                },
                "thumb1": {
                  "x": 0.23593658208847046,
                  "y": 1.6100233793258667,
                  "z": 0.13660526275634766
                },
                "index1": {
                  "x": 0.28886523842811584,
                  "y": 1.6705561876296997,
                  "z": 0.16003963351249695
                },
                "middle1": {
                  "x": 0.3128241002559662,
                  "y": 1.6776460409164429,
                  "z": 0.16698825359344482
                },
                "ring1": {
                  "x": 0.3320985436439514,
                  "y": 1.6641836166381836,
                  "z": 0.1728580892086029
                },
                "pinky1": {
                  "x": 0.3537620007991791,
                  "y": 1.6315282583236694,
                  "z": 0.18129247426986694
                }
              },
              {
                "thumb0": {
                  "x": 0.1737290918827057,
                  "y": 1.5867135524749756,
                  "z": -0.10995255410671234
                },
                "index0": {
                  "x": 0.19518935680389404,
                  "y": 1.6551097631454468,
                  "z": -0.14750173687934875
                },
                "middle0": {
                  "x": 0.22044456005096436,
                  "y": 1.6607855558395386,
                  "z": -0.16290688514709473
                },
                "ring0": {
                  "x": 0.2370687574148178,
                  "y": 1.648253321647644,
                  "z": -0.18181642889976501
                },
                "pinky0": {
                  "x": 0.25230127573013306,
                  "y": 1.6161794662475586,
                  "z": -0.2050783634185791
                },
                "thumb1": {
                  "x": 0.23593658208847046,
                  "y": 1.6100233793258667,
                  "z": 0.13660526275634766
                },
                "index1": {
                  "x": 0.28886523842811584,
                  "y": 1.6705561876296997,
                  "z": 0.16003963351249695
                },
                "middle1": {
                  "x": 0.3128241002559662,
                  "y": 1.6776460409164429,
                  "z": 0.16698825359344482
                },
                "ring1": {
                  "x": 0.3320985436439514,
                  "y": 1.6641836166381836,
                  "z": 0.1728580892086029
                },
                "pinky1": {
                  "x": 0.3537620007991791,
                  "y": 1.6315282583236694,
                  "z": 0.18129247426986694
                }
              },
              {
                "thumb0": {
                  "x": 0.17882052063941956,
                  "y": 1.583916425704956,
                  "z": -0.09601826965808868
                },
                "index0": {
                  "x": 0.1993870586156845,
                  "y": 1.6553772687911987,
                  "z": -0.13176396489143372
                },
                "middle0": {
                  "x": 0.2231786847114563,
                  "y": 1.6634647846221924,
                  "z": -0.14937153458595276
                },
                "ring0": {
                  "x": 0.23926234245300293,
                  "y": 1.651727318763733,
                  "z": -0.17022423446178436
                },
                "pinky0": {
                  "x": 0.25518882274627686,
                  "y": 1.6192337274551392,
                  "z": -0.1948816031217575
                },
                "thumb1": {
                  "x": 0.22202624380588531,
                  "y": 1.5912681818008423,
                  "z": 0.10361620783805847
                },
                "index1": {
                  "x": 0.26692670583724976,
                  "y": 1.6649264097213745,
                  "z": 0.11577250808477402
                },
                "middle1": {
                  "x": 0.29647597670555115,
                  "y": 1.6747541427612305,
                  "z": 0.11435157060623169
                },
                "ring1": {
                  "x": 0.3216487765312195,
                  "y": 1.6643738746643066,
                  "z": 0.12402188777923584
                },
                "pinky1": {
                  "x": 0.3520810902118683,
                  "y": 1.6324747800827026,
                  "z": 0.13499382138252258
                }
              },
              {
                "thumb0": {
                  "x": 0.17816562950611115,
                  "y": 1.5830744504928589,
                  "z": -0.09979698807001114
                },
                "index0": {
                  "x": 0.19702444970607758,
                  "y": 1.6558302640914917,
                  "z": -0.13501524925231934
                },
                "middle0": {
                  "x": 0.21991346776485443,
                  "y": 1.6644008159637451,
                  "z": -0.15284456312656403
                },
                "ring0": {
                  "x": 0.23567837476730347,
                  "y": 1.6526557207107544,
                  "z": -0.1739720106124878
                },
                "pinky0": {
                  "x": 0.2505281865596771,
                  "y": 1.6209588050842285,
                  "z": -0.20023088157176971
                },
                "thumb1": {
                  "x": 0.22119079530239105,
                  "y": 1.5912290811538696,
                  "z": 0.09904585778713226
                },
                "index1": {
                  "x": 0.2656804621219635,
                  "y": 1.6661134958267212,
                  "z": 0.11320209503173828
                },
                "middle1": {
                  "x": 0.29407814145088196,
                  "y": 1.6762287616729736,
                  "z": 0.10985101014375687
                },
                "ring1": {
                  "x": 0.31925615668296814,
                  "y": 1.6653871536254883,
                  "z": 0.11819978058338165
                },
                "pinky1": {
                  "x": 0.3515753149986267,
                  "y": 1.633617639541626,
                  "z": 0.12923334538936615
                }
              },
              {
                "thumb0": {
                  "x": 0.17856250703334808,
                  "y": 1.5829706192016602,
                  "z": -0.10220091044902802
                },
                "index0": {
                  "x": 0.19621241092681885,
                  "y": 1.6562060117721558,
                  "z": -0.13714420795440674
                },
                "middle0": {
                  "x": 0.2181701511144638,
                  "y": 1.6651170253753662,
                  "z": -0.1550753265619278
                },
                "ring0": {
                  "x": 0.2334284484386444,
                  "y": 1.6537754535675049,
                  "z": -0.17643895745277405
                },
                "pinky0": {
                  "x": 0.2478228658437729,
                  "y": 1.6227302551269531,
                  "z": -0.20314331352710724
                },
                "thumb1": {
                  "x": 0.2197895050048828,
                  "y": 1.5926674604415894,
                  "z": 0.09963801503181458
                },
                "index1": {
                  "x": 0.2634062170982361,
                  "y": 1.667612910270691,
                  "z": 0.11413772404193878
                },
                "middle1": {
                  "x": 0.29099130630493164,
                  "y": 1.6777063608169556,
                  "z": 0.11056528240442276
                },
                "ring1": {
                  "x": 0.3154637813568115,
                  "y": 1.6673204898834229,
                  "z": 0.11885550618171692
                },
                "pinky1": {
                  "x": 0.34753501415252686,
                  "y": 1.6366065740585327,
                  "z": 0.13028199970722198
                }
              },
              {
                "thumb0": {
                  "x": 0.17888393998146057,
                  "y": 1.58282470703125,
                  "z": -0.10287508368492126
                },
                "index0": {
                  "x": 0.19587261974811554,
                  "y": 1.6562919616699219,
                  "z": -0.1379525065422058
                },
                "middle0": {
                  "x": 0.2175496220588684,
                  "y": 1.665260910987854,
                  "z": -0.15564504265785217
                },
                "ring0": {
                  "x": 0.23246490955352783,
                  "y": 1.6538541316986084,
                  "z": -0.1767914742231369
                },
                "pinky0": {
                  "x": 0.24688152968883514,
                  "y": 1.6227816343307495,
                  "z": -0.2030545175075531
                },
                "thumb1": {
                  "x": 0.21940287947654724,
                  "y": 1.5948609113693237,
                  "z": 0.1012234315276146
                },
                "index1": {
                  "x": 0.26340529322624207,
                  "y": 1.6694926023483276,
                  "z": 0.11657189577817917
                },
                "middle1": {
                  "x": 0.29081210494041443,
                  "y": 1.6792824268341064,
                  "z": 0.11301486194133759
                },
                "ring1": {
                  "x": 0.3150382339954376,
                  "y": 1.6689846515655518,
                  "z": 0.12205836176872253
                },
                "pinky1": {
                  "x": 0.3466742932796478,
                  "y": 1.6382431983947754,
                  "z": 0.13416793942451477
                }
              },
              {
                "thumb0": {
                  "x": 0.17968985438346863,
                  "y": 1.5825377702713013,
                  "z": -0.1030678078532219
                },
                "index0": {
                  "x": 0.19592466950416565,
                  "y": 1.6569267511367798,
                  "z": -0.13748279213905334
                },
                "middle0": {
                  "x": 0.2170128971338272,
                  "y": 1.6660635471343994,
                  "z": -0.15478917956352234
                },
                "ring0": {
                  "x": 0.2316512167453766,
                  "y": 1.6550471782684326,
                  "z": -0.17611625790596008
                },
                "pinky0": {
                  "x": 0.24595090746879578,
                  "y": 1.6247402429580688,
                  "z": -0.2027682363986969
                },
                "thumb1": {
                  "x": 0.22015458345413208,
                  "y": 1.595128059387207,
                  "z": 0.10236818343400955
                },
                "index1": {
                  "x": 0.26356568932533264,
                  "y": 1.669770359992981,
                  "z": 0.1184379979968071
                },
                "middle1": {
                  "x": 0.2908565104007721,
                  "y": 1.6794785261154175,
                  "z": 0.11502410471439362
                },
                "ring1": {
                  "x": 0.31503555178642273,
                  "y": 1.6692042350769043,
                  "z": 0.12405805289745331
                },
                "pinky1": {
                  "x": 0.346523255109787,
                  "y": 1.6385122537612915,
                  "z": 0.13611330091953278
                }
              }
            ],
            "words": "bar"
          },
          {
            "key": "75e76e72-acb2-350c-3a95-a86ab5255c66",
            "gesture": [
              {
                "thumb0": {
                  "x": 0.21425242722034454,
                  "y": 1.5321075916290283,
                  "z": -0.07941702753305435
                },
                "index0": {
                  "x": 0.19569168984889984,
                  "y": 1.6390937566757202,
                  "z": -0.09544448554515839
                },
                "middle0": {
                  "x": 0.2139996588230133,
                  "y": 1.653817057609558,
                  "z": -0.12266058474779129
                },
                "ring0": {
                  "x": 0.221080482006073,
                  "y": 1.644378662109375,
                  "z": -0.14744076132774353
                },
                "pinky0": {
                  "x": 0.22689375281333923,
                  "y": 1.619999647140503,
                  "z": -0.18190303444862366
                },
                "thumb1": {
                  "x": 0.24807138741016388,
                  "y": 1.5301114320755005,
                  "z": 0.02885347604751587
                },
                "index1": {
                  "x": 0.27042436599731445,
                  "y": 1.6341238021850586,
                  "z": 0.05405596271157265
                },
                "middle1": {
                  "x": 0.2934334874153137,
                  "y": 1.6446467638015747,
                  "z": 0.05834975093603134
                },
                "ring1": {
                  "x": 0.30959463119506836,
                  "y": 1.6417001485824585,
                  "z": 0.07523808628320694
                },
                "pinky1": {
                  "x": 0.334861695766449,
                  "y": 1.6174814701080322,
                  "z": 0.09150154888629913
                }
              },
              {
                "thumb0": {
                  "x": 0.21721792221069336,
                  "y": 1.5270874500274658,
                  "z": -0.07189091295003891
                },
                "index0": {
                  "x": 0.20303422212600708,
                  "y": 1.6326769590377808,
                  "z": -0.0884699746966362
                },
                "middle0": {
                  "x": 0.2200842797756195,
                  "y": 1.6486668586730957,
                  "z": -0.11620014160871506
                },
                "ring0": {
                  "x": 0.22679488360881805,
                  "y": 1.6396654844284058,
                  "z": -0.14154291152954102
                },
                "pinky0": {
                  "x": 0.2332783341407776,
                  "y": 1.6147407293319702,
                  "z": -0.17634734511375427
                },
                "thumb1": {
                  "x": 0.2354082316160202,
                  "y": 1.5345258712768555,
                  "z": 0.025577344000339508
                },
                "index1": {
                  "x": 0.25829967856407166,
                  "y": 1.6387841701507568,
                  "z": 0.04982936754822731
                },
                "middle1": {
                  "x": 0.2806115448474884,
                  "y": 1.65094792842865,
                  "z": 0.05368051677942276
                },
                "ring1": {
                  "x": 0.2980245351791382,
                  "y": 1.6485536098480225,
                  "z": 0.0712290033698082
                },
                "pinky1": {
                  "x": 0.32570406794548035,
                  "y": 1.62419593334198,
                  "z": 0.08572353422641754
                }
              },
              {
                "thumb0": {
                  "x": 0.21737810969352722,
                  "y": 1.524949073791504,
                  "z": -0.07009772211313248
                },
                "index0": {
                  "x": 0.20563726127147675,
                  "y": 1.6301600933074951,
                  "z": -0.08633334189653397
                },
                "middle0": {
                  "x": 0.22241143882274628,
                  "y": 1.6464414596557617,
                  "z": -0.11393793672323227
                },
                "ring0": {
                  "x": 0.22934041917324066,
                  "y": 1.6372069120407104,
                  "z": -0.13946300745010376
                },
                "pinky0": {
                  "x": 0.23584263026714325,
                  "y": 1.6119744777679443,
                  "z": -0.17416471242904663
                },
                "thumb1": {
                  "x": 0.23871904611587524,
                  "y": 1.530693531036377,
                  "z": 0.023291409015655518
                },
                "index1": {
                  "x": 0.262276291847229,
                  "y": 1.635258674621582,
                  "z": 0.04810638725757599
                },
                "middle1": {
                  "x": 0.2849476635456085,
                  "y": 1.6467794179916382,
                  "z": 0.051902301609516144
                },
                "ring1": {
                  "x": 0.3024265766143799,
                  "y": 1.6441090106964111,
                  "z": 0.07036059349775314
                },
                "pinky1": {
                  "x": 0.33059027791023254,
                  "y": 1.6189171075820923,
                  "z": 0.08533169329166412
                }
              },
              {
                "thumb0": {
                  "x": 0.20454321801662445,
                  "y": 1.5266807079315186,
                  "z": -0.06045200675725937
                },
                "index0": {
                  "x": 0.1997554451227188,
                  "y": 1.6309692859649658,
                  "z": -0.08341126143932343
                },
                "middle0": {
                  "x": 0.22045326232910156,
                  "y": 1.6445835828781128,
                  "z": -0.10931296646595001
                },
                "ring0": {
                  "x": 0.23024488985538483,
                  "y": 1.633315086364746,
                  "z": -0.13348093628883362
                },
                "pinky0": {
                  "x": 0.23990757763385773,
                  "y": 1.6052892208099365,
                  "z": -0.16544213891029358
                },
                "thumb1": {
                  "x": 0.24147891998291016,
                  "y": 1.5280624628067017,
                  "z": 0.023422352969646454
                },
                "index1": {
                  "x": 0.2674618065357208,
                  "y": 1.631432056427002,
                  "z": 0.04932766035199165
                },
                "middle1": {
                  "x": 0.29062265157699585,
                  "y": 1.641428828239441,
                  "z": 0.05310114473104477
                },
                "ring1": {
                  "x": 0.3086574077606201,
                  "y": 1.6374900341033936,
                  "z": 0.07065317779779434
                },
                "pinky1": {
                  "x": 0.33622416853904724,
                  "y": 1.6100817918777466,
                  "z": 0.08440044522285461
                }
              },
              {
                "thumb0": {
                  "x": 0.16091713309288025,
                  "y": 1.5340977907180786,
                  "z": 0.020548321306705475
                },
                "index0": {
                  "x": 0.2216288149356842,
                  "y": 1.616298794746399,
                  "z": -0.009979620575904846
                },
                "middle0": {
                  "x": 0.24973441660404205,
                  "y": 1.6013916730880737,
                  "z": -0.011041134595870972
                },
                "ring0": {
                  "x": 0.26440954208374023,
                  "y": 1.5826258659362793,
                  "z": -0.02242349088191986
                },
                "pinky0": {
                  "x": 0.26730677485466003,
                  "y": 1.5543361902236938,
                  "z": -0.03904303163290024
                },
                "thumb1": {
                  "x": 0.2700246274471283,
                  "y": 1.4348196983337402,
                  "z": -0.005732804536819458
                },
                "index1": {
                  "x": 0.3476678133010864,
                  "y": 1.4628140926361084,
                  "z": -0.010991491377353668
                },
                "middle1": {
                  "x": 0.35030049085617065,
                  "y": 1.437979817390442,
                  "z": -0.02095874398946762
                },
                "ring1": {
                  "x": 0.35476216673851013,
                  "y": 1.4160186052322388,
                  "z": -0.018671147525310516
                },
                "pinky1": {
                  "x": 0.36402949690818787,
                  "y": 1.3959859609603882,
                  "z": -0.006660394370555878
                }
              },
              {
                "thumb0": {
                  "x": 0.2598496377468109,
                  "y": 1.4418531656265259,
                  "z": 0.07332395017147064
                },
                "index0": {
                  "x": 0.30128583312034607,
                  "y": 1.4544001817703247,
                  "z": 0.06715510785579681
                },
                "middle0": {
                  "x": 0.29575106501579285,
                  "y": 1.4356045722961426,
                  "z": 0.06034333258867264
                },
                "ring0": {
                  "x": 0.29045212268829346,
                  "y": 1.4183177947998047,
                  "z": 0.057586997747421265
                },
                "pinky0": {
                  "x": 0.2829294502735138,
                  "y": 1.4064834117889404,
                  "z": 0.04817073047161102
                },
                "thumb1": {
                  "x": 0.24602627754211426,
                  "y": 1.3876627683639526,
                  "z": -0.015305891633033752
                },
                "index1": {
                  "x": 0.31032681465148926,
                  "y": 1.400546908378601,
                  "z": -0.03513762354850769
                },
                "middle1": {
                  "x": 0.3114927411079407,
                  "y": 1.378614902496338,
                  "z": -0.039795950055122375
                },
                "ring1": {
                  "x": 0.3090164363384247,
                  "y": 1.3575752973556519,
                  "z": -0.03426454961299896
                },
                "pinky1": {
                  "x": 0.3077206313610077,
                  "y": 1.338334083557129,
                  "z": -0.026509560644626617
                }
              },
              {
                "thumb0": {
                  "x": 0.1918877363204956,
                  "y": 1.688230276107788,
                  "z": -0.048841215670108795
                },
                "index0": {
                  "x": 0.20518629252910614,
                  "y": 1.7046692371368408,
                  "z": -0.05739521235227585
                },
                "middle0": {
                  "x": 0.20382146537303925,
                  "y": 1.688510537147522,
                  "z": -0.05654900521039963
                },
                "ring0": {
                  "x": 0.20173843204975128,
                  "y": 1.6730239391326904,
                  "z": -0.055164165794849396
                },
                "pinky0": {
                  "x": 0.20136456191539764,
                  "y": 1.6593554019927979,
                  "z": -0.057904697954654694
                },
                "thumb1": {
                  "x": 0.23454293608665466,
                  "y": 1.2187539339065552,
                  "z": -0.09027836471796036
                },
                "index1": {
                  "x": 0.24565020203590393,
                  "y": 1.2286428213119507,
                  "z": -0.08433820307254791
                },
                "middle1": {
                  "x": 0.2399057000875473,
                  "y": 1.2189600467681885,
                  "z": -0.07272550463676453
                },
                "ring1": {
                  "x": 0.23434458673000336,
                  "y": 1.2081563472747803,
                  "z": -0.06227149814367294
                },
                "pinky1": {
                  "x": 0.23077285289764404,
                  "y": 1.1980286836624146,
                  "z": -0.055303022265434265
                }
              },
              {
                "thumb0": {
                  "x": 0.21032120287418365,
                  "y": 1.408327579498291,
                  "z": -0.10687584429979324
                },
                "index0": {
                  "x": 0.2623543441295624,
                  "y": 1.4005436897277832,
                  "z": -0.15516811609268188
                },
                "middle0": {
                  "x": 0.2601167857646942,
                  "y": 1.3783303499221802,
                  "z": -0.15951408445835114
                },
                "ring0": {
                  "x": 0.2572588324546814,
                  "y": 1.3584681749343872,
                  "z": -0.15329068899154663
                },
                "pinky0": {
                  "x": 0.2521968185901642,
                  "y": 1.340608835220337,
                  "z": -0.13193738460540771
                },
                "thumb1": {
                  "x": 0.2073955237865448,
                  "y": 1.2984453439712524,
                  "z": -0.10970822721719742
                },
                "index1": {
                  "x": 0.22680307924747467,
                  "y": 1.2977988719940186,
                  "z": -0.11397068202495575
                },
                "middle1": {
                  "x": 0.21595478057861328,
                  "y": 1.2876546382904053,
                  "z": -0.09965839236974716
                },
                "ring1": {
                  "x": 0.21319027245044708,
                  "y": 1.2781027555465698,
                  "z": -0.08435873687267303
                },
                "pinky1": {
                  "x": 0.21171404421329498,
                  "y": 1.2679237127304077,
                  "z": -0.07752405852079391
                }
              },
              {
                "thumb0": {
                  "x": 0.19807925820350647,
                  "y": 1.5003509521484375,
                  "z": -0.10354814678430557
                },
                "index0": {
                  "x": 0.24577701091766357,
                  "y": 1.5188626050949097,
                  "z": -0.11608278751373291
                },
                "middle0": {
                  "x": 0.2451731115579605,
                  "y": 1.4977021217346191,
                  "z": -0.12084663659334183
                },
                "ring0": {
                  "x": 0.22797761857509613,
                  "y": 1.47922682762146,
                  "z": -0.1295844465494156
                },
                "pinky0": {
                  "x": 0.22165897488594055,
                  "y": 1.466970443725586,
                  "z": -0.13290749490261078
                },
                "thumb1": {
                  "x": 0.1767677515745163,
                  "y": 1.487797737121582,
                  "z": -0.16656063497066498
                },
                "index1": {
                  "x": 0.18239335715770721,
                  "y": 1.4910682439804077,
                  "z": -0.1772955358028412
                },
                "middle1": {
                  "x": 0.183645099401474,
                  "y": 1.477057695388794,
                  "z": -0.16870081424713135
                },
                "ring1": {
                  "x": 0.19124861061573029,
                  "y": 1.4630521535873413,
                  "z": -0.16245996952056885
                },
                "pinky1": {
                  "x": 0.19862759113311768,
                  "y": 1.4484094381332397,
                  "z": -0.1661790907382965
                }
              },
              {
                "thumb0": {
                  "x": 0.1369490772485733,
                  "y": 1.6776612997055054,
                  "z": -0.16398468613624573
                },
                "index0": {
                  "x": 0.13977062702178955,
                  "y": 1.6925548315048218,
                  "z": -0.16965988278388977
                },
                "middle0": {
                  "x": 0.1408393234014511,
                  "y": 1.6794688701629639,
                  "z": -0.17342974245548248
                },
                "ring0": {
                  "x": 0.1418301910161972,
                  "y": 1.665217638015747,
                  "z": -0.17625220119953156
                },
                "pinky0": {
                  "x": 0.14578308165073395,
                  "y": 1.6533989906311035,
                  "z": -0.18194645643234253
                },
                "thumb1": {
                  "x": 0.18745514750480652,
                  "y": 1.3432942628860474,
                  "z": -0.2348327338695526
                },
                "index1": {
                  "x": 0.19069871306419373,
                  "y": 1.3513895273208618,
                  "z": -0.22971433401107788
                },
                "middle1": {
                  "x": 0.1936444491147995,
                  "y": 1.3415892124176025,
                  "z": -0.2228093147277832
                },
                "ring1": {
                  "x": 0.19949571788311005,
                  "y": 1.3293057680130005,
                  "z": -0.21477988362312317
                },
                "pinky1": {
                  "x": 0.20252551138401031,
                  "y": 1.3184142112731934,
                  "z": -0.2071484625339508
                }
              },
              {
                "thumb0": {
                  "x": 0.15750937163829803,
                  "y": 1.3787771463394165,
                  "z": -0.22955936193466187
                },
                "index0": {
                  "x": 0.16349345445632935,
                  "y": 1.3744617700576782,
                  "z": -0.23625591397285461
                },
                "middle0": {
                  "x": 0.16403813660144806,
                  "y": 1.3634601831436157,
                  "z": -0.22561976313591003
                },
                "ring0": {
                  "x": 0.1692984253168106,
                  "y": 1.3515502214431763,
                  "z": -0.21487686038017273
                },
                "pinky0": {
                  "x": 0.1741754412651062,
                  "y": 1.3391374349594116,
                  "z": -0.21050019562244415
                },
                "thumb1": {
                  "x": 0.18236830830574036,
                  "y": 1.3319265842437744,
                  "z": -0.2331998646259308
                },
                "index1": {
                  "x": 0.18495790660381317,
                  "y": 1.3379474878311157,
                  "z": -0.22732380032539368
                },
                "middle1": {
                  "x": 0.18702448904514313,
                  "y": 1.3282417058944702,
                  "z": -0.22101609408855438
                },
                "ring1": {
                  "x": 0.19356119632720947,
                  "y": 1.316572666168213,
                  "z": -0.2113310545682907
                },
                "pinky1": {
                  "x": 0.19660155475139618,
                  "y": 1.3055120706558228,
                  "z": -0.20503471791744232
                }
              },
              {
                "thumb0": {
                  "x": 0.12911969423294067,
                  "y": 1.6001747846603394,
                  "z": -0.1844499409198761
                },
                "index0": {
                  "x": 0.17372994124889374,
                  "y": 1.6238858699798584,
                  "z": -0.21503712236881256
                },
                "middle0": {
                  "x": 0.18628688156604767,
                  "y": 1.5995628833770752,
                  "z": -0.21682745218276978
                },
                "ring0": {
                  "x": 0.1915799379348755,
                  "y": 1.5760871171951294,
                  "z": -0.22021540999412537
                },
                "pinky0": {
                  "x": 0.17731720209121704,
                  "y": 1.549546480178833,
                  "z": -0.22700391709804535
                },
                "thumb1": {
                  "x": 0.13519206643104553,
                  "y": 1.449588656425476,
                  "z": -0.21568205952644348
                },
                "index1": {
                  "x": 0.15485712885856628,
                  "y": 1.442230463027954,
                  "z": -0.27608340978622437
                },
                "middle1": {
                  "x": 0.14399324357509613,
                  "y": 1.4154210090637207,
                  "z": -0.271445631980896
                },
                "ring1": {
                  "x": 0.1492624431848526,
                  "y": 1.392477035522461,
                  "z": -0.2702972888946533
                },
                "pinky1": {
                  "x": 0.17161515355110168,
                  "y": 1.3644351959228516,
                  "z": -0.26042473316192627
                }
              },
              {
                "thumb0": {
                  "x": 0.15159423649311066,
                  "y": 1.5676562786102295,
                  "z": -0.1471012830734253
                },
                "index0": {
                  "x": 0.1871987283229828,
                  "y": 1.6082395315170288,
                  "z": -0.18541601300239563
                },
                "middle0": {
                  "x": 0.21106426417827606,
                  "y": 1.596442699432373,
                  "z": -0.19904828071594238
                },
                "ring0": {
                  "x": 0.2214926779270172,
                  "y": 1.5764985084533691,
                  "z": -0.21120423078536987
                },
                "pinky0": {
                  "x": 0.21703623235225677,
                  "y": 1.5476219654083252,
                  "z": -0.22013109922409058
                },
                "thumb1": {
                  "x": 0.15349288284778595,
                  "y": 1.4818651676177979,
                  "z": -0.17090103030204773
                },
                "index1": {
                  "x": 0.22076886892318726,
                  "y": 1.4990359544754028,
                  "z": -0.2358236461877823
                },
                "middle1": {
                  "x": 0.23362122476100922,
                  "y": 1.4742528200149536,
                  "z": -0.2523554563522339
                },
                "ring1": {
                  "x": 0.2472037523984909,
                  "y": 1.449820876121521,
                  "z": -0.2491975575685501
                },
                "pinky1": {
                  "x": 0.24370871484279633,
                  "y": 1.4098615646362305,
                  "z": -0.22695808112621307
                }
              },
              {
                "thumb0": {
                  "x": 0.16983331739902496,
                  "y": 1.5537396669387817,
                  "z": -0.11968346685171127
                },
                "index0": {
                  "x": 0.17353026568889618,
                  "y": 1.6184911727905273,
                  "z": -0.15312910079956055
                },
                "middle0": {
                  "x": 0.1847887486219406,
                  "y": 1.6282083988189697,
                  "z": -0.1806344836950302
                },
                "ring0": {
                  "x": 0.19062504172325134,
                  "y": 1.616204857826233,
                  "z": -0.20575587451457977
                },
                "pinky0": {
                  "x": 0.19135932624340057,
                  "y": 1.5842680931091309,
                  "z": -0.2315739244222641
                },
                "thumb1": {
                  "x": 0.15607453882694244,
                  "y": 1.4687044620513916,
                  "z": -0.1383131742477417
                },
                "index1": {
                  "x": 0.2387346774339676,
                  "y": 1.520217776298523,
                  "z": -0.17430073022842407
                },
                "middle1": {
                  "x": 0.2640077769756317,
                  "y": 1.5036875009536743,
                  "z": -0.18675976991653442
                },
                "ring1": {
                  "x": 0.27938705682754517,
                  "y": 1.4789572954177856,
                  "z": -0.1808166801929474
                },
                "pinky1": {
                  "x": 0.288277268409729,
                  "y": 1.4415597915649414,
                  "z": -0.16064311563968658
                }
              },
              {
                "thumb0": {
                  "x": 0.16594795882701874,
                  "y": 1.5421514511108398,
                  "z": -0.10924434661865234
                },
                "index0": {
                  "x": 0.16944478452205658,
                  "y": 1.6103166341781616,
                  "z": -0.14157706499099731
                },
                "middle0": {
                  "x": 0.17585422098636627,
                  "y": 1.6226115226745605,
                  "z": -0.16669481992721558
                },
                "ring0": {
                  "x": 0.17930026352405548,
                  "y": 1.6117327213287354,
                  "z": -0.19103801250457764
                },
                "pinky0": {
                  "x": 0.17763493955135345,
                  "y": 1.5851761102676392,
                  "z": -0.22064486145973206
                },
                "thumb1": {
                  "x": 0.15978024899959564,
                  "y": 1.4696009159088135,
                  "z": -0.10522066056728363
                },
                "index1": {
                  "x": 0.23523858189582825,
                  "y": 1.5246814489364624,
                  "z": -0.13169242441654205
                },
                "middle1": {
                  "x": 0.2628626525402069,
                  "y": 1.5109233856201172,
                  "z": -0.14025729894638062
                },
                "ring1": {
                  "x": 0.27859196066856384,
                  "y": 1.4862006902694702,
                  "z": -0.13409528136253357
                },
                "pinky1": {
                  "x": 0.2866906523704529,
                  "y": 1.4510889053344727,
                  "z": -0.11464624851942062
                }
              },
              {
                "thumb0": {
                  "x": 0.18010058999061584,
                  "y": 1.5350013971328735,
                  "z": -0.11344768106937408
                },
                "index0": {
                  "x": 0.17698079347610474,
                  "y": 1.6128417253494263,
                  "z": -0.141925647854805
                },
                "middle0": {
                  "x": 0.18061791360378265,
                  "y": 1.6277244091033936,
                  "z": -0.17080941796302795
                },
                "ring0": {
                  "x": 0.1846841275691986,
                  "y": 1.6130083799362183,
                  "z": -0.19569475948810577
                },
                "pinky0": {
                  "x": 0.1808326542377472,
                  "y": 1.5862923860549927,
                  "z": -0.22665762901306152
                },
                "thumb1": {
                  "x": 0.2153356522321701,
                  "y": 1.482421636581421,
                  "z": -0.05427116900682449
                },
                "index1": {
                  "x": 0.2631555199623108,
                  "y": 1.563629150390625,
                  "z": -0.03530582785606384
                },
                "middle1": {
                  "x": 0.29337766766548157,
                  "y": 1.5648459196090698,
                  "z": -0.03010140359401703
                },
                "ring1": {
                  "x": 0.3105458915233612,
                  "y": 1.552561640739441,
                  "z": -0.013718128204345703
                },
                "pinky1": {
                  "x": 0.3229704797267914,
                  "y": 1.5260411500930786,
                  "z": 0.012146420776844025
                }
              },
              {
                "thumb0": {
                  "x": 0.198073148727417,
                  "y": 1.5216618776321411,
                  "z": -0.12051451206207275
                },
                "index0": {
                  "x": 0.18378517031669617,
                  "y": 1.6086416244506836,
                  "z": -0.14486610889434814
                },
                "middle0": {
                  "x": 0.18204167485237122,
                  "y": 1.626497745513916,
                  "z": -0.1753539890050888
                },
                "ring0": {
                  "x": 0.17779086530208588,
                  "y": 1.616542935371399,
                  "z": -0.20279918611049652
                },
                "pinky0": {
                  "x": 0.17753517627716064,
                  "y": 1.5871095657348633,
                  "z": -0.23591305315494537
                },
                "thumb1": {
                  "x": 0.25438055396080017,
                  "y": 1.5206449031829834,
                  "z": 0.029823817312717438
                },
                "index1": {
                  "x": 0.27334120869636536,
                  "y": 1.6089121103286743,
                  "z": 0.0706871747970581
                },
                "middle1": {
                  "x": 0.2949484884738922,
                  "y": 1.6201231479644775,
                  "z": 0.08968149870634079
                },
                "ring1": {
                  "x": 0.30476176738739014,
                  "y": 1.6120845079421997,
                  "z": 0.11870535463094711
                },
                "pinky1": {
                  "x": 0.3190889060497284,
                  "y": 1.5828996896743774,
                  "z": 0.14854896068572998
                }
              },
              {
                "thumb0": {
                  "x": 0.21558243036270142,
                  "y": 1.5181043148040771,
                  "z": -0.1169872134923935
                },
                "index0": {
                  "x": 0.19898009300231934,
                  "y": 1.6082274913787842,
                  "z": -0.14241287112236023
                },
                "middle0": {
                  "x": 0.19912199676036835,
                  "y": 1.6252336502075195,
                  "z": -0.17384687066078186
                },
                "ring0": {
                  "x": 0.1926090121269226,
                  "y": 1.6165010929107666,
                  "z": -0.202317476272583
                },
                "pinky0": {
                  "x": 0.19094207882881165,
                  "y": 1.5876846313476562,
                  "z": -0.23620986938476562
                },
                "thumb1": {
                  "x": 0.2528779208660126,
                  "y": 1.5356618165969849,
                  "z": 0.0640333741903305
                },
                "index1": {
                  "x": 0.2592184841632843,
                  "y": 1.6261531114578247,
                  "z": 0.10836049169301987
                },
                "middle1": {
                  "x": 0.27719488739967346,
                  "y": 1.6396002769470215,
                  "z": 0.1301240622997284
                },
                "ring1": {
                  "x": 0.2831992208957672,
                  "y": 1.6314154863357544,
                  "z": 0.16080518066883087
                },
                "pinky1": {
                  "x": 0.29661825299263,
                  "y": 1.6031602621078491,
                  "z": 0.19229522347450256
                }
              },
              {
                "thumb0": {
                  "x": 0.23013024032115936,
                  "y": 1.5220075845718384,
                  "z": -0.10313693434000015
                },
                "index0": {
                  "x": 0.21315346658229828,
                  "y": 1.6142857074737549,
                  "z": -0.127996027469635
                },
                "middle0": {
                  "x": 0.21428720653057098,
                  "y": 1.6312217712402344,
                  "z": -0.15899012982845306
                },
                "ring0": {
                  "x": 0.20777152478694916,
                  "y": 1.6232513189315796,
                  "z": -0.18772074580192566
                },
                "pinky0": {
                  "x": 0.20552843809127808,
                  "y": 1.5956007242202759,
                  "z": -0.22176125645637512
                },
                "thumb1": {
                  "x": 0.25879359245300293,
                  "y": 1.5387895107269287,
                  "z": 0.06744123250246048
                },
                "index1": {
                  "x": 0.2575153112411499,
                  "y": 1.634890079498291,
                  "z": 0.11444517970085144
                },
                "middle1": {
                  "x": 0.27377691864967346,
                  "y": 1.6485775709152222,
                  "z": 0.13561266660690308
                },
                "ring1": {
                  "x": 0.28123411536216736,
                  "y": 1.6399110555648804,
                  "z": 0.16462324559688568
                },
                "pinky1": {
                  "x": 0.2939039468765259,
                  "y": 1.6130692958831787,
                  "z": 0.1970539689064026
                }
              },
              {
                "thumb0": {
                  "x": 0.23730698227882385,
                  "y": 1.517148733139038,
                  "z": -0.09204986691474915
                },
                "index0": {
                  "x": 0.22080253064632416,
                  "y": 1.6124260425567627,
                  "z": -0.11858475208282471
                },
                "middle0": {
                  "x": 0.2223236858844757,
                  "y": 1.6288037300109863,
                  "z": -0.14969900250434875
                },
                "ring0": {
                  "x": 0.2169610857963562,
                  "y": 1.6202173233032227,
                  "z": -0.17830678820610046
                },
                "pinky0": {
                  "x": 0.21308697760105133,
                  "y": 1.5930428504943848,
                  "z": -0.21188035607337952
                },
                "thumb1": {
                  "x": 0.26050084829330444,
                  "y": 1.5359605550765991,
                  "z": 0.06557653844356537
                },
                "index1": {
                  "x": 0.2546992301940918,
                  "y": 1.635715365409851,
                  "z": 0.10911568999290466
                },
                "middle1": {
                  "x": 0.26970601081848145,
                  "y": 1.6512119770050049,
                  "z": 0.13073153793811798
                },
                "ring1": {
                  "x": 0.2771399915218353,
                  "y": 1.6438336372375488,
                  "z": 0.16043499112129211
                },
                "pinky1": {
                  "x": 0.29012739658355713,
                  "y": 1.6182794570922852,
                  "z": 0.1941351592540741
                }
              },
              {
                "thumb0": {
                  "x": 0.2398502379655838,
                  "y": 1.5161912441253662,
                  "z": -0.08812152594327927
                },
                "index0": {
                  "x": 0.22600007057189941,
                  "y": 1.6124597787857056,
                  "z": -0.11655110865831375
                },
                "middle0": {
                  "x": 0.2299250066280365,
                  "y": 1.6280369758605957,
                  "z": -0.14769670367240906
                },
                "ring0": {
                  "x": 0.22600549459457397,
                  "y": 1.6190789937973022,
                  "z": -0.17611047625541687
                },
                "pinky0": {
                  "x": 0.22276975214481354,
                  "y": 1.5918561220169067,
                  "z": -0.20923936367034912
                },
                "thumb1": {
                  "x": 0.25946077704429626,
                  "y": 1.5321155786514282,
                  "z": 0.06809169054031372
                },
                "index1": {
                  "x": 0.254591166973114,
                  "y": 1.6342079639434814,
                  "z": 0.1065770611166954
                },
                "middle1": {
                  "x": 0.2706998586654663,
                  "y": 1.6512798070907593,
                  "z": 0.12719812989234924
                },
                "ring1": {
                  "x": 0.2797187268733978,
                  "y": 1.6453777551651,
                  "z": 0.15656678378582
                },
                "pinky1": {
                  "x": 0.29445362091064453,
                  "y": 1.6216570138931274,
                  "z": 0.1912824511528015
                }
              },
              {
                "thumb0": {
                  "x": 0.24221183359622955,
                  "y": 1.5142810344696045,
                  "z": -0.08224812895059586
                },
                "index0": {
                  "x": 0.22985973954200745,
                  "y": 1.611765742301941,
                  "z": -0.11121976375579834
                },
                "middle0": {
                  "x": 0.23612560331821442,
                  "y": 1.6267812252044678,
                  "z": -0.14212104678153992
                },
                "ring0": {
                  "x": 0.23432707786560059,
                  "y": 1.617596983909607,
                  "z": -0.17017394304275513
                },
                "pinky0": {
                  "x": 0.23230473697185516,
                  "y": 1.5905214548110962,
                  "z": -0.2033623456954956
                },
                "thumb1": {
                  "x": 0.2639767527580261,
                  "y": 1.5285859107971191,
                  "z": 0.07008367776870728
                },
                "index1": {
                  "x": 0.2637139558792114,
                  "y": 1.6310820579528809,
                  "z": 0.10711946338415146
                },
                "middle1": {
                  "x": 0.28153741359710693,
                  "y": 1.6480382680892944,
                  "z": 0.12688225507736206
                },
                "ring1": {
                  "x": 0.29145699739456177,
                  "y": 1.642224669456482,
                  "z": 0.15572232007980347
                },
                "pinky1": {
                  "x": 0.3075859248638153,
                  "y": 1.618469476699829,
                  "z": 0.18978933990001678
                }
              }
            ],
            "words": "column"
          },
          {
            "key": "d4fbd561-d1f4-4192-7546-242ffb03dd59",
            "gesture": [
              {
                "thumb0": {
                  "x": 0.24127179384231567,
                  "y": 1.5146464109420776,
                  "z": -0.08984392881393433
                },
                "index0": {
                  "x": 0.23809726536273956,
                  "y": 1.6115896701812744,
                  "z": -0.11900598555803299
                },
                "middle0": {
                  "x": 0.260656476020813,
                  "y": 1.6162998676300049,
                  "z": -0.14066675305366516
                },
                "ring0": {
                  "x": 0.2728617489337921,
                  "y": 1.5995969772338867,
                  "z": -0.16165480017662048
                },
                "pinky0": {
                  "x": 0.27737218141555786,
                  "y": 1.570778727531433,
                  "z": -0.1901485174894333
                },
                "thumb1": {
                  "x": 0.2412792444229126,
                  "y": 1.53897225856781,
                  "z": 0.04007148742675781
                },
                "index1": {
                  "x": 0.2668236792087555,
                  "y": 1.625744342803955,
                  "z": 0.06557247042655945
                },
                "middle1": {
                  "x": 0.2937268018722534,
                  "y": 1.626997709274292,
                  "z": 0.05444532632827759
                },
                "ring1": {
                  "x": 0.3179115951061249,
                  "y": 1.6177423000335693,
                  "z": 0.05961611866950989
                },
                "pinky1": {
                  "x": 0.34306761622428894,
                  "y": 1.5939315557479858,
                  "z": 0.0740034431219101
                }
              },
              {
                "thumb0": {
                  "x": 0.27209147810935974,
                  "y": 1.4750386476516724,
                  "z": -0.10044881701469421
                },
                "index0": {
                  "x": 0.2856839597225189,
                  "y": 1.5668085813522339,
                  "z": -0.1413050889968872
                },
                "middle0": {
                  "x": 0.3082911968231201,
                  "y": 1.562843918800354,
                  "z": -0.16030007600784302
                },
                "ring0": {
                  "x": 0.3175194561481476,
                  "y": 1.5424851179122925,
                  "z": -0.1766824573278427
                },
                "pinky0": {
                  "x": 0.3175159990787506,
                  "y": 1.5129109621047974,
                  "z": -0.19599032402038574
                },
                "thumb1": {
                  "x": 0.2389586716890335,
                  "y": 1.530000925064087,
                  "z": 0.027553774416446686
                },
                "index1": {
                  "x": 0.26957374811172485,
                  "y": 1.6054747104644775,
                  "z": 0.0454784519970417
                },
                "middle1": {
                  "x": 0.2934974730014801,
                  "y": 1.6007250547409058,
                  "z": 0.03557808697223663
                },
                "ring1": {
                  "x": 0.3169991970062256,
                  "y": 1.592145323753357,
                  "z": 0.03865636885166168
                },
                "pinky1": {
                  "x": 0.3413376212120056,
                  "y": 1.5739041566848755,
                  "z": 0.05408044531941414
                }
              },
              {
                "thumb0": {
                  "x": 0.3073729872703552,
                  "y": 1.4066892862319946,
                  "z": -0.0762656182050705
                },
                "index0": {
                  "x": 0.36461299657821655,
                  "y": 1.4574389457702637,
                  "z": -0.12109354138374329
                },
                "middle0": {
                  "x": 0.3819460868835449,
                  "y": 1.4370486736297607,
                  "z": -0.1333962380886078
                },
                "ring0": {
                  "x": 0.38139790296554565,
                  "y": 1.411940097808838,
                  "z": -0.144995778799057
                },
                "pinky0": {
                  "x": 0.3643340468406677,
                  "y": 1.3853758573532104,
                  "z": -0.15619882941246033
                },
                "thumb1": {
                  "x": 0.23743973672389984,
                  "y": 1.514279842376709,
                  "z": -0.022734634578227997
                },
                "index1": {
                  "x": 0.2951614260673523,
                  "y": 1.5459372997283936,
                  "z": -0.030319705605506897
                },
                "middle1": {
                  "x": 0.3016623854637146,
                  "y": 1.5281128883361816,
                  "z": -0.03897038847208023
                },
                "ring1": {
                  "x": 0.3158968687057495,
                  "y": 1.5141041278839111,
                  "z": -0.03465411067008972
                },
                "pinky1": {
                  "x": 0.3304988741874695,
                  "y": 1.4959909915924072,
                  "z": -0.023688413202762604
                }
              },
              {
                "thumb0": {
                  "x": 0.2978598475456238,
                  "y": 1.3529106378555298,
                  "z": -0.041368208825588226
                },
                "index0": {
                  "x": 0.3683369755744934,
                  "y": 1.3652575016021729,
                  "z": -0.05446804314851761
                },
                "middle0": {
                  "x": 0.37987759709358215,
                  "y": 1.340444803237915,
                  "z": -0.05881612002849579
                },
                "ring0": {
                  "x": 0.3686940670013428,
                  "y": 1.314926266670227,
                  "z": -0.06466227024793625
                },
                "pinky0": {
                  "x": 0.35090264678001404,
                  "y": 1.2968374490737915,
                  "z": -0.07715429365634918
                },
                "thumb1": {
                  "x": 0.2219153791666031,
                  "y": 1.463369369506836,
                  "z": -0.09338439255952835
                },
                "index1": {
                  "x": 0.2639352083206177,
                  "y": 1.469213843345642,
                  "z": -0.11750612407922745
                },
                "middle1": {
                  "x": 0.26027706265449524,
                  "y": 1.448833703994751,
                  "z": -0.11527077853679657
                },
                "ring1": {
                  "x": 0.26364320516586304,
                  "y": 1.431638240814209,
                  "z": -0.11352893710136414
                },
                "pinky1": {
                  "x": 0.26976755261421204,
                  "y": 1.417478084564209,
                  "z": -0.1045769676566124
                }
              },
              {
                "thumb0": {
                  "x": 0.283890038728714,
                  "y": 1.353898525238037,
                  "z": -0.05103202164173126
                },
                "index0": {
                  "x": 0.3519417941570282,
                  "y": 1.3645743131637573,
                  "z": -0.06020551919937134
                },
                "middle0": {
                  "x": 0.36796894669532776,
                  "y": 1.3417210578918457,
                  "z": -0.06489359587430954
                },
                "ring0": {
                  "x": 0.35547593235969543,
                  "y": 1.3158938884735107,
                  "z": -0.07102320343255997
                },
                "pinky0": {
                  "x": 0.33750733733177185,
                  "y": 1.2974694967269897,
                  "z": -0.08410393446683884
                },
                "thumb1": {
                  "x": 0.2514134645462036,
                  "y": 1.4106314182281494,
                  "z": -0.12312233448028564
                },
                "index1": {
                  "x": 0.26447850465774536,
                  "y": 1.4189356565475464,
                  "z": -0.1222863644361496
                },
                "middle1": {
                  "x": 0.2666264474391937,
                  "y": 1.4056875705718994,
                  "z": -0.110708087682724
                },
                "ring1": {
                  "x": 0.2680630683898926,
                  "y": 1.3919456005096436,
                  "z": -0.10000432282686234
                },
                "pinky1": {
                  "x": 0.268848717212677,
                  "y": 1.3804118633270264,
                  "z": -0.09237491339445114
                }
              },
              {
                "thumb0": {
                  "x": 0.30206215381622314,
                  "y": 1.3740628957748413,
                  "z": -0.04909329116344452
                },
                "index0": {
                  "x": 0.3144719898700714,
                  "y": 1.3428151607513428,
                  "z": -0.060090966522693634
                },
                "middle0": {
                  "x": 0.3021274209022522,
                  "y": 1.3291494846343994,
                  "z": -0.06713981181383133
                },
                "ring0": {
                  "x": 0.3036250174045563,
                  "y": 1.3197587728500366,
                  "z": -0.07832568883895874
                },
                "pinky0": {
                  "x": 0.30545440316200256,
                  "y": 1.31049644947052,
                  "z": -0.08910556137561798
                },
                "thumb1": {
                  "x": 0.257265567779541,
                  "y": 1.4884357452392578,
                  "z": -0.08290479332208633
                },
                "index1": {
                  "x": 0.266815185546875,
                  "y": 1.4979979991912842,
                  "z": -0.07942061126232147
                },
                "middle1": {
                  "x": 0.2662864327430725,
                  "y": 1.483502745628357,
                  "z": -0.07084977626800537
                },
                "ring1": {
                  "x": 0.26800736784935,
                  "y": 1.4693917036056519,
                  "z": -0.056845150887966156
                },
                "pinky1": {
                  "x": 0.2646711468696594,
                  "y": 1.4572898149490356,
                  "z": -0.051280610263347626
                }
              },
              {
                "thumb0": {
                  "x": 0.31085005402565,
                  "y": 1.3427854776382446,
                  "z": -0.015769697725772858
                },
                "index0": {
                  "x": 0.32617858052253723,
                  "y": 1.337467074394226,
                  "z": -0.02330658584833145
                },
                "middle0": {
                  "x": 0.324076771736145,
                  "y": 1.3229451179504395,
                  "z": -0.027238979935646057
                },
                "ring0": {
                  "x": 0.31208673119544983,
                  "y": 1.308570146560669,
                  "z": -0.029759682714939117
                },
                "pinky0": {
                  "x": 0.30694472789764404,
                  "y": 1.2960857152938843,
                  "z": -0.03284226357936859
                },
                "thumb1": {
                  "x": 0.225162073969841,
                  "y": 1.6736654043197632,
                  "z": 0.03077724575996399
                },
                "index1": {
                  "x": 0.2379225492477417,
                  "y": 1.6784485578536987,
                  "z": 0.028869323432445526
                },
                "middle1": {
                  "x": 0.23743940889835358,
                  "y": 1.663495659828186,
                  "z": 0.02381809800863266
                },
                "ring1": {
                  "x": 0.2422938048839569,
                  "y": 1.6505948305130005,
                  "z": 0.017383314669132233
                },
                "pinky1": {
                  "x": 0.25891584157943726,
                  "y": 1.642475962638855,
                  "z": 0.014142699539661407
                }
              },
              {
                "thumb0": {
                  "x": 0.30149826407432556,
                  "y": 1.376667857170105,
                  "z": 0.019804149866104126
                },
                "index0": {
                  "x": 0.3625408709049225,
                  "y": 1.3759384155273438,
                  "z": 0.016806907951831818
                },
                "middle0": {
                  "x": 0.36697453260421753,
                  "y": 1.351033091545105,
                  "z": 0.02133244276046753
                },
                "ring0": {
                  "x": 0.34784823656082153,
                  "y": 1.3311481475830078,
                  "z": 0.0179324671626091
                },
                "pinky0": {
                  "x": 0.33223602175712585,
                  "y": 1.316009521484375,
                  "z": 0.00801866501569748
                },
                "thumb1": {
                  "x": 0.22669734060764313,
                  "y": 1.5692881345748901,
                  "z": 0.03444517403841019
                },
                "index1": {
                  "x": 0.27668389678001404,
                  "y": 1.584837794303894,
                  "z": -0.011607624590396881
                },
                "middle1": {
                  "x": 0.2851935029029846,
                  "y": 1.5629246234893799,
                  "z": -0.02455686777830124
                },
                "ring1": {
                  "x": 0.2932509779930115,
                  "y": 1.5406007766723633,
                  "z": -0.023337751626968384
                },
                "pinky1": {
                  "x": 0.3042314946651459,
                  "y": 1.5170695781707764,
                  "z": -0.006695263087749481
                }
              },
              {
                "thumb0": {
                  "x": 0.300911009311676,
                  "y": 1.381548285484314,
                  "z": 0.07949765026569366
                },
                "index0": {
                  "x": 0.3638504445552826,
                  "y": 1.3883150815963745,
                  "z": 0.08202037215232849
                },
                "middle0": {
                  "x": 0.361303448677063,
                  "y": 1.3622143268585205,
                  "z": 0.08450479805469513
                },
                "ring0": {
                  "x": 0.34740155935287476,
                  "y": 1.3417659997940063,
                  "z": 0.07508532702922821
                },
                "pinky0": {
                  "x": 0.3421684205532074,
                  "y": 1.3238184452056885,
                  "z": 0.06048789620399475
                },
                "thumb1": {
                  "x": 0.25722357630729675,
                  "y": 1.4270720481872559,
                  "z": -0.0021963343024253845
                },
                "index1": {
                  "x": 0.29494696855545044,
                  "y": 1.4312981367111206,
                  "z": -0.022647134959697723
                },
                "middle1": {
                  "x": 0.2938586473464966,
                  "y": 1.4117310047149658,
                  "z": -0.017205148935317993
                },
                "ring1": {
                  "x": 0.29361188411712646,
                  "y": 1.393635869026184,
                  "z": -0.005271710455417633
                },
                "pinky1": {
                  "x": 0.30072054266929626,
                  "y": 1.376749873161316,
                  "z": -0.001912333071231842
                }
              },
              {
                "thumb0": {
                  "x": 0.30539974570274353,
                  "y": 1.3896335363388062,
                  "z": 0.0954831913113594
                },
                "index0": {
                  "x": 0.3273164629936218,
                  "y": 1.3963696956634521,
                  "z": 0.09848381578922272
                },
                "middle0": {
                  "x": 0.3264103829860687,
                  "y": 1.3797732591629028,
                  "z": 0.0992189273238182
                },
                "ring0": {
                  "x": 0.32063028216362,
                  "y": 1.3632622957229614,
                  "z": 0.09183993935585022
                },
                "pinky0": {
                  "x": 0.32576480507850647,
                  "y": 1.3463175296783447,
                  "z": 0.08789608627557755
                },
                "thumb1": {
                  "x": 0.28536900877952576,
                  "y": 1.4973430633544922,
                  "z": 0.044455014169216156
                },
                "index1": {
                  "x": 0.2987748682498932,
                  "y": 1.508551001548767,
                  "z": 0.04424673691391945
                },
                "middle1": {
                  "x": 0.2997908890247345,
                  "y": 1.4928747415542603,
                  "z": 0.05101776495575905
                },
                "ring1": {
                  "x": 0.3007016181945801,
                  "y": 1.4773532152175903,
                  "z": 0.05958302691578865
                },
                "pinky1": {
                  "x": 0.3034327030181885,
                  "y": 1.4618606567382812,
                  "z": 0.062039025127887726
                }
              },
              {
                "thumb0": {
                  "x": 0.309826135635376,
                  "y": 1.3826364278793335,
                  "z": 0.1294316053390503
                },
                "index0": {
                  "x": 0.32747697830200195,
                  "y": 1.3846380710601807,
                  "z": 0.12675456702709198
                },
                "middle0": {
                  "x": 0.3261457085609436,
                  "y": 1.370171308517456,
                  "z": 0.12540990114212036
                },
                "ring0": {
                  "x": 0.32398033142089844,
                  "y": 1.3547450304031372,
                  "z": 0.12200497835874557
                },
                "pinky0": {
                  "x": 0.32489195466041565,
                  "y": 1.3403786420822144,
                  "z": 0.116377092897892
                },
                "thumb1": {
                  "x": 0.2569597661495209,
                  "y": 1.6846957206726074,
                  "z": 0.11540698260068893
                },
                "index1": {
                  "x": 0.2655119299888611,
                  "y": 1.6893694400787354,
                  "z": 0.11601044237613678
                },
                "middle1": {
                  "x": 0.26480498909950256,
                  "y": 1.6750166416168213,
                  "z": 0.11707565188407898
                },
                "ring1": {
                  "x": 0.269214004278183,
                  "y": 1.6609505414962769,
                  "z": 0.11950141936540604
                },
                "pinky1": {
                  "x": 0.2790517807006836,
                  "y": 1.6482118368148804,
                  "z": 0.1205112636089325
                }
              },
              {
                "thumb0": {
                  "x": 0.3020722568035126,
                  "y": 1.4058963060379028,
                  "z": 0.16160796582698822
                },
                "index0": {
                  "x": 0.33369800448417664,
                  "y": 1.4095669984817505,
                  "z": 0.16610419750213623
                },
                "middle0": {
                  "x": 0.3294081389904022,
                  "y": 1.3910824060440063,
                  "z": 0.16658776998519897
                },
                "ring0": {
                  "x": 0.321023166179657,
                  "y": 1.3736662864685059,
                  "z": 0.15804190933704376
                },
                "pinky0": {
                  "x": 0.3210321366786957,
                  "y": 1.3587063550949097,
                  "z": 0.15142178535461426
                },
                "thumb1": {
                  "x": 0.24361653625965118,
                  "y": 1.6831904649734497,
                  "z": 0.16558082401752472
                },
                "index1": {
                  "x": 0.24884729087352753,
                  "y": 1.6865394115447998,
                  "z": 0.1603538990020752
                },
                "middle1": {
                  "x": 0.2518298625946045,
                  "y": 1.6744847297668457,
                  "z": 0.1597549319267273
                },
                "ring1": {
                  "x": 0.2568487524986267,
                  "y": 1.660785436630249,
                  "z": 0.15900099277496338
                },
                "pinky1": {
                  "x": 0.26635998487472534,
                  "y": 1.6477158069610596,
                  "z": 0.16364885866641998
                }
              },
              {
                "thumb0": {
                  "x": 0.2891629934310913,
                  "y": 1.4218806028366089,
                  "z": 0.1827189028263092
                },
                "index0": {
                  "x": 0.34386497735977173,
                  "y": 1.426425576210022,
                  "z": 0.19735392928123474
                },
                "middle0": {
                  "x": 0.3471298813819885,
                  "y": 1.403301477432251,
                  "z": 0.20500527322292328
                },
                "ring0": {
                  "x": 0.33903008699417114,
                  "y": 1.38149893283844,
                  "z": 0.19873681664466858
                },
                "pinky0": {
                  "x": 0.33244216442108154,
                  "y": 1.3619505167007446,
                  "z": 0.1827230155467987
                },
                "thumb1": {
                  "x": 0.22964279353618622,
                  "y": 1.610831618309021,
                  "z": 0.18630456924438477
                },
                "index1": {
                  "x": 0.3016328811645508,
                  "y": 1.6217596530914307,
                  "z": 0.14514753222465515
                },
                "middle1": {
                  "x": 0.32202795147895813,
                  "y": 1.608220100402832,
                  "z": 0.13476847112178802
                },
                "ring1": {
                  "x": 0.34112101793289185,
                  "y": 1.5849491357803345,
                  "z": 0.14229166507720947
                },
                "pinky1": {
                  "x": 0.34905511140823364,
                  "y": 1.5526896715164185,
                  "z": 0.16612288355827332
                }
              },
              {
                "thumb0": {
                  "x": 0.2839295268058777,
                  "y": 1.397029161453247,
                  "z": 0.19559484720230103
                },
                "index0": {
                  "x": 0.3544918894767761,
                  "y": 1.4467567205429077,
                  "z": 0.1945819854736328
                },
                "middle0": {
                  "x": 0.37771427631378174,
                  "y": 1.4258838891983032,
                  "z": 0.19113948941230774
                },
                "ring0": {
                  "x": 0.38689327239990234,
                  "y": 1.4049015045166016,
                  "z": 0.1772114783525467
                },
                "pinky0": {
                  "x": 0.3841869831085205,
                  "y": 1.3794736862182617,
                  "z": 0.15372881293296814
                },
                "thumb1": {
                  "x": 0.23524920642375946,
                  "y": 1.470578908920288,
                  "z": 0.1383531093597412
                },
                "index1": {
                  "x": 0.2860413193702698,
                  "y": 1.5659973621368408,
                  "z": 0.14759774506092072
                },
                "middle1": {
                  "x": 0.31191202998161316,
                  "y": 1.5765233039855957,
                  "z": 0.16114097833633423
                },
                "ring1": {
                  "x": 0.3258007764816284,
                  "y": 1.5695075988769531,
                  "z": 0.18615752458572388
                },
                "pinky1": {
                  "x": 0.34046775102615356,
                  "y": 1.5435947179794312,
                  "z": 0.22121909260749817
                }
              },
              {
                "thumb0": {
                  "x": 0.28840288519859314,
                  "y": 1.3940688371658325,
                  "z": 0.18968698382377625
                },
                "index0": {
                  "x": 0.3282512128353119,
                  "y": 1.4889651536941528,
                  "z": 0.17841854691505432
                },
                "middle0": {
                  "x": 0.3573931157588959,
                  "y": 1.4978429079055786,
                  "z": 0.16175633668899536
                },
                "ring0": {
                  "x": 0.37345242500305176,
                  "y": 1.4857850074768066,
                  "z": 0.13881853222846985
                },
                "pinky0": {
                  "x": 0.3835889995098114,
                  "y": 1.4557528495788574,
                  "z": 0.10891567915678024
                },
                "thumb1": {
                  "x": 0.2536143958568573,
                  "y": 1.4618818759918213,
                  "z": 0.14422020316123962
                },
                "index1": {
                  "x": 0.2831261157989502,
                  "y": 1.566820740699768,
                  "z": 0.16456936299800873
                },
                "middle1": {
                  "x": 0.3036973774433136,
                  "y": 1.5818735361099243,
                  "z": 0.1803404986858368
                },
                "ring1": {
                  "x": 0.31367406249046326,
                  "y": 1.579968810081482,
                  "z": 0.21073299646377563
                },
                "pinky1": {
                  "x": 0.33176982402801514,
                  "y": 1.5551930665969849,
                  "z": 0.24507518112659454
                }
              },
              {
                "thumb0": {
                  "x": 0.29282909631729126,
                  "y": 1.4184465408325195,
                  "z": 0.16183245182037354
                },
                "index0": {
                  "x": 0.3119173049926758,
                  "y": 1.5203124284744263,
                  "z": 0.1439271718263626
                },
                "middle0": {
                  "x": 0.3371633291244507,
                  "y": 1.53359854221344,
                  "z": 0.12337754666805267
                },
                "ring0": {
                  "x": 0.3532157242298126,
                  "y": 1.5235822200775146,
                  "z": 0.09647977352142334
                },
                "pinky0": {
                  "x": 0.3659721314907074,
                  "y": 1.4948312044143677,
                  "z": 0.062320906668901443
                },
                "thumb1": {
                  "x": 0.2605459988117218,
                  "y": 1.4684804677963257,
                  "z": 0.15334589779376984
                },
                "index1": {
                  "x": 0.28624099493026733,
                  "y": 1.5719915628433228,
                  "z": 0.17574112117290497
                },
                "middle1": {
                  "x": 0.3060067594051361,
                  "y": 1.58732271194458,
                  "z": 0.1919226497411728
                },
                "ring1": {
                  "x": 0.31319648027420044,
                  "y": 1.586749792098999,
                  "z": 0.22399836778640747
                },
                "pinky1": {
                  "x": 0.3251466155052185,
                  "y": 1.5654610395431519,
                  "z": 0.2606573700904846
                }
              },
              {
                "thumb0": {
                  "x": 0.29602882266044617,
                  "y": 1.4379136562347412,
                  "z": 0.13697659969329834
                },
                "index0": {
                  "x": 0.3082921504974365,
                  "y": 1.5366030931472778,
                  "z": 0.11772734671831131
                },
                "middle0": {
                  "x": 0.3286939561367035,
                  "y": 1.5526233911514282,
                  "z": 0.0930868536233902
                },
                "ring0": {
                  "x": 0.34328901767730713,
                  "y": 1.5425201654434204,
                  "z": 0.06436635553836823
                },
                "pinky0": {
                  "x": 0.3544580936431885,
                  "y": 1.513480305671692,
                  "z": 0.028979547321796417
                },
                "thumb1": {
                  "x": 0.2727105915546417,
                  "y": 1.456648826599121,
                  "z": 0.15776972472667694
                },
                "index1": {
                  "x": 0.300106406211853,
                  "y": 1.5575040578842163,
                  "z": 0.176420658826828
                },
                "middle1": {
                  "x": 0.3188001215457916,
                  "y": 1.5749341249465942,
                  "z": 0.19337135553359985
                },
                "ring1": {
                  "x": 0.3241262137889862,
                  "y": 1.575472354888916,
                  "z": 0.22646626830101013
                },
                "pinky1": {
                  "x": 0.3362095355987549,
                  "y": 1.5541378259658813,
                  "z": 0.26426026225090027
                }
              },
              {
                "thumb0": {
                  "x": 0.29059934616088867,
                  "y": 1.4498714208602905,
                  "z": 0.10459798574447632
                },
                "index0": {
                  "x": 0.30058762431144714,
                  "y": 1.5472012758255005,
                  "z": 0.08762307465076447
                },
                "middle0": {
                  "x": 0.31879740953445435,
                  "y": 1.5643844604492188,
                  "z": 0.0615338496863842
                },
                "ring0": {
                  "x": 0.33076807856559753,
                  "y": 1.5557502508163452,
                  "z": 0.03151809424161911
                },
                "pinky0": {
                  "x": 0.34040626883506775,
                  "y": 1.527400016784668,
                  "z": -0.006935194134712219
                },
                "thumb1": {
                  "x": 0.2760533392429352,
                  "y": 1.4650418758392334,
                  "z": 0.13691362738609314
                },
                "index1": {
                  "x": 0.300037682056427,
                  "y": 1.5650626420974731,
                  "z": 0.16118766367435455
                },
                "middle1": {
                  "x": 0.3194834887981415,
                  "y": 1.5804449319839478,
                  "z": 0.17870891094207764
                },
                "ring1": {
                  "x": 0.32311156392097473,
                  "y": 1.5798784494400024,
                  "z": 0.21229904890060425
                },
                "pinky1": {
                  "x": 0.3348383903503418,
                  "y": 1.5566896200180054,
                  "z": 0.2489546835422516
                }
              },
              {
                "thumb0": {
                  "x": 0.27209874987602234,
                  "y": 1.4696124792099,
                  "z": 0.03757505863904953
                },
                "index0": {
                  "x": 0.2761060297489166,
                  "y": 1.5655434131622314,
                  "z": 0.016532234847545624
                },
                "middle0": {
                  "x": 0.28944945335388184,
                  "y": 1.5827306509017944,
                  "z": -0.013092808425426483
                },
                "ring0": {
                  "x": 0.2964753806591034,
                  "y": 1.574061393737793,
                  "z": -0.045081719756126404
                },
                "pinky0": {
                  "x": 0.3016328811645508,
                  "y": 1.5447347164154053,
                  "z": -0.08380682021379471
                },
                "thumb1": {
                  "x": 0.26218703389167786,
                  "y": 1.47944974899292,
                  "z": 0.09654098749160767
                },
                "index1": {
                  "x": 0.28345853090286255,
                  "y": 1.5797722339630127,
                  "z": 0.119195856153965
                },
                "middle1": {
                  "x": 0.30143916606903076,
                  "y": 1.5972625017166138,
                  "z": 0.13477177917957306
                },
                "ring1": {
                  "x": 0.3085455298423767,
                  "y": 1.5974045991897583,
                  "z": 0.16750115156173706
                },
                "pinky1": {
                  "x": 0.32195723056793213,
                  "y": 1.5766185522079468,
                  "z": 0.2035980224609375
                }
              },
              {
                "thumb0": {
                  "x": 0.254556804895401,
                  "y": 1.4883856773376465,
                  "z": -0.032326579093933105
                },
                "index0": {
                  "x": 0.2524571120738983,
                  "y": 1.5842440128326416,
                  "z": -0.05604895204305649
                },
                "middle0": {
                  "x": 0.25981274247169495,
                  "y": 1.6017366647720337,
                  "z": -0.08783136308193207
                },
                "ring0": {
                  "x": 0.2608383297920227,
                  "y": 1.5929944515228271,
                  "z": -0.11912064254283905
                },
                "pinky0": {
                  "x": 0.2584397494792938,
                  "y": 1.5655449628829956,
                  "z": -0.1576267033815384
                },
                "thumb1": {
                  "x": 0.26169124245643616,
                  "y": 1.4854028224945068,
                  "z": 0.05811436101794243
                },
                "index1": {
                  "x": 0.28570839762687683,
                  "y": 1.5868566036224365,
                  "z": 0.07870665937662125
                },
                "middle1": {
                  "x": 0.3050040900707245,
                  "y": 1.6041171550750732,
                  "z": 0.0929911807179451
                },
                "ring1": {
                  "x": 0.31315872073173523,
                  "y": 1.6045488119125366,
                  "z": 0.12504836916923523
                },
                "pinky1": {
                  "x": 0.3289196193218231,
                  "y": 1.5841139554977417,
                  "z": 0.1602276861667633
                }
              },
              {
                "thumb0": {
                  "x": 0.25395873188972473,
                  "y": 1.4843500852584839,
                  "z": -0.06517782807350159
                },
                "index0": {
                  "x": 0.24752186238765717,
                  "y": 1.583605408668518,
                  "z": -0.09358011186122894
                },
                "middle0": {
                  "x": 0.25079208612442017,
                  "y": 1.5997982025146484,
                  "z": -0.12588681280612946
                },
                "ring0": {
                  "x": 0.24829302728176117,
                  "y": 1.5894421339035034,
                  "z": -0.15585194528102875
                },
                "pinky0": {
                  "x": 0.23948098719120026,
                  "y": 1.5623657703399658,
                  "z": -0.1895596981048584
                },
                "thumb1": {
                  "x": 0.2529124915599823,
                  "y": 1.4907724857330322,
                  "z": 0.03320646286010742
                },
                "index1": {
                  "x": 0.27756965160369873,
                  "y": 1.5928409099578857,
                  "z": 0.050472941249608994
                },
                "middle1": {
                  "x": 0.2986118495464325,
                  "y": 1.6105692386627197,
                  "z": 0.06317566335201263
                },
                "ring1": {
                  "x": 0.31046435236930847,
                  "y": 1.610910177230835,
                  "z": 0.09413705766201019
                },
                "pinky1": {
                  "x": 0.32809746265411377,
                  "y": 1.5912773609161377,
                  "z": 0.12839308381080627
                }
              },
              {
                "thumb0": {
                  "x": 0.25397080183029175,
                  "y": 1.4777823686599731,
                  "z": -0.08672389388084412
                },
                "index0": {
                  "x": 0.24532081186771393,
                  "y": 1.5784049034118652,
                  "z": -0.1166151836514473
                },
                "middle0": {
                  "x": 0.24653340876102448,
                  "y": 1.5941420793533325,
                  "z": -0.14891254901885986
                },
                "ring0": {
                  "x": 0.24084246158599854,
                  "y": 1.5840989351272583,
                  "z": -0.17767387628555298
                },
                "pinky0": {
                  "x": 0.23012875020503998,
                  "y": 1.55677330493927,
                  "z": -0.21008242666721344
                },
                "thumb1": {
                  "x": 0.249742329120636,
                  "y": 1.487084984779358,
                  "z": 0.019647598266601562
                },
                "index1": {
                  "x": 0.27617552876472473,
                  "y": 1.5914243459701538,
                  "z": 0.033686600625514984
                },
                "middle1": {
                  "x": 0.29982250928878784,
                  "y": 1.6082667112350464,
                  "z": 0.04424426704645157
                },
                "ring1": {
                  "x": 0.3146046996116638,
                  "y": 1.6085968017578125,
                  "z": 0.07382125407457352
                },
                "pinky1": {
                  "x": 0.33383870124816895,
                  "y": 1.5893620252609253,
                  "z": 0.10819371789693832
                }
              }
            ],
            "words": "column"
          },
          {
            "key": "2818b295-2a1a-c14e-1259-da58eb3fe09e",
            "gesture": [
              {
                "thumb0": {
                  "x": 0.24557960033416748,
                  "y": 1.5133569240570068,
                  "z": -0.029548272490501404
                },
                "index0": {
                  "x": 0.2571631968021393,
                  "y": 1.6009212732315063,
                  "z": -0.07097424566745758
                },
                "middle0": {
                  "x": 0.2719089984893799,
                  "y": 1.6134814023971558,
                  "z": -0.1042986512184143
                },
                "ring0": {
                  "x": 0.2866531014442444,
                  "y": 1.5937440395355225,
                  "z": -0.12955930829048157
                },
                "pinky0": {
                  "x": 0.29806798696517944,
                  "y": 1.5524303913116455,
                  "z": -0.16397066414356232
                },
                "thumb1": {
                  "x": 0.28380435705184937,
                  "y": 1.514392614364624,
                  "z": 0.04560551792383194
                },
                "index1": {
                  "x": 0.3248678743839264,
                  "y": 1.6027851104736328,
                  "z": 0.0596604123711586
                },
                "middle1": {
                  "x": 0.35632872581481934,
                  "y": 1.614410400390625,
                  "z": 0.05743889510631561
                },
                "ring1": {
                  "x": 0.3805868923664093,
                  "y": 1.6043590307235718,
                  "z": 0.07135729491710663
                },
                "pinky1": {
                  "x": 0.41098082065582275,
                  "y": 1.5750343799591064,
                  "z": 0.08604105561971664
                }
              },
              {
                "thumb0": {
                  "x": 0.2294519543647766,
                  "y": 1.5176911354064941,
                  "z": -0.04206615686416626
                },
                "index0": {
                  "x": 0.2494352012872696,
                  "y": 1.5996437072753906,
                  "z": -0.08217862993478775
                },
                "middle0": {
                  "x": 0.2657546103000641,
                  "y": 1.611386775970459,
                  "z": -0.11182902008295059
                },
                "ring0": {
                  "x": 0.2857106029987335,
                  "y": 1.5918607711791992,
                  "z": -0.13553251326084137
                },
                "pinky0": {
                  "x": 0.299288809299469,
                  "y": 1.5512354373931885,
                  "z": -0.168432354927063
                },
                "thumb1": {
                  "x": 0.27973702549934387,
                  "y": 1.5217559337615967,
                  "z": 0.05127166211605072
                },
                "index1": {
                  "x": 0.32766100764274597,
                  "y": 1.6087427139282227,
                  "z": 0.061780549585819244
                },
                "middle1": {
                  "x": 0.35404184460639954,
                  "y": 1.6202853918075562,
                  "z": 0.061010267585515976
                },
                "ring1": {
                  "x": 0.3794836401939392,
                  "y": 1.6083221435546875,
                  "z": 0.06834857165813446
                },
                "pinky1": {
                  "x": 0.41362401843070984,
                  "y": 1.5772716999053955,
                  "z": 0.0758373960852623
                }
              },
              {
                "thumb0": {
                  "x": 0.22975727915763855,
                  "y": 1.5171490907669067,
                  "z": -0.03995104134082794
                },
                "index0": {
                  "x": 0.25004032254219055,
                  "y": 1.6003317832946777,
                  "z": -0.0807747095823288
                },
                "middle0": {
                  "x": 0.26687806844711304,
                  "y": 1.6119717359542847,
                  "z": -0.10995963215827942
                },
                "ring0": {
                  "x": 0.28724405169487,
                  "y": 1.5921920537948608,
                  "z": -0.13281944394111633
                },
                "pinky0": {
                  "x": 0.30149051547050476,
                  "y": 1.5515164136886597,
                  "z": -0.16502481698989868
                },
                "thumb1": {
                  "x": 0.2776053547859192,
                  "y": 1.5245949029922485,
                  "z": 0.05177880451083183
                },
                "index1": {
                  "x": 0.32744482159614563,
                  "y": 1.610666036605835,
                  "z": 0.06286388635635376
                },
                "middle1": {
                  "x": 0.35390612483024597,
                  "y": 1.621498942375183,
                  "z": 0.061136890202760696
                },
                "ring1": {
                  "x": 0.3790758550167084,
                  "y": 1.608701467514038,
                  "z": 0.06696482002735138
                },
                "pinky1": {
                  "x": 0.4154539108276367,
                  "y": 1.5742729902267456,
                  "z": 0.06875547021627426
                }
              },
              {
                "thumb0": {
                  "x": 0.21887797117233276,
                  "y": 1.5254876613616943,
                  "z": -0.01640896499156952
                },
                "index0": {
                  "x": 0.24834659695625305,
                  "y": 1.607344150543213,
                  "z": -0.054205819964408875
                },
                "middle0": {
                  "x": 0.2653089761734009,
                  "y": 1.6191211938858032,
                  "z": -0.07414057105779648
                },
                "ring0": {
                  "x": 0.28651899099349976,
                  "y": 1.603007435798645,
                  "z": -0.0951872318983078
                },
                "pinky0": {
                  "x": 0.30820876359939575,
                  "y": 1.5657519102096558,
                  "z": -0.1244724690914154
                },
                "thumb1": {
                  "x": 0.2608623802661896,
                  "y": 1.5386143922805786,
                  "z": 0.06817008554935455
                },
                "index1": {
                  "x": 0.32175812125205994,
                  "y": 1.6169836521148682,
                  "z": 0.06783650815486908
                },
                "middle1": {
                  "x": 0.3494343161582947,
                  "y": 1.6249384880065918,
                  "z": 0.05846778303384781
                },
                "ring1": {
                  "x": 0.3749033510684967,
                  "y": 1.6071877479553223,
                  "z": 0.05030690133571625
                },
                "pinky1": {
                  "x": 0.4057232737541199,
                  "y": 1.5655862092971802,
                  "z": 0.040020350366830826
                }
              },
              {
                "thumb0": {
                  "x": 0.20951738953590393,
                  "y": 1.5317822694778442,
                  "z": 0.00333598256111145
                },
                "index0": {
                  "x": 0.27118661999702454,
                  "y": 1.5924710035324097,
                  "z": -0.00784110277891159
                },
                "middle0": {
                  "x": 0.28491735458374023,
                  "y": 1.6053085327148438,
                  "z": -0.01521570235490799
                },
                "ring0": {
                  "x": 0.3021533489227295,
                  "y": 1.59611177444458,
                  "z": -0.03408002108335495
                },
                "pinky0": {
                  "x": 0.32292306423187256,
                  "y": 1.565843105316162,
                  "z": -0.06186697632074356
                },
                "thumb1": {
                  "x": 0.25060462951660156,
                  "y": 1.5463355779647827,
                  "z": 0.0769275426864624
                },
                "index1": {
                  "x": 0.3111661672592163,
                  "y": 1.6250981092453003,
                  "z": 0.0680549219250679
                },
                "middle1": {
                  "x": 0.3380090296268463,
                  "y": 1.6336642503738403,
                  "z": 0.05063296854496002
                },
                "ring1": {
                  "x": 0.3628133237361908,
                  "y": 1.6154228448867798,
                  "z": 0.03560061752796173
                },
                "pinky1": {
                  "x": 0.39308053255081177,
                  "y": 1.574868083000183,
                  "z": 0.020626313984394073
                }
              },
              {
                "thumb0": {
                  "x": 0.2266668975353241,
                  "y": 1.532752275466919,
                  "z": 0.021368324756622314
                },
                "index0": {
                  "x": 0.29983586072921753,
                  "y": 1.5640162229537964,
                  "z": 0.02646748721599579
                },
                "middle0": {
                  "x": 0.31539079546928406,
                  "y": 1.5681205987930298,
                  "z": 0.02673482894897461
                },
                "ring0": {
                  "x": 0.3292255401611328,
                  "y": 1.563528299331665,
                  "z": 0.010110199451446533
                },
                "pinky0": {
                  "x": 0.33674904704093933,
                  "y": 1.5492018461227417,
                  "z": -0.018696874380111694
                },
                "thumb1": {
                  "x": 0.23995986580848694,
                  "y": 1.5535509586334229,
                  "z": 0.07448609918355942
                },
                "index1": {
                  "x": 0.2971009314060211,
                  "y": 1.631718397140503,
                  "z": 0.06006179377436638
                },
                "middle1": {
                  "x": 0.3234935998916626,
                  "y": 1.6406813859939575,
                  "z": 0.04121384024620056
                },
                "ring1": {
                  "x": 0.3468681275844574,
                  "y": 1.6233305931091309,
                  "z": 0.02573101967573166
                },
                "pinky1": {
                  "x": 0.3774678707122803,
                  "y": 1.5833855867385864,
                  "z": 0.009084388613700867
                }
              },
              {
                "thumb0": {
                  "x": 0.23476162552833557,
                  "y": 1.5166469812393188,
                  "z": 0.019273534417152405
                },
                "index0": {
                  "x": 0.30327272415161133,
                  "y": 1.503470540046692,
                  "z": 0.022110797464847565
                },
                "middle0": {
                  "x": 0.3180330693721771,
                  "y": 1.5066179037094116,
                  "z": 0.023540988564491272
                },
                "ring0": {
                  "x": 0.32731181383132935,
                  "y": 1.504556655883789,
                  "z": 0.009953133761882782
                },
                "pinky0": {
                  "x": 0.3318384289741516,
                  "y": 1.5055913925170898,
                  "z": -0.016317136585712433
                },
                "thumb1": {
                  "x": 0.23897725343704224,
                  "y": 1.56110417842865,
                  "z": 0.0708668977022171
                },
                "index1": {
                  "x": 0.292999804019928,
                  "y": 1.643952488899231,
                  "z": 0.05928829684853554
                },
                "middle1": {
                  "x": 0.3182081878185272,
                  "y": 1.6550475358963013,
                  "z": 0.04268140345811844
                },
                "ring1": {
                  "x": 0.3421820402145386,
                  "y": 1.6410709619522095,
                  "z": 0.029465772211551666
                },
                "pinky1": {
                  "x": 0.3767751455307007,
                  "y": 1.6051840782165527,
                  "z": 0.015292100608348846
                }
              },
              {
                "thumb0": {
                  "x": 0.24405984580516815,
                  "y": 1.516356348991394,
                  "z": 0.023604705929756165
                },
                "index0": {
                  "x": 0.26718273758888245,
                  "y": 1.4695684909820557,
                  "z": -0.01003468781709671
                },
                "middle0": {
                  "x": 0.27505940198898315,
                  "y": 1.4628572463989258,
                  "z": -0.01168154925107956
                },
                "ring0": {
                  "x": 0.2959575951099396,
                  "y": 1.4641247987747192,
                  "z": -0.007083632051944733
                },
                "pinky0": {
                  "x": 0.30147585272789,
                  "y": 1.4690653085708618,
                  "z": -0.02431328594684601
                },
                "thumb1": {
                  "x": 0.23525048792362213,
                  "y": 1.55837881565094,
                  "z": 0.07138988375663757
                },
                "index1": {
                  "x": 0.2905971109867096,
                  "y": 1.6392074823379517,
                  "z": 0.05381552129983902
                },
                "middle1": {
                  "x": 0.3149617910385132,
                  "y": 1.6485055685043335,
                  "z": 0.03611351177096367
                },
                "ring1": {
                  "x": 0.3397609293460846,
                  "y": 1.6335116624832153,
                  "z": 0.02480504661798477
                },
                "pinky1": {
                  "x": 0.37222617864608765,
                  "y": 1.5961449146270752,
                  "z": 0.011338301002979279
                }
              },
              {
                "thumb0": {
                  "x": 0.2341969758272171,
                  "y": 1.5601962804794312,
                  "z": 0.017228245735168457
                },
                "index0": {
                  "x": 0.302852600812912,
                  "y": 1.553955078125,
                  "z": 0.026868321001529694
                },
                "middle0": {
                  "x": 0.31431737542152405,
                  "y": 1.5424425601959229,
                  "z": 0.02625218778848648
                },
                "ring0": {
                  "x": 0.31829458475112915,
                  "y": 1.5295588970184326,
                  "z": 0.012404009699821472
                },
                "pinky0": {
                  "x": 0.3266170918941498,
                  "y": 1.5325356721878052,
                  "z": -0.009710647165775299
                },
                "thumb1": {
                  "x": 0.2364150881767273,
                  "y": 1.5574376583099365,
                  "z": 0.07510777562856674
                },
                "index1": {
                  "x": 0.29261744022369385,
                  "y": 1.636720895767212,
                  "z": 0.05385582521557808
                },
                "middle1": {
                  "x": 0.31545403599739075,
                  "y": 1.6453030109405518,
                  "z": 0.03367520868778229
                },
                "ring1": {
                  "x": 0.3412270247936249,
                  "y": 1.6286107301712036,
                  "z": 0.019281595945358276
                },
                "pinky1": {
                  "x": 0.37004414200782776,
                  "y": 1.5887982845306396,
                  "z": 0.0015347972512245178
                }
              },
              {
                "thumb0": {
                  "x": 0.22830787301063538,
                  "y": 1.5575220584869385,
                  "z": 0.01764899492263794
                },
                "index0": {
                  "x": 0.2866467237472534,
                  "y": 1.6218748092651367,
                  "z": 0.006073564291000366
                },
                "middle0": {
                  "x": 0.313827782869339,
                  "y": 1.623020052909851,
                  "z": 0.0009013861417770386
                },
                "ring0": {
                  "x": 0.329325795173645,
                  "y": 1.6056212186813354,
                  "z": -0.009156450629234314
                },
                "pinky0": {
                  "x": 0.33997413516044617,
                  "y": 1.5719404220581055,
                  "z": -0.02424013614654541
                },
                "thumb1": {
                  "x": 0.241054505109787,
                  "y": 1.5544639825820923,
                  "z": 0.06809522211551666
                },
                "index1": {
                  "x": 0.29740360379219055,
                  "y": 1.6391347646713257,
                  "z": 0.05556926503777504
                },
                "middle1": {
                  "x": 0.3171680271625519,
                  "y": 1.6490962505340576,
                  "z": 0.03973495215177536
                },
                "ring1": {
                  "x": 0.3376309871673584,
                  "y": 1.6362025737762451,
                  "z": 0.02696230262517929
                },
                "pinky1": {
                  "x": 0.3589078187942505,
                  "y": 1.6032195091247559,
                  "z": 0.007565252482891083
                }
              },
              {
                "thumb0": {
                  "x": 0.23300644755363464,
                  "y": 1.4958724975585938,
                  "z": 0.032174862921237946
                },
                "index0": {
                  "x": 0.2957030236721039,
                  "y": 1.5522891283035278,
                  "z": 0.032553695142269135
                },
                "middle0": {
                  "x": 0.31324097514152527,
                  "y": 1.5620700120925903,
                  "z": 0.023082323372364044
                },
                "ring0": {
                  "x": 0.3298771381378174,
                  "y": 1.5461406707763672,
                  "z": 0.005835242569446564
                },
                "pinky0": {
                  "x": 0.3413040339946747,
                  "y": 1.5160555839538574,
                  "z": -0.02198813110589981
                },
                "thumb1": {
                  "x": 0.23756013810634613,
                  "y": 1.5646768808364868,
                  "z": 0.07930503785610199
                },
                "index1": {
                  "x": 0.2962963879108429,
                  "y": 1.64321768283844,
                  "z": 0.05741773918271065
                },
                "middle1": {
                  "x": 0.31708642840385437,
                  "y": 1.651753544807434,
                  "z": 0.04008544981479645
                },
                "ring1": {
                  "x": 0.337900310754776,
                  "y": 1.6373142004013062,
                  "z": 0.02895527333021164
                },
                "pinky1": {
                  "x": 0.35604169964790344,
                  "y": 1.6059951782226562,
                  "z": 0.01445111632347107
                }
              },
              {
                "thumb0": {
                  "x": 0.23104369640350342,
                  "y": 1.5099064111709595,
                  "z": 0.003964632749557495
                },
                "index0": {
                  "x": 0.28068697452545166,
                  "y": 1.466896653175354,
                  "z": -0.0007016211748123169
                },
                "middle0": {
                  "x": 0.2922084629535675,
                  "y": 1.4638032913208008,
                  "z": 0.0001639053225517273
                },
                "ring0": {
                  "x": 0.3046640455722809,
                  "y": 1.4658197164535522,
                  "z": -0.007377542555332184
                },
                "pinky0": {
                  "x": 0.310454398393631,
                  "y": 1.4674307107925415,
                  "z": -0.03032533824443817
                },
                "thumb1": {
                  "x": 0.242707297205925,
                  "y": 1.5662829875946045,
                  "z": 0.08244118094444275
                },
                "index1": {
                  "x": 0.2984603941440582,
                  "y": 1.6443653106689453,
                  "z": 0.06554582715034485
                },
                "middle1": {
                  "x": 0.3186991214752197,
                  "y": 1.6542991399765015,
                  "z": 0.05157560855150223
                },
                "ring1": {
                  "x": 0.33905062079429626,
                  "y": 1.640723466873169,
                  "z": 0.04350687190890312
                },
                "pinky1": {
                  "x": 0.3607914447784424,
                  "y": 1.6110429763793945,
                  "z": 0.030601151287555695
                }
              },
              {
                "thumb0": {
                  "x": 0.24480903148651123,
                  "y": 1.5592550039291382,
                  "z": 0.013740688562393188
                },
                "index0": {
                  "x": 0.27752384543418884,
                  "y": 1.5166586637496948,
                  "z": 0.010294295847415924
                },
                "middle0": {
                  "x": 0.28526195883750916,
                  "y": 1.509836196899414,
                  "z": 0.008569583296775818
                },
                "ring0": {
                  "x": 0.2952301502227783,
                  "y": 1.5094987154006958,
                  "z": 0.0004511326551437378
                },
                "pinky0": {
                  "x": 0.3039546310901642,
                  "y": 1.5102225542068481,
                  "z": -0.014458619058132172
                },
                "thumb1": {
                  "x": 0.2371903359889984,
                  "y": 1.5629966259002686,
                  "z": 0.0693049505352974
                },
                "index1": {
                  "x": 0.29106926918029785,
                  "y": 1.6442326307296753,
                  "z": 0.05114264041185379
                },
                "middle1": {
                  "x": 0.3096139132976532,
                  "y": 1.6549469232559204,
                  "z": 0.03622336685657501
                },
                "ring1": {
                  "x": 0.33047229051589966,
                  "y": 1.6418489217758179,
                  "z": 0.02596333622932434
                },
                "pinky1": {
                  "x": 0.35469773411750793,
                  "y": 1.6120240688323975,
                  "z": 0.011188969016075134
                }
              },
              {
                "thumb0": {
                  "x": 0.23311007022857666,
                  "y": 1.5789763927459717,
                  "z": 0.0034461989998817444
                },
                "index0": {
                  "x": 0.2840830385684967,
                  "y": 1.6334580183029175,
                  "z": 0.0010429397225379944
                },
                "middle0": {
                  "x": 0.3067648410797119,
                  "y": 1.636704444885254,
                  "z": -0.004061281681060791
                },
                "ring0": {
                  "x": 0.3227960467338562,
                  "y": 1.6209012269973755,
                  "z": -0.01379586011171341
                },
                "pinky0": {
                  "x": 0.33624401688575745,
                  "y": 1.5903719663619995,
                  "z": -0.033935099840164185
                },
                "thumb1": {
                  "x": 0.2341538518667221,
                  "y": 1.5546475648880005,
                  "z": 0.06687182188034058
                },
                "index1": {
                  "x": 0.29176121950149536,
                  "y": 1.6429224014282227,
                  "z": 0.05471837520599365
                },
                "middle1": {
                  "x": 0.3088923990726471,
                  "y": 1.6544816493988037,
                  "z": 0.04149869084358215
                },
                "ring1": {
                  "x": 0.3263896405696869,
                  "y": 1.6428041458129883,
                  "z": 0.029333606362342834
                },
                "pinky1": {
                  "x": 0.35004401206970215,
                  "y": 1.6142597198486328,
                  "z": 0.011496685445308685
                }
              },
              {
                "thumb0": {
                  "x": 0.2364984154701233,
                  "y": 1.5256314277648926,
                  "z": 0.03211309760808945
                },
                "index0": {
                  "x": 0.2854974567890167,
                  "y": 1.5974977016448975,
                  "z": 0.023235797882080078
                },
                "middle0": {
                  "x": 0.3137727677822113,
                  "y": 1.6031383275985718,
                  "z": 0.012618161737918854
                },
                "ring0": {
                  "x": 0.3320592939853668,
                  "y": 1.5867217779159546,
                  "z": -0.001711331307888031
                },
                "pinky0": {
                  "x": 0.3490831255912781,
                  "y": 1.5529775619506836,
                  "z": -0.023352697491645813
                },
                "thumb1": {
                  "x": 0.22918717563152313,
                  "y": 1.5596574544906616,
                  "z": 0.06360551714897156
                },
                "index1": {
                  "x": 0.28602665662765503,
                  "y": 1.6461325883865356,
                  "z": 0.05131702497601509
                },
                "middle1": {
                  "x": 0.3040120601654053,
                  "y": 1.657556414604187,
                  "z": 0.03762996196746826
                },
                "ring1": {
                  "x": 0.3208775222301483,
                  "y": 1.6460717916488647,
                  "z": 0.025712057948112488
                },
                "pinky1": {
                  "x": 0.342437207698822,
                  "y": 1.6177794933319092,
                  "z": 0.008826397359371185
                }
              },
              {
                "thumb0": {
                  "x": 0.21884821355342865,
                  "y": 1.4937905073165894,
                  "z": 0.01572585105895996
                },
                "index0": {
                  "x": 0.2875153124332428,
                  "y": 1.52555513381958,
                  "z": 0.029747374355793
                },
                "middle0": {
                  "x": 0.3076567053794861,
                  "y": 1.5422322750091553,
                  "z": 0.015837647020816803
                },
                "ring0": {
                  "x": 0.323432058095932,
                  "y": 1.5228129625320435,
                  "z": -0.0012603849172592163
                },
                "pinky0": {
                  "x": 0.330262154340744,
                  "y": 1.497787356376648,
                  "z": -0.03513573110103607
                },
                "thumb1": {
                  "x": 0.23408143222332,
                  "y": 1.5726447105407715,
                  "z": 0.079872727394104
                },
                "index1": {
                  "x": 0.2925059199333191,
                  "y": 1.650295376777649,
                  "z": 0.06533563137054443
                },
                "middle1": {
                  "x": 0.31227660179138184,
                  "y": 1.6601656675338745,
                  "z": 0.05264255404472351
                },
                "ring1": {
                  "x": 0.332677960395813,
                  "y": 1.6457414627075195,
                  "z": 0.04200180992484093
                },
                "pinky1": {
                  "x": 0.35423213243484497,
                  "y": 1.6144143342971802,
                  "z": 0.024975575506687164
                }
              },
              {
                "thumb0": {
                  "x": 0.21869656443595886,
                  "y": 1.5269761085510254,
                  "z": 0.0013371855020523071
                },
                "index0": {
                  "x": 0.27081745862960815,
                  "y": 1.4822322130203247,
                  "z": -0.008589282631874084
                },
                "middle0": {
                  "x": 0.2796348035335541,
                  "y": 1.4744861125946045,
                  "z": -0.009639106690883636
                },
                "ring0": {
                  "x": 0.2966204583644867,
                  "y": 1.4806604385375977,
                  "z": -0.015061229467391968
                },
                "pinky0": {
                  "x": 0.3120390474796295,
                  "y": 1.5018653869628906,
                  "z": -0.0351918488740921
                },
                "thumb1": {
                  "x": 0.23509816825389862,
                  "y": 1.5748348236083984,
                  "z": 0.07586008310317993
                },
                "index1": {
                  "x": 0.2899681031703949,
                  "y": 1.6546066999435425,
                  "z": 0.06466402113437653
                },
                "middle1": {
                  "x": 0.31019318103790283,
                  "y": 1.6655482053756714,
                  "z": 0.05359971150755882
                },
                "ring1": {
                  "x": 0.33081167936325073,
                  "y": 1.6522489786148071,
                  "z": 0.043129656463861465
                },
                "pinky1": {
                  "x": 0.3552592992782593,
                  "y": 1.6226505041122437,
                  "z": 0.028742529451847076
                }
              }
            ],
            "words": "color"
          },
          {
            "key": "82c46dfd-d3fe-6489-13cb-f32c1581ecc0",
            "gesture": [
              {
                "thumb0": {
                  "x": 0.2039574384689331,
                  "y": 1.5352412462234497,
                  "z": -0.03120042383670807
                },
                "index0": {
                  "x": 0.2184646874666214,
                  "y": 1.621158242225647,
                  "z": -0.06774865835905075
                },
                "middle0": {
                  "x": 0.2351580411195755,
                  "y": 1.6347899436950684,
                  "z": -0.0918075293302536
                },
                "ring0": {
                  "x": 0.25592759251594543,
                  "y": 1.6208363771438599,
                  "z": -0.1161443442106247
                },
                "pinky0": {
                  "x": 0.27780210971832275,
                  "y": 1.5853774547576904,
                  "z": -0.14583197236061096
                },
                "thumb1": {
                  "x": 0.2645069658756256,
                  "y": 1.554876446723938,
                  "z": 0.06399372220039368
                },
                "index1": {
                  "x": 0.3156141936779022,
                  "y": 1.6374528408050537,
                  "z": 0.0735645666718483
                },
                "middle1": {
                  "x": 0.3461572229862213,
                  "y": 1.646982192993164,
                  "z": 0.06238240376114845
                },
                "ring1": {
                  "x": 0.3743709921836853,
                  "y": 1.631642460823059,
                  "z": 0.056975435465574265
                },
                "pinky1": {
                  "x": 0.4070221483707428,
                  "y": 1.5944355726242065,
                  "z": 0.053657498210668564
                }
              },
              {
                "thumb0": {
                  "x": 0.20608948171138763,
                  "y": 1.537583351135254,
                  "z": -0.022227048873901367
                },
                "index0": {
                  "x": 0.220651775598526,
                  "y": 1.6233012676239014,
                  "z": -0.059329159557819366
                },
                "middle0": {
                  "x": 0.2369506061077118,
                  "y": 1.6372439861297607,
                  "z": -0.08437331765890121
                },
                "ring0": {
                  "x": 0.25852590799331665,
                  "y": 1.6232880353927612,
                  "z": -0.10818769782781601
                },
                "pinky0": {
                  "x": 0.28266289830207825,
                  "y": 1.5871238708496094,
                  "z": -0.13768208026885986
                },
                "thumb1": {
                  "x": 0.26286619901657104,
                  "y": 1.5575462579727173,
                  "z": 0.07242812216281891
                },
                "index1": {
                  "x": 0.3122415542602539,
                  "y": 1.6400856971740723,
                  "z": 0.08074671775102615
                },
                "middle1": {
                  "x": 0.337597519159317,
                  "y": 1.6505903005599976,
                  "z": 0.07240032404661179
                },
                "ring1": {
                  "x": 0.3670313060283661,
                  "y": 1.6361138820648193,
                  "z": 0.06767779588699341
                },
                "pinky1": {
                  "x": 0.402524471282959,
                  "y": 1.5995709896087646,
                  "z": 0.06342990696430206
                }
              },
              {
                "thumb0": {
                  "x": 0.2079268842935562,
                  "y": 1.5377326011657715,
                  "z": -0.021064557135105133
                },
                "index0": {
                  "x": 0.22253277897834778,
                  "y": 1.6227216720581055,
                  "z": -0.05807174742221832
                },
                "middle0": {
                  "x": 0.2391100972890854,
                  "y": 1.6366727352142334,
                  "z": -0.08335433900356293
                },
                "ring0": {
                  "x": 0.26087838411331177,
                  "y": 1.6226179599761963,
                  "z": -0.10702191293239594
                },
                "pinky0": {
                  "x": 0.28555071353912354,
                  "y": 1.586122989654541,
                  "z": -0.13674414157867432
                },
                "thumb1": {
                  "x": 0.26286688446998596,
                  "y": 1.5598084926605225,
                  "z": 0.07683182507753372
                },
                "index1": {
                  "x": 0.3127064108848572,
                  "y": 1.6419665813446045,
                  "z": 0.08569297194480896
                },
                "middle1": {
                  "x": 0.34120380878448486,
                  "y": 1.6527149677276611,
                  "z": 0.07812747359275818
                },
                "ring1": {
                  "x": 0.37020161747932434,
                  "y": 1.637772798538208,
                  "z": 0.07288039475679398
                },
                "pinky1": {
                  "x": 0.40523046255111694,
                  "y": 1.6007983684539795,
                  "z": 0.06869436800479889
                }
              },
              {
                "thumb0": {
                  "x": 0.20962189137935638,
                  "y": 1.5390371084213257,
                  "z": -0.020190782845020294
                },
                "index0": {
                  "x": 0.22668518126010895,
                  "y": 1.6242629289627075,
                  "z": -0.054760873317718506
                },
                "middle0": {
                  "x": 0.24416904151439667,
                  "y": 1.6386514902114868,
                  "z": -0.07887835800647736
                },
                "ring0": {
                  "x": 0.266112357378006,
                  "y": 1.6251858472824097,
                  "z": -0.1021556705236435
                },
                "pinky0": {
                  "x": 0.291835755109787,
                  "y": 1.5897728204727173,
                  "z": -0.13218744099140167
                },
                "thumb1": {
                  "x": 0.23263318836688995,
                  "y": 1.5529569387435913,
                  "z": 0.06786822527647018
                },
                "index1": {
                  "x": 0.2671056389808655,
                  "y": 1.6249603033065796,
                  "z": 0.036582741886377335
                },
                "middle1": {
                  "x": 0.2900444567203522,
                  "y": 1.6352195739746094,
                  "z": 0.023028850555419922
                },
                "ring1": {
                  "x": 0.3185971975326538,
                  "y": 1.6233735084533691,
                  "z": 0.01599210500717163
                },
                "pinky1": {
                  "x": 0.3551555573940277,
                  "y": 1.5930755138397217,
                  "z": 0.015851236879825592
                }
              },
              {
                "thumb0": {
                  "x": 0.20048359036445618,
                  "y": 1.5448200702667236,
                  "z": 0.0010085999965667725
                },
                "index0": {
                  "x": 0.2317943274974823,
                  "y": 1.6314377784729004,
                  "z": -0.03269357979297638
                },
                "middle0": {
                  "x": 0.2543514370918274,
                  "y": 1.644494652748108,
                  "z": -0.05206107348203659
                },
                "ring0": {
                  "x": 0.27980107069015503,
                  "y": 1.6303486824035645,
                  "z": -0.06880586594343185
                },
                "pinky0": {
                  "x": 0.31250306963920593,
                  "y": 1.5939533710479736,
                  "z": -0.08857733756303787
                },
                "thumb1": {
                  "x": 0.19197313487529755,
                  "y": 1.5304765701293945,
                  "z": 0.03830939531326294
                },
                "index1": {
                  "x": 0.2201017588376999,
                  "y": 1.575117588043213,
                  "z": -0.03729863464832306
                },
                "middle1": {
                  "x": 0.23278583586215973,
                  "y": 1.5831342935562134,
                  "z": -0.04964408278465271
                },
                "ring1": {
                  "x": 0.25780728459358215,
                  "y": 1.5698628425598145,
                  "z": -0.054754458367824554
                },
                "pinky1": {
                  "x": 0.2938198149204254,
                  "y": 1.5412029027938843,
                  "z": -0.05109352618455887
                }
              },
              {
                "thumb0": {
                  "x": 0.18218983709812164,
                  "y": 1.5616915225982666,
                  "z": -0.0032821670174598694
                },
                "index0": {
                  "x": 0.21075476706027985,
                  "y": 1.6506932973861694,
                  "z": -0.046249955892562866
                },
                "middle0": {
                  "x": 0.23776371777057648,
                  "y": 1.663440227508545,
                  "z": -0.0646161437034607
                },
                "ring0": {
                  "x": 0.2624762952327728,
                  "y": 1.6486274003982544,
                  "z": -0.07615740597248077
                },
                "pinky0": {
                  "x": 0.29508528113365173,
                  "y": 1.613829493522644,
                  "z": -0.09688267111778259
                },
                "thumb1": {
                  "x": 0.20525489747524261,
                  "y": 1.4985665082931519,
                  "z": 0.05675261467695236
                },
                "index1": {
                  "x": 0.23432685434818268,
                  "y": 1.5090172290802002,
                  "z": -0.03174404799938202
                },
                "middle1": {
                  "x": 0.24438665807247162,
                  "y": 1.5195915699005127,
                  "z": -0.04478470981121063
                },
                "ring1": {
                  "x": 0.26566287875175476,
                  "y": 1.5050756931304932,
                  "z": -0.047319985926151276
                },
                "pinky1": {
                  "x": 0.2968016266822815,
                  "y": 1.486398458480835,
                  "z": -0.03620090335607529
                }
              },
              {
                "thumb0": {
                  "x": 0.18063463270664215,
                  "y": 1.5548689365386963,
                  "z": 0.004368185997009277
                },
                "index0": {
                  "x": 0.2192048281431198,
                  "y": 1.64677894115448,
                  "z": -0.03910507261753082
                },
                "middle0": {
                  "x": 0.244906485080719,
                  "y": 1.658287525177002,
                  "z": -0.05681384354829788
                },
                "ring0": {
                  "x": 0.26585763692855835,
                  "y": 1.6438413858413696,
                  "z": -0.06821417063474655
                },
                "pinky0": {
                  "x": 0.2972675561904907,
                  "y": 1.6096043586730957,
                  "z": -0.08366195857524872
                },
                "thumb1": {
                  "x": 0.22054854035377502,
                  "y": 1.5435069799423218,
                  "z": 0.050447169691324234
                },
                "index1": {
                  "x": 0.2607622742652893,
                  "y": 1.484470248222351,
                  "z": 0.0012256428599357605
                },
                "middle1": {
                  "x": 0.2648557722568512,
                  "y": 1.4769400358200073,
                  "z": -0.00788426399230957
                },
                "ring1": {
                  "x": 0.27596351504325867,
                  "y": 1.4771727323532104,
                  "z": -0.016614384949207306
                },
                "pinky1": {
                  "x": 0.29628637433052063,
                  "y": 1.4857523441314697,
                  "z": -0.013897784054279327
                }
              },
              {
                "thumb0": {
                  "x": 0.18483571708202362,
                  "y": 1.5509934425354004,
                  "z": 0.0088767409324646
                },
                "index0": {
                  "x": 0.23464259505271912,
                  "y": 1.6398005485534668,
                  "z": -0.036817654967308044
                },
                "middle0": {
                  "x": 0.2687927186489105,
                  "y": 1.647339105606079,
                  "z": -0.0547248050570488
                },
                "ring0": {
                  "x": 0.29149675369262695,
                  "y": 1.6293877363204956,
                  "z": -0.0653047189116478
                },
                "pinky0": {
                  "x": 0.3160276710987091,
                  "y": 1.5914897918701172,
                  "z": -0.07374098896980286
                },
                "thumb1": {
                  "x": 0.22113078832626343,
                  "y": 1.5761510133743286,
                  "z": 0.03960775211453438
                },
                "index1": {
                  "x": 0.25428998470306396,
                  "y": 1.537164568901062,
                  "z": -0.03287241607904434
                },
                "middle1": {
                  "x": 0.25985345244407654,
                  "y": 1.540406584739685,
                  "z": -0.046754516661167145
                },
                "ring1": {
                  "x": 0.27443626523017883,
                  "y": 1.5421514511108398,
                  "z": -0.05071020871400833
                },
                "pinky1": {
                  "x": 0.2946886718273163,
                  "y": 1.5401160717010498,
                  "z": -0.03906560689210892
                }
              },
              {
                "thumb0": {
                  "x": 0.18636132776737213,
                  "y": 1.551464319229126,
                  "z": 0.007627822458744049
                },
                "index0": {
                  "x": 0.2396191656589508,
                  "y": 1.6409838199615479,
                  "z": -0.03963576257228851
                },
                "middle0": {
                  "x": 0.2759276330471039,
                  "y": 1.6470246315002441,
                  "z": -0.0613480806350708
                },
                "ring0": {
                  "x": 0.29641905426979065,
                  "y": 1.6285991668701172,
                  "z": -0.07427782565355301
                },
                "pinky0": {
                  "x": 0.32332322001457214,
                  "y": 1.5863502025604248,
                  "z": -0.08367066830396652
                },
                "thumb1": {
                  "x": 0.2209499180316925,
                  "y": 1.5662730932235718,
                  "z": 0.03353053331375122
                },
                "index1": {
                  "x": 0.26658862829208374,
                  "y": 1.6373116970062256,
                  "z": -0.0023156851530075073
                },
                "middle1": {
                  "x": 0.2893959879875183,
                  "y": 1.6431329250335693,
                  "z": -0.018940992653369904
                },
                "ring1": {
                  "x": 0.3077548146247864,
                  "y": 1.6262712478637695,
                  "z": -0.027632512152194977
                },
                "pinky1": {
                  "x": 0.33577314019203186,
                  "y": 1.5916231870651245,
                  "z": -0.034123167395591736
                }
              },
              {
                "thumb0": {
                  "x": 0.18333588540554047,
                  "y": 1.5629757642745972,
                  "z": 0.000990159809589386
                },
                "index0": {
                  "x": 0.2335786074399948,
                  "y": 1.6516000032424927,
                  "z": -0.0453285276889801
                },
                "middle0": {
                  "x": 0.2638697326183319,
                  "y": 1.6593667268753052,
                  "z": -0.06164047122001648
                },
                "ring0": {
                  "x": 0.28730079531669617,
                  "y": 1.642029881477356,
                  "z": -0.07447174936532974
                },
                "pinky0": {
                  "x": 0.3174012005329132,
                  "y": 1.6015629768371582,
                  "z": -0.08616858720779419
                },
                "thumb1": {
                  "x": 0.20215581357479095,
                  "y": 1.5004116296768188,
                  "z": 0.014307647943496704
                },
                "index1": {
                  "x": 0.2325170785188675,
                  "y": 1.5669941902160645,
                  "z": -0.03551246225833893
                },
                "middle1": {
                  "x": 0.2568759024143219,
                  "y": 1.5777937173843384,
                  "z": -0.04721386730670929
                },
                "ring1": {
                  "x": 0.2797805964946747,
                  "y": 1.5559099912643433,
                  "z": -0.06011088192462921
                },
                "pinky1": {
                  "x": 0.3143818974494934,
                  "y": 1.5211474895477295,
                  "z": -0.05909644812345505
                }
              },
              {
                "thumb0": {
                  "x": 0.18176762759685516,
                  "y": 1.5644012689590454,
                  "z": -0.009261898696422577
                },
                "index0": {
                  "x": 0.22358083724975586,
                  "y": 1.6601762771606445,
                  "z": -0.05825934559106827
                },
                "middle0": {
                  "x": 0.25178062915802,
                  "y": 1.6710351705551147,
                  "z": -0.08154134452342987
                },
                "ring0": {
                  "x": 0.27269572019577026,
                  "y": 1.6559367179870605,
                  "z": -0.09689837694168091
                },
                "pinky0": {
                  "x": 0.29833507537841797,
                  "y": 1.6225124597549438,
                  "z": -0.1077619269490242
                },
                "thumb1": {
                  "x": 0.22622835636138916,
                  "y": 1.5223615169525146,
                  "z": 0.0468185730278492
                },
                "index1": {
                  "x": 0.25702783465385437,
                  "y": 1.4868106842041016,
                  "z": -0.024649791419506073
                },
                "middle1": {
                  "x": 0.261111319065094,
                  "y": 1.4795114994049072,
                  "z": -0.03399169445037842
                },
                "ring1": {
                  "x": 0.27534183859825134,
                  "y": 1.4691376686096191,
                  "z": -0.03192858397960663
                },
                "pinky1": {
                  "x": 0.2986491918563843,
                  "y": 1.4714012145996094,
                  "z": -0.026189632713794708
                }
              },
              {
                "thumb0": {
                  "x": 0.18639817833900452,
                  "y": 1.5521522760391235,
                  "z": 0.013893164694309235
                },
                "index0": {
                  "x": 0.2438269853591919,
                  "y": 1.6361640691757202,
                  "z": -0.02704860270023346
                },
                "middle0": {
                  "x": 0.27103179693222046,
                  "y": 1.6438323259353638,
                  "z": -0.04708299785852432
                },
                "ring0": {
                  "x": 0.2926996350288391,
                  "y": 1.6249533891677856,
                  "z": -0.050955839455127716
                },
                "pinky0": {
                  "x": 0.3196237087249756,
                  "y": 1.586335301399231,
                  "z": -0.0630713626742363
                },
                "thumb1": {
                  "x": 0.21728837490081787,
                  "y": 1.565741777420044,
                  "z": 0.026680007576942444
                },
                "index1": {
                  "x": 0.2596169710159302,
                  "y": 1.5087336301803589,
                  "z": -0.022617168724536896
                },
                "middle1": {
                  "x": 0.26524296402931213,
                  "y": 1.5008403062820435,
                  "z": -0.02972601354122162
                },
                "ring1": {
                  "x": 0.27861228585243225,
                  "y": 1.501319169998169,
                  "z": -0.026983417570590973
                },
                "pinky1": {
                  "x": 0.2963767945766449,
                  "y": 1.5120748281478882,
                  "z": -0.02328769862651825
                }
              },
              {
                "thumb0": {
                  "x": 0.19090048968791962,
                  "y": 1.551560401916504,
                  "z": 0.008266843855381012
                },
                "index0": {
                  "x": 0.24127016961574554,
                  "y": 1.64455246925354,
                  "z": -0.03838852792978287
                },
                "middle0": {
                  "x": 0.2775168716907501,
                  "y": 1.6517183780670166,
                  "z": -0.05970703810453415
                },
                "ring0": {
                  "x": 0.3031783998012543,
                  "y": 1.6328716278076172,
                  "z": -0.07041143625974655
                },
                "pinky0": {
                  "x": 0.32741472125053406,
                  "y": 1.590747594833374,
                  "z": -0.08250606060028076
                },
                "thumb1": {
                  "x": 0.23007196187973022,
                  "y": 1.6041549444198608,
                  "z": 0.04510302096605301
                },
                "index1": {
                  "x": 0.26771530508995056,
                  "y": 1.6594083309173584,
                  "z": -0.014567002654075623
                },
                "middle1": {
                  "x": 0.2785261273384094,
                  "y": 1.6677857637405396,
                  "z": -0.025607898831367493
                },
                "ring1": {
                  "x": 0.29780280590057373,
                  "y": 1.660178542137146,
                  "z": -0.028335660696029663
                },
                "pinky1": {
                  "x": 0.32234472036361694,
                  "y": 1.6352818012237549,
                  "z": -0.02806122601032257
                }
              },
              {
                "thumb0": {
                  "x": 0.18647800385951996,
                  "y": 1.5543485879898071,
                  "z": 0.003969267010688782
                },
                "index0": {
                  "x": 0.23939581215381622,
                  "y": 1.6457351446151733,
                  "z": -0.03969128429889679
                },
                "middle0": {
                  "x": 0.27451014518737793,
                  "y": 1.6531352996826172,
                  "z": -0.06028801202774048
                },
                "ring0": {
                  "x": 0.29754117131233215,
                  "y": 1.6357803344726562,
                  "z": -0.07423710823059082
                },
                "pinky0": {
                  "x": 0.32109203934669495,
                  "y": 1.5970630645751953,
                  "z": -0.08471282571554184
                },
                "thumb1": {
                  "x": 0.19854511320590973,
                  "y": 1.5284963846206665,
                  "z": 0.02059086412191391
                },
                "index1": {
                  "x": 0.2403496950864792,
                  "y": 1.5933772325515747,
                  "z": -0.03484288603067398
                },
                "middle1": {
                  "x": 0.2595456540584564,
                  "y": 1.6002637147903442,
                  "z": -0.047519929707050323
                },
                "ring1": {
                  "x": 0.2841816246509552,
                  "y": 1.5822830200195312,
                  "z": -0.055638402700424194
                },
                "pinky1": {
                  "x": 0.31421712040901184,
                  "y": 1.5467798709869385,
                  "z": -0.05737828463315964
                }
              },
              {
                "thumb0": {
                  "x": 0.17917343974113464,
                  "y": 1.565843105316162,
                  "z": -0.007645711302757263
                },
                "index0": {
                  "x": 0.23236390948295593,
                  "y": 1.6556979417800903,
                  "z": -0.058768130838871
                },
                "middle0": {
                  "x": 0.2656879127025604,
                  "y": 1.662017583847046,
                  "z": -0.08117405325174332
                },
                "ring0": {
                  "x": 0.28556013107299805,
                  "y": 1.6434845924377441,
                  "z": -0.09997104853391647
                },
                "pinky0": {
                  "x": 0.3101300299167633,
                  "y": 1.6037330627441406,
                  "z": -0.11236593127250671
                },
                "thumb1": {
                  "x": 0.2107400894165039,
                  "y": 1.4744253158569336,
                  "z": 0.044992487877607346
                },
                "index1": {
                  "x": 0.23861701786518097,
                  "y": 1.4926449060440063,
                  "z": -0.036982372403144836
                },
                "middle1": {
                  "x": 0.24927100539207458,
                  "y": 1.4980990886688232,
                  "z": -0.049216143786907196
                },
                "ring1": {
                  "x": 0.27142465114593506,
                  "y": 1.4774287939071655,
                  "z": -0.05204061418771744
                },
                "pinky1": {
                  "x": 0.30675145983695984,
                  "y": 1.4570081233978271,
                  "z": -0.04024825990200043
                }
              },
              {
                "thumb0": {
                  "x": 0.18275755643844604,
                  "y": 1.5573176145553589,
                  "z": -0.0022888779640197754
                },
                "index0": {
                  "x": 0.2308729588985443,
                  "y": 1.6513073444366455,
                  "z": -0.056335218250751495
                },
                "middle0": {
                  "x": 0.26168292760849,
                  "y": 1.6590732336044312,
                  "z": -0.07917986065149307
                },
                "ring0": {
                  "x": 0.28235897421836853,
                  "y": 1.6416147947311401,
                  "z": -0.09716284275054932
                },
                "pinky0": {
                  "x": 0.30901244282722473,
                  "y": 1.6029020547866821,
                  "z": -0.11018965393304825
                },
                "thumb1": {
                  "x": 0.2223430573940277,
                  "y": 1.531523585319519,
                  "z": 0.034855782985687256
                },
                "index1": {
                  "x": 0.25676289200782776,
                  "y": 1.486830234527588,
                  "z": -0.027677729725837708
                },
                "middle1": {
                  "x": 0.2620064616203308,
                  "y": 1.4804695844650269,
                  "z": -0.03540226072072983
                },
                "ring1": {
                  "x": 0.27482056617736816,
                  "y": 1.4768669605255127,
                  "z": -0.03597767651081085
                },
                "pinky1": {
                  "x": 0.29557499289512634,
                  "y": 1.483180046081543,
                  "z": -0.030861683189868927
                }
              },
              {
                "thumb0": {
                  "x": 0.1825299859046936,
                  "y": 1.5557684898376465,
                  "z": 0.0008450374007225037
                },
                "index0": {
                  "x": 0.23409350216388702,
                  "y": 1.6475679874420166,
                  "z": -0.055218033492565155
                },
                "middle0": {
                  "x": 0.2665145695209503,
                  "y": 1.6536064147949219,
                  "z": -0.07390865683555603
                },
                "ring0": {
                  "x": 0.29157185554504395,
                  "y": 1.6344084739685059,
                  "z": -0.08431868255138397
                },
                "pinky0": {
                  "x": 0.3184308111667633,
                  "y": 1.5925873517990112,
                  "z": -0.09450022131204605
                },
                "thumb1": {
                  "x": 0.2231806367635727,
                  "y": 1.5957406759262085,
                  "z": 0.03134472668170929
                },
                "index1": {
                  "x": 0.2541583180427551,
                  "y": 1.612555980682373,
                  "z": -0.042048849165439606
                },
                "middle1": {
                  "x": 0.26137271523475647,
                  "y": 1.6127111911773682,
                  "z": -0.05592777580022812
                },
                "ring1": {
                  "x": 0.27657708525657654,
                  "y": 1.6077519655227661,
                  "z": -0.0596274808049202
                },
                "pinky1": {
                  "x": 0.29525429010391235,
                  "y": 1.5873647928237915,
                  "z": -0.05034925788640976
                }
              },
              {
                "thumb0": {
                  "x": 0.1833091527223587,
                  "y": 1.5522394180297852,
                  "z": 0.0023133307695388794
                },
                "index0": {
                  "x": 0.23124253749847412,
                  "y": 1.645745873451233,
                  "z": -0.048950791358947754
                },
                "middle0": {
                  "x": 0.2627699077129364,
                  "y": 1.6533926725387573,
                  "z": -0.0677238404750824
                },
                "ring0": {
                  "x": 0.286371111869812,
                  "y": 1.6358600854873657,
                  "z": -0.08117686957120895
                },
                "pinky0": {
                  "x": 0.31345677375793457,
                  "y": 1.5959547758102417,
                  "z": -0.09105101227760315
                },
                "thumb1": {
                  "x": 0.2068464457988739,
                  "y": 1.5927398204803467,
                  "z": 0.03312283754348755
                },
                "index1": {
                  "x": 0.24178721010684967,
                  "y": 1.6563142538070679,
                  "z": -0.02009674906730652
                },
                "middle1": {
                  "x": 0.2590279281139374,
                  "y": 1.664806604385376,
                  "z": -0.03371329605579376
                },
                "ring1": {
                  "x": 0.2843257486820221,
                  "y": 1.6507232189178467,
                  "z": -0.042077869176864624
                },
                "pinky1": {
                  "x": 0.311991423368454,
                  "y": 1.62030827999115,
                  "z": -0.044524453580379486
                }
              },
              {
                "thumb0": {
                  "x": 0.1702594757080078,
                  "y": 1.5681161880493164,
                  "z": -0.005442820489406586
                },
                "index0": {
                  "x": 0.21966734528541565,
                  "y": 1.6605371236801147,
                  "z": -0.054411545395851135
                },
                "middle0": {
                  "x": 0.2533819079399109,
                  "y": 1.6681245565414429,
                  "z": -0.07101251184940338
                },
                "ring0": {
                  "x": 0.2764614522457123,
                  "y": 1.6512138843536377,
                  "z": -0.08621613681316376
                },
                "pinky0": {
                  "x": 0.30326351523399353,
                  "y": 1.6125608682632446,
                  "z": -0.09671393036842346
                },
                "thumb1": {
                  "x": 0.20935654640197754,
                  "y": 1.516121506690979,
                  "z": 0.03804493695497513
                },
                "index1": {
                  "x": 0.23318350315093994,
                  "y": 1.5418345928192139,
                  "z": -0.042542941868305206
                },
                "middle1": {
                  "x": 0.24284647405147552,
                  "y": 1.5465933084487915,
                  "z": -0.055899374186992645
                },
                "ring1": {
                  "x": 0.2658819556236267,
                  "y": 1.5319937467575073,
                  "z": -0.06110253930091858
                },
                "pinky1": {
                  "x": 0.3002793490886688,
                  "y": 1.509514570236206,
                  "z": -0.053636178374290466
                }
              },
              {
                "thumb0": {
                  "x": 0.17742040753364563,
                  "y": 1.5614955425262451,
                  "z": -0.002999834716320038
                },
                "index0": {
                  "x": 0.21958008408546448,
                  "y": 1.657448410987854,
                  "z": -0.05431953817605972
                },
                "middle0": {
                  "x": 0.24757762253284454,
                  "y": 1.6672314405441284,
                  "z": -0.07579021155834198
                },
                "ring0": {
                  "x": 0.2677507698535919,
                  "y": 1.6521937847137451,
                  "z": -0.09318966418504715
                },
                "pinky0": {
                  "x": 0.2949235439300537,
                  "y": 1.6159412860870361,
                  "z": -0.10928484797477722
                },
                "thumb1": {
                  "x": 0.21746742725372314,
                  "y": 1.5187784433364868,
                  "z": 0.04247967153787613
                },
                "index1": {
                  "x": 0.25574731826782227,
                  "y": 1.471002459526062,
                  "z": -0.012831069529056549
                },
                "middle1": {
                  "x": 0.26101037859916687,
                  "y": 1.4641698598861694,
                  "z": -0.0213472843170166
                },
                "ring1": {
                  "x": 0.27341556549072266,
                  "y": 1.4728418588638306,
                  "z": -0.030614733695983887
                },
                "pinky1": {
                  "x": 0.29605910181999207,
                  "y": 1.4848909378051758,
                  "z": -0.026094496250152588
                }
              },
              {
                "thumb0": {
                  "x": 0.178889200091362,
                  "y": 1.553977370262146,
                  "z": 0.004435360431671143
                },
                "index0": {
                  "x": 0.23018786311149597,
                  "y": 1.6460018157958984,
                  "z": -0.04494912922382355
                },
                "middle0": {
                  "x": 0.26355689764022827,
                  "y": 1.6534721851348877,
                  "z": -0.06337029486894608
                },
                "ring0": {
                  "x": 0.28808140754699707,
                  "y": 1.6353954076766968,
                  "z": -0.07429235428571701
                },
                "pinky0": {
                  "x": 0.3166753947734833,
                  "y": 1.5953786373138428,
                  "z": -0.08222401887178421
                },
                "thumb1": {
                  "x": 0.21841596066951752,
                  "y": 1.5877926349639893,
                  "z": 0.03475671261548996
                },
                "index1": {
                  "x": 0.25162485241889954,
                  "y": 1.555336833000183,
                  "z": -0.0349017009139061
                },
                "middle1": {
                  "x": 0.25776010751724243,
                  "y": 1.5571626424789429,
                  "z": -0.045291900634765625
                },
                "ring1": {
                  "x": 0.2716883718967438,
                  "y": 1.5588760375976562,
                  "z": -0.04705949127674103
                },
                "pinky1": {
                  "x": 0.29343917965888977,
                  "y": 1.5654643774032593,
                  "z": -0.03855844587087631
                }
              }
            ],
            "words": "color"
          },
          {
            "key": "f15012d8-a09f-c3b7-90a7-66f796e29fa6",
            "gesture": [
              {
                "thumb0": {
                  "x": 0.20292213559150696,
                  "y": 1.505929946899414,
                  "z": -0.01564660668373108
                },
                "index0": {
                  "x": 0.22421345114707947,
                  "y": 1.597129464149475,
                  "z": -0.058584555983543396
                },
                "middle0": {
                  "x": 0.2513352930545807,
                  "y": 1.6087032556533813,
                  "z": -0.08110639452934265
                },
                "ring0": {
                  "x": 0.2709800899028778,
                  "y": 1.5939964056015015,
                  "z": -0.10132613033056259
                },
                "pinky0": {
                  "x": 0.2938576340675354,
                  "y": 1.558943271636963,
                  "z": -0.1261397749185562
                },
                "thumb1": {
                  "x": 0.29578566551208496,
                  "y": 1.5109894275665283,
                  "z": 0.1010892316699028
                },
                "index1": {
                  "x": 0.3561440706253052,
                  "y": 1.5656108856201172,
                  "z": 0.05770096555352211
                },
                "middle1": {
                  "x": 0.36228352785110474,
                  "y": 1.533589482307434,
                  "z": 0.01975136250257492
                },
                "ring1": {
                  "x": 0.3754388988018036,
                  "y": 1.5087807178497314,
                  "z": 0.012824878096580505
                },
                "pinky1": {
                  "x": 0.3897589445114136,
                  "y": 1.478826880455017,
                  "z": 0.023177631199359894
                }
              },
              {
                "thumb0": {
                  "x": 0.20947137475013733,
                  "y": 1.5032641887664795,
                  "z": -0.01302432268857956
                },
                "index0": {
                  "x": 0.22889067232608795,
                  "y": 1.594346046447754,
                  "z": -0.05495010316371918
                },
                "middle0": {
                  "x": 0.25377413630485535,
                  "y": 1.6068480014801025,
                  "z": -0.07795064151287079
                },
                "ring0": {
                  "x": 0.27430596947669983,
                  "y": 1.592129111289978,
                  "z": -0.09887149184942245
                },
                "pinky0": {
                  "x": 0.2912905812263489,
                  "y": 1.5595126152038574,
                  "z": -0.13158434629440308
                },
                "thumb1": {
                  "x": 0.2882694602012634,
                  "y": 1.5126514434814453,
                  "z": 0.10798238217830658
                },
                "index1": {
                  "x": 0.3451879322528839,
                  "y": 1.5681029558181763,
                  "z": 0.06061191484332085
                },
                "middle1": {
                  "x": 0.36318355798721313,
                  "y": 1.547102928161621,
                  "z": 0.02497079223394394
                },
                "ring1": {
                  "x": 0.38214150071144104,
                  "y": 1.5244067907333374,
                  "z": 0.018637947738170624
                },
                "pinky1": {
                  "x": 0.39336732029914856,
                  "y": 1.487654685974121,
                  "z": 0.02632053941488266
                }
              },
              {
                "thumb0": {
                  "x": 0.20879703760147095,
                  "y": 1.5021854639053345,
                  "z": -0.012148931622505188
                },
                "index0": {
                  "x": 0.22962439060211182,
                  "y": 1.5929497480392456,
                  "z": -0.054354116320610046
                },
                "middle0": {
                  "x": 0.25449222326278687,
                  "y": 1.6052956581115723,
                  "z": -0.07710446417331696
                },
                "ring0": {
                  "x": 0.2754664421081543,
                  "y": 1.5901505947113037,
                  "z": -0.09742867201566696
                },
                "pinky0": {
                  "x": 0.2923683226108551,
                  "y": 1.5568350553512573,
                  "z": -0.1323632001876831
                },
                "thumb1": {
                  "x": 0.28527966141700745,
                  "y": 1.5142998695373535,
                  "z": 0.10769928991794586
                },
                "index1": {
                  "x": 0.34069719910621643,
                  "y": 1.5701656341552734,
                  "z": 0.05909345671534538
                },
                "middle1": {
                  "x": 0.35971903800964355,
                  "y": 1.5524355173110962,
                  "z": 0.02429812401533127
                },
                "ring1": {
                  "x": 0.37979182600975037,
                  "y": 1.5306142568588257,
                  "z": 0.017257221043109894
                },
                "pinky1": {
                  "x": 0.3917069435119629,
                  "y": 1.4926539659500122,
                  "z": 0.022196568548679352
                }
              },
              {
                "thumb0": {
                  "x": 0.198736310005188,
                  "y": 1.5156161785125732,
                  "z": -0.011648744344711304
                },
                "index0": {
                  "x": 0.23868316411972046,
                  "y": 1.6006826162338257,
                  "z": -0.055166713893413544
                },
                "middle0": {
                  "x": 0.26835897564888,
                  "y": 1.6086910963058472,
                  "z": -0.07362928986549377
                },
                "ring0": {
                  "x": 0.2908807396888733,
                  "y": 1.5898730754852295,
                  "z": -0.08983834087848663
                },
                "pinky0": {
                  "x": 0.31302109360694885,
                  "y": 1.5507827997207642,
                  "z": -0.1146853044629097
                },
                "thumb1": {
                  "x": 0.29463765025138855,
                  "y": 1.5252326726913452,
                  "z": 0.08940272033214569
                },
                "index1": {
                  "x": 0.35235124826431274,
                  "y": 1.5510262250900269,
                  "z": 0.01256224513053894
                },
                "middle1": {
                  "x": 0.31571322679519653,
                  "y": 1.4642934799194336,
                  "z": 0.005228869616985321
                },
                "ring1": {
                  "x": 0.3265403211116791,
                  "y": 1.4483520984649658,
                  "z": 0.002239808440208435
                },
                "pinky1": {
                  "x": 0.35450369119644165,
                  "y": 1.4413983821868896,
                  "z": 0.006338156759738922
                }
              },
              {
                "thumb0": {
                  "x": 0.18958771228790283,
                  "y": 1.5240534543991089,
                  "z": -0.0239434614777565
                },
                "index0": {
                  "x": 0.23988744616508484,
                  "y": 1.608134150505066,
                  "z": -0.05882023274898529
                },
                "middle0": {
                  "x": 0.27198708057403564,
                  "y": 1.6160402297973633,
                  "z": -0.07593657821416855
                },
                "ring0": {
                  "x": 0.2959885001182556,
                  "y": 1.5973974466323853,
                  "z": -0.08768819272518158
                },
                "pinky0": {
                  "x": 0.31851926445961,
                  "y": 1.5601832866668701,
                  "z": -0.1084102988243103
                },
                "thumb1": {
                  "x": 0.32182231545448303,
                  "y": 1.5240809917449951,
                  "z": 0.07282659411430359
                },
                "index1": {
                  "x": 0.34749555587768555,
                  "y": 1.5037322044372559,
                  "z": -0.02795197069644928
                },
                "middle1": {
                  "x": 0.3112991154193878,
                  "y": 1.4239411354064941,
                  "z": 0.04320027679204941
                },
                "ring1": {
                  "x": 0.3203065097332001,
                  "y": 1.4146519899368286,
                  "z": 0.05026114732027054
                },
                "pinky1": {
                  "x": 0.3355669677257538,
                  "y": 1.4087284803390503,
                  "z": 0.038330305367708206
                }
              },
              {
                "thumb0": {
                  "x": 0.18529020249843597,
                  "y": 1.5189276933670044,
                  "z": -0.025501586496829987
                },
                "index0": {
                  "x": 0.2412545084953308,
                  "y": 1.6031137704849243,
                  "z": -0.0554024800658226
                },
                "middle0": {
                  "x": 0.2743300795555115,
                  "y": 1.610621690750122,
                  "z": -0.07163001596927643
                },
                "ring0": {
                  "x": 0.2979528307914734,
                  "y": 1.5931167602539062,
                  "z": -0.08466039597988129
                },
                "pinky0": {
                  "x": 0.31850239634513855,
                  "y": 1.5568358898162842,
                  "z": -0.1027541235089302
                },
                "thumb1": {
                  "x": 0.34877023100852966,
                  "y": 1.51624596118927,
                  "z": 0.06583331525325775
                },
                "index1": {
                  "x": 0.3056786060333252,
                  "y": 1.4577312469482422,
                  "z": -0.030573830008506775
                },
                "middle1": {
                  "x": 0.3138161301612854,
                  "y": 1.4207450151443481,
                  "z": 0.06828226149082184
                },
                "ring1": {
                  "x": 0.3332207202911377,
                  "y": 1.4112671613693237,
                  "z": 0.06843549013137817
                },
                "pinky1": {
                  "x": 0.3362196385860443,
                  "y": 1.3993687629699707,
                  "z": 0.05985189974308014
                }
              },
              {
                "thumb0": {
                  "x": 0.20036472380161285,
                  "y": 1.5277705192565918,
                  "z": -0.031101010739803314
                },
                "index0": {
                  "x": 0.28539836406707764,
                  "y": 1.5827527046203613,
                  "z": -0.06125045567750931
                },
                "middle0": {
                  "x": 0.30995503067970276,
                  "y": 1.584114909172058,
                  "z": -0.07053054869174957
                },
                "ring0": {
                  "x": 0.3237137198448181,
                  "y": 1.5671933889389038,
                  "z": -0.08438611030578613
                },
                "pinky0": {
                  "x": 0.33110153675079346,
                  "y": 1.533700704574585,
                  "z": -0.10165895521640778
                },
                "thumb1": {
                  "x": 0.3469509780406952,
                  "y": 1.5160084962844849,
                  "z": 0.07222411036491394
                },
                "index1": {
                  "x": 0.31034567952156067,
                  "y": 1.4480793476104736,
                  "z": -0.028294041752815247
                },
                "middle1": {
                  "x": 0.31742966175079346,
                  "y": 1.418057918548584,
                  "z": 0.07355425506830215
                },
                "ring1": {
                  "x": 0.3370739817619324,
                  "y": 1.410081386566162,
                  "z": 0.0744343176484108
                },
                "pinky1": {
                  "x": 0.3409668803215027,
                  "y": 1.397346019744873,
                  "z": 0.0658852756023407
                }
              },
              {
                "thumb0": {
                  "x": 0.3004187345504761,
                  "y": 1.5039117336273193,
                  "z": -0.002974271774291992
                },
                "index0": {
                  "x": 0.34091493487358093,
                  "y": 1.5093203783035278,
                  "z": -0.021861672401428223
                },
                "middle0": {
                  "x": 0.38244664669036865,
                  "y": 1.5238747596740723,
                  "z": -0.05297607183456421
                },
                "ring0": {
                  "x": 0.38387176394462585,
                  "y": 1.504677176475525,
                  "z": -0.06835385411977768
                },
                "pinky0": {
                  "x": 0.3674512505531311,
                  "y": 1.4755229949951172,
                  "z": -0.07261783629655838
                },
                "thumb1": {
                  "x": 0.3436773121356964,
                  "y": 1.5113393068313599,
                  "z": 0.06970960646867752
                },
                "index1": {
                  "x": 0.3084755837917328,
                  "y": 1.4416791200637817,
                  "z": -0.03129720687866211
                },
                "middle1": {
                  "x": 0.3167683184146881,
                  "y": 1.4134349822998047,
                  "z": 0.06735692918300629
                },
                "ring1": {
                  "x": 0.33468151092529297,
                  "y": 1.404865026473999,
                  "z": 0.06901568919420242
                },
                "pinky1": {
                  "x": 0.3379935324192047,
                  "y": 1.3917782306671143,
                  "z": 0.06227684020996094
                }
              },
              {
                "thumb0": {
                  "x": 0.3481825888156891,
                  "y": 1.475579857826233,
                  "z": 0.03502862900495529
                },
                "index0": {
                  "x": 0.3582989275455475,
                  "y": 1.5067977905273438,
                  "z": 0.02543337643146515
                },
                "middle0": {
                  "x": 0.37075042724609375,
                  "y": 1.582316279411316,
                  "z": -0.0014089494943618774
                },
                "ring0": {
                  "x": 0.3697866201400757,
                  "y": 1.5787687301635742,
                  "z": -0.028269484639167786
                },
                "pinky0": {
                  "x": 0.37118589878082275,
                  "y": 1.548451542854309,
                  "z": -0.053773149847984314
                },
                "thumb1": {
                  "x": 0.3452426493167877,
                  "y": 1.5123004913330078,
                  "z": 0.06612487882375717
                },
                "index1": {
                  "x": 0.29523682594299316,
                  "y": 1.4312466382980347,
                  "z": 0.01306799054145813
                },
                "middle1": {
                  "x": 0.31022167205810547,
                  "y": 1.4170186519622803,
                  "z": 0.05712825432419777
                },
                "ring1": {
                  "x": 0.32405832409858704,
                  "y": 1.406306266784668,
                  "z": 0.06053441762924194
                },
                "pinky1": {
                  "x": 0.33007562160491943,
                  "y": 1.3932287693023682,
                  "z": 0.05798214301466942
                }
              },
              {
                "thumb0": {
                  "x": 0.32743582129478455,
                  "y": 1.4717434644699097,
                  "z": 0.012352354824543
                },
                "index0": {
                  "x": 0.3335479497909546,
                  "y": 1.505592942237854,
                  "z": 0.010687701404094696
                },
                "middle0": {
                  "x": 0.3290756046772003,
                  "y": 1.5949440002441406,
                  "z": -0.009809218347072601
                },
                "ring0": {
                  "x": 0.33239662647247314,
                  "y": 1.5910568237304688,
                  "z": -0.040531814098358154
                },
                "pinky0": {
                  "x": 0.3391290009021759,
                  "y": 1.5662953853607178,
                  "z": -0.07921487838029861
                },
                "thumb1": {
                  "x": 0.3489176630973816,
                  "y": 1.5164381265640259,
                  "z": 0.07501943409442902
                },
                "index1": {
                  "x": 0.2935793399810791,
                  "y": 1.4369138479232788,
                  "z": 0.013251371681690216
                },
                "middle1": {
                  "x": 0.3128927946090698,
                  "y": 1.4211195707321167,
                  "z": 0.06303636729717255
                },
                "ring1": {
                  "x": 0.3228936195373535,
                  "y": 1.4099003076553345,
                  "z": 0.06608156859874725
                },
                "pinky1": {
                  "x": 0.3303743898868561,
                  "y": 1.3969223499298096,
                  "z": 0.06360944360494614
                }
              },
              {
                "thumb0": {
                  "x": 0.2773001790046692,
                  "y": 1.4921214580535889,
                  "z": -0.07720675319433212
                },
                "index0": {
                  "x": 0.2773834466934204,
                  "y": 1.5287673473358154,
                  "z": -0.08002540469169617
                },
                "middle0": {
                  "x": 0.2712540924549103,
                  "y": 1.604839563369751,
                  "z": -0.1177668496966362
                },
                "ring0": {
                  "x": 0.2762216031551361,
                  "y": 1.59523344039917,
                  "z": -0.14576077461242676
                },
                "pinky0": {
                  "x": 0.2858143746852875,
                  "y": 1.5638988018035889,
                  "z": -0.17271894216537476
                },
                "thumb1": {
                  "x": 0.35750773549079895,
                  "y": 1.5149654150009155,
                  "z": 0.08620833605527878
                },
                "index1": {
                  "x": 0.3188874423503876,
                  "y": 1.4498257637023926,
                  "z": -0.021462537348270416
                },
                "middle1": {
                  "x": 0.31643638014793396,
                  "y": 1.418792724609375,
                  "z": 0.0802047848701477
                },
                "ring1": {
                  "x": 0.3356463313102722,
                  "y": 1.4101454019546509,
                  "z": 0.08439520746469498
                },
                "pinky1": {
                  "x": 0.33725446462631226,
                  "y": 1.3958823680877686,
                  "z": 0.07813731580972672
                }
              },
              {
                "thumb0": {
                  "x": 0.28287285566329956,
                  "y": 1.4963281154632568,
                  "z": -0.05711159110069275
                },
                "index0": {
                  "x": 0.2828826606273651,
                  "y": 1.5351283550262451,
                  "z": -0.05997291952371597
                },
                "middle0": {
                  "x": 0.27762845158576965,
                  "y": 1.6093119382858276,
                  "z": -0.09980212152004242
                },
                "ring0": {
                  "x": 0.2842135429382324,
                  "y": 1.5984222888946533,
                  "z": -0.12746724486351013
                },
                "pinky0": {
                  "x": 0.29392483830451965,
                  "y": 1.5667335987091064,
                  "z": -0.15542161464691162
                },
                "thumb1": {
                  "x": 0.3555721342563629,
                  "y": 1.515159010887146,
                  "z": 0.08732885867357254
                },
                "index1": {
                  "x": 0.3178236484527588,
                  "y": 1.4500584602355957,
                  "z": -0.020030252635478973
                },
                "middle1": {
                  "x": 0.31846827268600464,
                  "y": 1.4187231063842773,
                  "z": 0.08306780457496643
                },
                "ring1": {
                  "x": 0.3372824788093567,
                  "y": 1.409180998802185,
                  "z": 0.08443982899188995
                },
                "pinky1": {
                  "x": 0.3413279354572296,
                  "y": 1.395104169845581,
                  "z": 0.07447686046361923
                }
              },
              {
                "thumb0": {
                  "x": 0.3335476815700531,
                  "y": 1.4798634052276611,
                  "z": 0.02606206387281418
                },
                "index0": {
                  "x": 0.33882758021354675,
                  "y": 1.5217021703720093,
                  "z": 0.028167754411697388
                },
                "middle0": {
                  "x": 0.34262776374816895,
                  "y": 1.59912109375,
                  "z": -0.006583839654922485
                },
                "ring0": {
                  "x": 0.35256609320640564,
                  "y": 1.5891979932785034,
                  "z": -0.0341012105345726
                },
                "pinky0": {
                  "x": 0.36439281702041626,
                  "y": 1.5582886934280396,
                  "z": -0.06255396455526352
                },
                "thumb1": {
                  "x": 0.3445117473602295,
                  "y": 1.5189642906188965,
                  "z": 0.08323723822832108
                },
                "index1": {
                  "x": 0.30353501439094543,
                  "y": 1.4507465362548828,
                  "z": -0.018196485936641693
                },
                "middle1": {
                  "x": 0.3082323968410492,
                  "y": 1.421751856803894,
                  "z": 0.07315529137849808
                },
                "ring1": {
                  "x": 0.325920969247818,
                  "y": 1.4118984937667847,
                  "z": 0.07473284006118774
                },
                "pinky1": {
                  "x": 0.3305501639842987,
                  "y": 1.398922324180603,
                  "z": 0.06971780955791473
                }
              },
              {
                "thumb0": {
                  "x": 0.3313787281513214,
                  "y": 1.4682023525238037,
                  "z": 0.032747842371463776
                },
                "index0": {
                  "x": 0.340032696723938,
                  "y": 1.5108107328414917,
                  "z": 0.035508036613464355
                },
                "middle0": {
                  "x": 0.3446633815765381,
                  "y": 1.5889681577682495,
                  "z": 0.004668503999710083
                },
                "ring0": {
                  "x": 0.35568779706954956,
                  "y": 1.5815304517745972,
                  "z": -0.023294538259506226
                },
                "pinky0": {
                  "x": 0.37034422159194946,
                  "y": 1.5511960983276367,
                  "z": -0.05169094353914261
                },
                "thumb1": {
                  "x": 0.34506624937057495,
                  "y": 1.519547462463379,
                  "z": 0.08074980974197388
                },
                "index1": {
                  "x": 0.2982342541217804,
                  "y": 1.4413338899612427,
                  "z": 0.012835845351219177
                },
                "middle1": {
                  "x": 0.31009647250175476,
                  "y": 1.4231641292572021,
                  "z": 0.0673564225435257
                },
                "ring1": {
                  "x": 0.32244306802749634,
                  "y": 1.4122161865234375,
                  "z": 0.07102760672569275
                },
                "pinky1": {
                  "x": 0.3294789493083954,
                  "y": 1.3991199731826782,
                  "z": 0.06689988076686859
                }
              },
              {
                "thumb0": {
                  "x": 0.3194010853767395,
                  "y": 1.4706014394760132,
                  "z": 0.019195042550563812
                },
                "index0": {
                  "x": 0.3291913866996765,
                  "y": 1.5143965482711792,
                  "z": 0.02125702053308487
                },
                "middle0": {
                  "x": 0.3343130648136139,
                  "y": 1.5893528461456299,
                  "z": -0.011520743370056152
                },
                "ring0": {
                  "x": 0.3451748490333557,
                  "y": 1.5812972784042358,
                  "z": -0.039280086755752563
                },
                "pinky0": {
                  "x": 0.3596763014793396,
                  "y": 1.550218105316162,
                  "z": -0.06648316234350204
                },
                "thumb1": {
                  "x": 0.3455146849155426,
                  "y": 1.5179243087768555,
                  "z": 0.08082185685634613
                },
                "index1": {
                  "x": 0.29522302746772766,
                  "y": 1.4452797174453735,
                  "z": 0.005113936960697174
                },
                "middle1": {
                  "x": 0.3125149607658386,
                  "y": 1.4222135543823242,
                  "z": 0.06587612628936768
                },
                "ring1": {
                  "x": 0.3249814510345459,
                  "y": 1.4106043577194214,
                  "z": 0.06598488986492157
                },
                "pinky1": {
                  "x": 0.33039432764053345,
                  "y": 1.3971729278564453,
                  "z": 0.061391059309244156
                }
              },
              {
                "thumb0": {
                  "x": 0.31362348794937134,
                  "y": 1.474871039390564,
                  "z": 0.015445992350578308
                },
                "index0": {
                  "x": 0.3248945474624634,
                  "y": 1.5223792791366577,
                  "z": 0.01692146062850952
                },
                "middle0": {
                  "x": 0.32931575179100037,
                  "y": 1.5907758474349976,
                  "z": -0.017795875668525696
                },
                "ring0": {
                  "x": 0.3405035734176636,
                  "y": 1.5820587873458862,
                  "z": -0.045513227581977844
                },
                "pinky0": {
                  "x": 0.35448330640792847,
                  "y": 1.550430178642273,
                  "z": -0.07225418090820312
                },
                "thumb1": {
                  "x": 0.34417805075645447,
                  "y": 1.519382357597351,
                  "z": 0.07886641472578049
                },
                "index1": {
                  "x": 0.2952418327331543,
                  "y": 1.4454399347305298,
                  "z": 0.0068325623869895935
                },
                "middle1": {
                  "x": 0.3110772669315338,
                  "y": 1.4235531091690063,
                  "z": 0.06368931382894516
                },
                "ring1": {
                  "x": 0.3217456042766571,
                  "y": 1.4123692512512207,
                  "z": 0.06798387318849564
                },
                "pinky1": {
                  "x": 0.3291066884994507,
                  "y": 1.3998377323150635,
                  "z": 0.0668332427740097
                }
              },
              {
                "thumb0": {
                  "x": 0.30331048369407654,
                  "y": 1.4763251543045044,
                  "z": 0.012854218482971191
                },
                "index0": {
                  "x": 0.32031139731407166,
                  "y": 1.5369014739990234,
                  "z": 0.005218096077442169
                },
                "middle0": {
                  "x": 0.3307085931301117,
                  "y": 1.5849862098693848,
                  "z": -0.029091931879520416
                },
                "ring0": {
                  "x": 0.34309807419776917,
                  "y": 1.5698084831237793,
                  "z": -0.052080877125263214
                },
                "pinky0": {
                  "x": 0.35398638248443604,
                  "y": 1.5368119478225708,
                  "z": -0.07704289257526398
                },
                "thumb1": {
                  "x": 0.34194377064704895,
                  "y": 1.523946762084961,
                  "z": 0.08299210667610168
                },
                "index1": {
                  "x": 0.2944856584072113,
                  "y": 1.4502286911010742,
                  "z": 0.004305332899093628
                },
                "middle1": {
                  "x": 0.3081960380077362,
                  "y": 1.427539348602295,
                  "z": 0.0658557116985321
                },
                "ring1": {
                  "x": 0.31884706020355225,
                  "y": 1.4164589643478394,
                  "z": 0.0704914778470993
                },
                "pinky1": {
                  "x": 0.3251242935657501,
                  "y": 1.4039801359176636,
                  "z": 0.06861536204814911
                }
              },
              {
                "thumb0": {
                  "x": 0.294933021068573,
                  "y": 1.4769721031188965,
                  "z": 0.006749831140041351
                },
                "index0": {
                  "x": 0.31362804770469666,
                  "y": 1.5529451370239258,
                  "z": -0.01007004827260971
                },
                "middle0": {
                  "x": 0.3286442160606384,
                  "y": 1.5818202495574951,
                  "z": -0.03778497874736786
                },
                "ring0": {
                  "x": 0.34229332208633423,
                  "y": 1.5658931732177734,
                  "z": -0.058772020041942596
                },
                "pinky0": {
                  "x": 0.35247600078582764,
                  "y": 1.5345652103424072,
                  "z": -0.08425202965736389
                },
                "thumb1": {
                  "x": 0.3406986892223358,
                  "y": 1.5269581079483032,
                  "z": 0.08784278482198715
                },
                "index1": {
                  "x": 0.3037254214286804,
                  "y": 1.4693690538406372,
                  "z": -0.013581208884716034
                },
                "middle1": {
                  "x": 0.28781023621559143,
                  "y": 1.4348623752593994,
                  "z": 0.05869176983833313
                },
                "ring1": {
                  "x": 0.3151545524597168,
                  "y": 1.4226927757263184,
                  "z": 0.07129628956317902
                },
                "pinky1": {
                  "x": 0.32260167598724365,
                  "y": 1.4099818468093872,
                  "z": 0.06789617240428925
                }
              },
              {
                "thumb0": {
                  "x": 0.27902737259864807,
                  "y": 1.4788533449172974,
                  "z": 0.005050718784332275
                },
                "index0": {
                  "x": 0.3081863820552826,
                  "y": 1.5613021850585938,
                  "z": -0.024338029325008392
                },
                "middle0": {
                  "x": 0.326412171125412,
                  "y": 1.5759674310684204,
                  "z": -0.04767210781574249
                },
                "ring0": {
                  "x": 0.33902955055236816,
                  "y": 1.5596517324447632,
                  "z": -0.06880819797515869
                },
                "pinky0": {
                  "x": 0.34684184193611145,
                  "y": 1.528662919998169,
                  "z": -0.09542568773031235
                },
                "thumb1": {
                  "x": 0.3303999900817871,
                  "y": 1.5268391370773315,
                  "z": 0.09013708680868149
                },
                "index1": {
                  "x": 0.31994614005088806,
                  "y": 1.4758503437042236,
                  "z": -0.022683925926685333
                },
                "middle1": {
                  "x": 0.29645922780036926,
                  "y": 1.428768277168274,
                  "z": 0.06307634711265564
                },
                "ring1": {
                  "x": 0.31802359223365784,
                  "y": 1.4196927547454834,
                  "z": 0.07197312265634537
                },
                "pinky1": {
                  "x": 0.32849833369255066,
                  "y": 1.4087698459625244,
                  "z": 0.0645020455121994
                }
              },
              {
                "thumb0": {
                  "x": 0.25916752219200134,
                  "y": 1.4913884401321411,
                  "z": -0.0010768920183181763
                },
                "index0": {
                  "x": 0.2910615801811218,
                  "y": 1.5744762420654297,
                  "z": -0.041797660291194916
                },
                "middle0": {
                  "x": 0.3118748962879181,
                  "y": 1.581512212753296,
                  "z": -0.06374341994524002
                },
                "ring0": {
                  "x": 0.3242199122905731,
                  "y": 1.5652669668197632,
                  "z": -0.08612947165966034
                },
                "pinky0": {
                  "x": 0.3324104845523834,
                  "y": 1.5310330390930176,
                  "z": -0.11231590807437897
                },
                "thumb1": {
                  "x": 0.32246124744415283,
                  "y": 1.5284994840621948,
                  "z": 0.09275735169649124
                },
                "index1": {
                  "x": 0.3441181182861328,
                  "y": 1.5097893476486206,
                  "z": -0.01937105506658554
                },
                "middle1": {
                  "x": 0.293869286775589,
                  "y": 1.4337702989578247,
                  "z": 0.033535249531269073
                },
                "ring1": {
                  "x": 0.3016565442085266,
                  "y": 1.421863317489624,
                  "z": 0.035495661199092865
                },
                "pinky1": {
                  "x": 0.3227432072162628,
                  "y": 1.4153273105621338,
                  "z": 0.027000360190868378
                }
              },
              {
                "thumb0": {
                  "x": 0.25070542097091675,
                  "y": 1.4907854795455933,
                  "z": -0.008309721946716309
                },
                "index0": {
                  "x": 0.25837886333465576,
                  "y": 1.5788705348968506,
                  "z": -0.05734327435493469
                },
                "middle0": {
                  "x": 0.2719421684741974,
                  "y": 1.589363932609558,
                  "z": -0.08432835340499878
                },
                "ring0": {
                  "x": 0.28809022903442383,
                  "y": 1.5729073286056519,
                  "z": -0.10725174844264984
                },
                "pinky0": {
                  "x": 0.30103302001953125,
                  "y": 1.5373653173446655,
                  "z": -0.13501513004302979
                },
                "thumb1": {
                  "x": 0.27860572934150696,
                  "y": 1.509932279586792,
                  "z": 0.10728071630001068
                },
                "index1": {
                  "x": 0.33461856842041016,
                  "y": 1.559169888496399,
                  "z": 0.048940226435661316
                },
                "middle1": {
                  "x": 0.3558345139026642,
                  "y": 1.550745964050293,
                  "z": 0.02341867983341217
                },
                "ring1": {
                  "x": 0.3764052093029022,
                  "y": 1.5303261280059814,
                  "z": 0.020127102732658386
                },
                "pinky1": {
                  "x": 0.3941367268562317,
                  "y": 1.4912306070327759,
                  "z": 0.02293020486831665
                }
              }
            ],
            "words": "axis"
          },
          {
            "key": "e603c17a-18cd-3ad0-1504-f1ae4ab54dce",
            "gesture": [
              {
                "thumb0": {
                  "x": 0.22693251073360443,
                  "y": 1.4860378503799438,
                  "z": -0.0268058180809021
                },
                "index0": {
                  "x": 0.20572935044765472,
                  "y": 1.587843656539917,
                  "z": -0.0883219838142395
                },
                "middle0": {
                  "x": 0.23220697045326233,
                  "y": 1.5989638566970825,
                  "z": -0.11840229481458664
                },
                "ring0": {
                  "x": 0.24990330636501312,
                  "y": 1.5818623304367065,
                  "z": -0.14341577887535095
                },
                "pinky0": {
                  "x": 0.26847225427627563,
                  "y": 1.5434893369674683,
                  "z": -0.17289042472839355
                },
                "thumb1": {
                  "x": 0.2618648111820221,
                  "y": 1.487248182296753,
                  "z": 0.06539979577064514
                },
                "index1": {
                  "x": 0.322809636592865,
                  "y": 1.5873695611953735,
                  "z": 0.11177636682987213
                },
                "middle1": {
                  "x": 0.3569197654724121,
                  "y": 1.5950939655303955,
                  "z": 0.09742219001054764
                },
                "ring1": {
                  "x": 0.38240525126457214,
                  "y": 1.5774503946304321,
                  "z": 0.09172430634498596
                },
                "pinky1": {
                  "x": 0.4105701744556427,
                  "y": 1.5391274690628052,
                  "z": 0.08078519254922867
                }
              },
              {
                "thumb0": {
                  "x": 0.22943654656410217,
                  "y": 1.4818174839019775,
                  "z": -0.027118965983390808
                },
                "index0": {
                  "x": 0.2147306650876999,
                  "y": 1.585326910018921,
                  "z": -0.0856865793466568
                },
                "middle0": {
                  "x": 0.23802444338798523,
                  "y": 1.5964031219482422,
                  "z": -0.11469125002622604
                },
                "ring0": {
                  "x": 0.2536013126373291,
                  "y": 1.5799461603164673,
                  "z": -0.14098593592643738
                },
                "pinky0": {
                  "x": 0.27069181203842163,
                  "y": 1.5424420833587646,
                  "z": -0.1717328131198883
                },
                "thumb1": {
                  "x": 0.2618419826030731,
                  "y": 1.4865959882736206,
                  "z": 0.06509693711996078
                },
                "index1": {
                  "x": 0.32273876667022705,
                  "y": 1.587280035018921,
                  "z": 0.10976383835077286
                },
                "middle1": {
                  "x": 0.35741719603538513,
                  "y": 1.5949808359146118,
                  "z": 0.09648272395133972
                },
                "ring1": {
                  "x": 0.38281458616256714,
                  "y": 1.577212929725647,
                  "z": 0.0909503772854805
                },
                "pinky1": {
                  "x": 0.41093909740448,
                  "y": 1.5393154621124268,
                  "z": 0.08117528259754181
                }
              },
              {
                "thumb0": {
                  "x": 0.2239816039800644,
                  "y": 1.4805057048797607,
                  "z": -0.02687361091375351
                },
                "index0": {
                  "x": 0.22161108255386353,
                  "y": 1.5835444927215576,
                  "z": -0.08508528023958206
                },
                "middle0": {
                  "x": 0.2502855956554413,
                  "y": 1.5919581651687622,
                  "z": -0.10952573269605637
                },
                "ring0": {
                  "x": 0.2702162265777588,
                  "y": 1.5733345746994019,
                  "z": -0.1322016417980194
                },
                "pinky0": {
                  "x": 0.28620749711990356,
                  "y": 1.5347169637680054,
                  "z": -0.16257579624652863
                },
                "thumb1": {
                  "x": 0.2619847357273102,
                  "y": 1.4863109588623047,
                  "z": 0.06403549015522003
                },
                "index1": {
                  "x": 0.3218541145324707,
                  "y": 1.586356282234192,
                  "z": 0.1079588532447815
                },
                "middle1": {
                  "x": 0.35682323575019836,
                  "y": 1.5941530466079712,
                  "z": 0.09472092241048813
                },
                "ring1": {
                  "x": 0.38225340843200684,
                  "y": 1.5765849351882935,
                  "z": 0.08940017968416214
                },
                "pinky1": {
                  "x": 0.41007542610168457,
                  "y": 1.5389447212219238,
                  "z": 0.07965438067913055
                }
              },
              {
                "thumb0": {
                  "x": 0.20515176653862,
                  "y": 1.4994760751724243,
                  "z": -0.03835470229387283
                },
                "index0": {
                  "x": 0.27167007327079773,
                  "y": 1.5700409412384033,
                  "z": -0.08377242833375931
                },
                "middle0": {
                  "x": 0.29939183592796326,
                  "y": 1.5063574314117432,
                  "z": -0.06500544399023056
                },
                "ring0": {
                  "x": 0.3087139427661896,
                  "y": 1.4853023290634155,
                  "z": -0.07409398257732391
                },
                "pinky0": {
                  "x": 0.3142377436161041,
                  "y": 1.472358226776123,
                  "z": -0.09703481942415237
                },
                "thumb1": {
                  "x": 0.26060059666633606,
                  "y": 1.4871190786361694,
                  "z": 0.06687365472316742
                },
                "index1": {
                  "x": 0.3210656940937042,
                  "y": 1.5863521099090576,
                  "z": 0.10647307336330414
                },
                "middle1": {
                  "x": 0.3559524714946747,
                  "y": 1.5936028957366943,
                  "z": 0.09304473549127579
                },
                "ring1": {
                  "x": 0.38118138909339905,
                  "y": 1.5759425163269043,
                  "z": 0.0883939266204834
                },
                "pinky1": {
                  "x": 0.40871912240982056,
                  "y": 1.5382027626037598,
                  "z": 0.07992074638605118
                }
              },
              {
                "thumb0": {
                  "x": 0.22167646884918213,
                  "y": 1.5386314392089844,
                  "z": -0.09403617680072784
                },
                "index0": {
                  "x": 0.3239612281322479,
                  "y": 1.5197198390960693,
                  "z": -0.05704513192176819
                },
                "middle0": {
                  "x": 0.26536592841148376,
                  "y": 1.4455182552337646,
                  "z": -0.05572390556335449
                },
                "ring0": {
                  "x": 0.2638188600540161,
                  "y": 1.434517741203308,
                  "z": -0.0738716721534729
                },
                "pinky0": {
                  "x": 0.27219852805137634,
                  "y": 1.4262194633483887,
                  "z": -0.08946999162435532
                },
                "thumb1": {
                  "x": 0.26049917936325073,
                  "y": 1.4871258735656738,
                  "z": 0.08163368701934814
                },
                "index1": {
                  "x": 0.3251805603504181,
                  "y": 1.5787752866744995,
                  "z": 0.0904545709490776
                },
                "middle1": {
                  "x": 0.34913015365600586,
                  "y": 1.576016902923584,
                  "z": 0.0634804293513298
                },
                "ring1": {
                  "x": 0.3739384412765503,
                  "y": 1.5593622922897339,
                  "z": 0.05972212553024292
                },
                "pinky1": {
                  "x": 0.4012349843978882,
                  "y": 1.5247371196746826,
                  "z": 0.061377253383398056
                }
              },
              {
                "thumb0": {
                  "x": 0.24693483114242554,
                  "y": 1.536955714225769,
                  "z": -0.10223642736673355
                },
                "index0": {
                  "x": 0.3328082263469696,
                  "y": 1.487593650817871,
                  "z": -0.030880674719810486
                },
                "middle0": {
                  "x": 0.25757837295532227,
                  "y": 1.4351282119750977,
                  "z": -0.052296072244644165
                },
                "ring0": {
                  "x": 0.2608906626701355,
                  "y": 1.4263949394226074,
                  "z": -0.07561489939689636
                },
                "pinky0": {
                  "x": 0.2718731462955475,
                  "y": 1.4160093069076538,
                  "z": -0.08528618514537811
                },
                "thumb1": {
                  "x": 0.2626121938228607,
                  "y": 1.4943989515304565,
                  "z": 0.08981197327375412
                },
                "index1": {
                  "x": 0.3291531205177307,
                  "y": 1.5690158605575562,
                  "z": 0.07190655171871185
                },
                "middle1": {
                  "x": 0.34859398007392883,
                  "y": 1.5647505521774292,
                  "z": 0.05040739104151726
                },
                "ring1": {
                  "x": 0.3732280135154724,
                  "y": 1.5485550165176392,
                  "z": 0.048310767859220505
                },
                "pinky1": {
                  "x": 0.3953784704208374,
                  "y": 1.5153826475143433,
                  "z": 0.052928946912288666
                }
              },
              {
                "thumb0": {
                  "x": 0.2495099902153015,
                  "y": 1.5323172807693481,
                  "z": -0.0961093157529831
                },
                "index0": {
                  "x": 0.3201729357242584,
                  "y": 1.4647128582000732,
                  "z": -0.012869179248809814
                },
                "middle0": {
                  "x": 0.24622878432273865,
                  "y": 1.4296681880950928,
                  "z": -0.04339004307985306
                },
                "ring0": {
                  "x": 0.2485910803079605,
                  "y": 1.420271635055542,
                  "z": -0.06649084389209747
                },
                "pinky0": {
                  "x": 0.2542537748813629,
                  "y": 1.4082244634628296,
                  "z": -0.07158780097961426
                },
                "thumb1": {
                  "x": 0.27611616253852844,
                  "y": 1.4870864152908325,
                  "z": 0.07013314962387085
                },
                "index1": {
                  "x": 0.32503652572631836,
                  "y": 1.5611056089401245,
                  "z": 0.06422267854213715
                },
                "middle1": {
                  "x": 0.3532557785511017,
                  "y": 1.56771719455719,
                  "z": 0.05344228819012642
                },
                "ring1": {
                  "x": 0.3773902654647827,
                  "y": 1.5521026849746704,
                  "z": 0.05149756371974945
                },
                "pinky1": {
                  "x": 0.40046584606170654,
                  "y": 1.5191105604171753,
                  "z": 0.055275093764066696
                }
              },
              {
                "thumb0": {
                  "x": 0.24571417272090912,
                  "y": 1.5265003442764282,
                  "z": -0.09129277616739273
                },
                "index0": {
                  "x": 0.3119240701198578,
                  "y": 1.4539003372192383,
                  "z": -0.006630703806877136
                },
                "middle0": {
                  "x": 0.24029366672039032,
                  "y": 1.4231261014938354,
                  "z": -0.04112260043621063
                },
                "ring0": {
                  "x": 0.24527497589588165,
                  "y": 1.4138059616088867,
                  "z": -0.06442815065383911
                },
                "pinky0": {
                  "x": 0.25350022315979004,
                  "y": 1.4023306369781494,
                  "z": -0.07363266497850418
                },
                "thumb1": {
                  "x": 0.3167782425880432,
                  "y": 1.4819331169128418,
                  "z": 0.02404378354549408
                },
                "index1": {
                  "x": 0.30600103735923767,
                  "y": 1.5484577417373657,
                  "z": 0.041879214346408844
                },
                "middle1": {
                  "x": 0.33401304483413696,
                  "y": 1.5872622728347778,
                  "z": 0.059623852372169495
                },
                "ring1": {
                  "x": 0.3659808933734894,
                  "y": 1.5857645273208618,
                  "z": 0.07083968818187714
                },
                "pinky1": {
                  "x": 0.40254518389701843,
                  "y": 1.5582396984100342,
                  "z": 0.07362383604049683
                }
              },
              {
                "thumb0": {
                  "x": 0.24283114075660706,
                  "y": 1.5256175994873047,
                  "z": -0.08991584926843643
                },
                "index0": {
                  "x": 0.30582842230796814,
                  "y": 1.453015685081482,
                  "z": -0.003418758511543274
                },
                "middle0": {
                  "x": 0.23520736396312714,
                  "y": 1.4214646816253662,
                  "z": -0.03916207700967789
                },
                "ring0": {
                  "x": 0.23996300995349884,
                  "y": 1.411983609199524,
                  "z": -0.06095873564481735
                },
                "pinky0": {
                  "x": 0.24935272336006165,
                  "y": 1.4002361297607422,
                  "z": -0.06853816658258438
                },
                "thumb1": {
                  "x": 0.3056410551071167,
                  "y": 1.4930999279022217,
                  "z": -0.02108673006296158
                },
                "index1": {
                  "x": 0.2893246114253998,
                  "y": 1.5470455884933472,
                  "z": -0.015166208148002625
                },
                "middle1": {
                  "x": 0.3069906532764435,
                  "y": 1.6031986474990845,
                  "z": 0.005326688289642334
                },
                "ring1": {
                  "x": 0.34396690130233765,
                  "y": 1.6088627576828003,
                  "z": 0.01645425707101822
                },
                "pinky1": {
                  "x": 0.386272668838501,
                  "y": 1.585312843322754,
                  "z": 0.0176762193441391
                }
              },
              {
                "thumb0": {
                  "x": 0.24570931494235992,
                  "y": 1.5228101015090942,
                  "z": -0.08750344067811966
                },
                "index0": {
                  "x": 0.306549608707428,
                  "y": 1.4483736753463745,
                  "z": -0.0029461830854415894
                },
                "middle0": {
                  "x": 0.24243338406085968,
                  "y": 1.417567491531372,
                  "z": -0.03162878751754761
                },
                "ring0": {
                  "x": 0.2421361207962036,
                  "y": 1.4065537452697754,
                  "z": -0.0508677139878273
                },
                "pinky0": {
                  "x": 0.24972032010555267,
                  "y": 1.3956547975540161,
                  "z": -0.06481502205133438
                },
                "thumb1": {
                  "x": 0.2726762294769287,
                  "y": 1.4948222637176514,
                  "z": -0.046042442321777344
                },
                "index1": {
                  "x": 0.25437894463539124,
                  "y": 1.5371612310409546,
                  "z": -0.04477115720510483
                },
                "middle1": {
                  "x": 0.26311275362968445,
                  "y": 1.6078133583068848,
                  "z": -0.0258454829454422
                },
                "ring1": {
                  "x": 0.3007045388221741,
                  "y": 1.6171016693115234,
                  "z": -0.018001340329647064
                },
                "pinky1": {
                  "x": 0.3443799614906311,
                  "y": 1.5970873832702637,
                  "z": -0.019789226353168488
                }
              },
              {
                "thumb0": {
                  "x": 0.24418160319328308,
                  "y": 1.5232585668563843,
                  "z": -0.0875355452299118
                },
                "index0": {
                  "x": 0.31373581290245056,
                  "y": 1.4489229917526245,
                  "z": -0.004690058529376984
                },
                "middle0": {
                  "x": 0.24164405465126038,
                  "y": 1.41683828830719,
                  "z": -0.026108182966709137
                },
                "ring0": {
                  "x": 0.241277813911438,
                  "y": 1.405550479888916,
                  "z": -0.04209943860769272
                },
                "pinky0": {
                  "x": 0.24740134179592133,
                  "y": 1.396671175956726,
                  "z": -0.06247466802597046
                },
                "thumb1": {
                  "x": 0.29782167077064514,
                  "y": 1.4818813800811768,
                  "z": -0.013316407799720764
                },
                "index1": {
                  "x": 0.28042909502983093,
                  "y": 1.5208677053451538,
                  "z": -0.012747406959533691
                },
                "middle1": {
                  "x": 0.28561484813690186,
                  "y": 1.593260645866394,
                  "z": 0.00410781055688858
                },
                "ring1": {
                  "x": 0.3231172263622284,
                  "y": 1.6044392585754395,
                  "z": 0.014791712164878845
                },
                "pinky1": {
                  "x": 0.3658194839954376,
                  "y": 1.5843515396118164,
                  "z": 0.01251685619354248
                }
              },
              {
                "thumb0": {
                  "x": 0.24341046810150146,
                  "y": 1.5223461389541626,
                  "z": -0.09029135853052139
                },
                "index0": {
                  "x": 0.3219592869281769,
                  "y": 1.4528027772903442,
                  "z": -0.008487746119499207
                },
                "middle0": {
                  "x": 0.23727479577064514,
                  "y": 1.4182665348052979,
                  "z": -0.03872612863779068
                },
                "ring0": {
                  "x": 0.24362368881702423,
                  "y": 1.4094239473342896,
                  "z": -0.06532230228185654
                },
                "pinky0": {
                  "x": 0.2500263750553131,
                  "y": 1.3966737985610962,
                  "z": -0.07030528783798218
                },
                "thumb1": {
                  "x": 0.3604624569416046,
                  "y": 1.4809589385986328,
                  "z": 0.07020673900842667
                },
                "index1": {
                  "x": 0.3461276888847351,
                  "y": 1.5277137756347656,
                  "z": 0.07375804334878922
                },
                "middle1": {
                  "x": 0.35902267694473267,
                  "y": 1.5912768840789795,
                  "z": 0.09831073880195618
                },
                "ring1": {
                  "x": 0.39434078335762024,
                  "y": 1.5958853960037231,
                  "z": 0.11631082743406296
                },
                "pinky1": {
                  "x": 0.4352834224700928,
                  "y": 1.5712758302688599,
                  "z": 0.12116184830665588
                }
              },
              {
                "thumb0": {
                  "x": 0.24552969634532928,
                  "y": 1.5227460861206055,
                  "z": -0.08884364366531372
                },
                "index0": {
                  "x": 0.32227930426597595,
                  "y": 1.456174373626709,
                  "z": -0.00510069727897644
                },
                "middle0": {
                  "x": 0.23632529377937317,
                  "y": 1.4200921058654785,
                  "z": -0.03972723335027695
                },
                "ring0": {
                  "x": 0.24409401416778564,
                  "y": 1.410165548324585,
                  "z": -0.061586037278175354
                },
                "pinky0": {
                  "x": 0.25506505370140076,
                  "y": 1.3976705074310303,
                  "z": -0.06894078105688095
                },
                "thumb1": {
                  "x": 0.35933199524879456,
                  "y": 1.482081651687622,
                  "z": 0.06384485214948654
                },
                "index1": {
                  "x": 0.3449544608592987,
                  "y": 1.5313442945480347,
                  "z": 0.06899594515562057
                },
                "middle1": {
                  "x": 0.36118653416633606,
                  "y": 1.5927382707595825,
                  "z": 0.09674937278032303
                },
                "ring1": {
                  "x": 0.395372599363327,
                  "y": 1.5941100120544434,
                  "z": 0.11492079496383667
                },
                "pinky1": {
                  "x": 0.4353335499763489,
                  "y": 1.5678409337997437,
                  "z": 0.12150734663009644
                }
              },
              {
                "thumb0": {
                  "x": 0.2463589459657669,
                  "y": 1.5245026350021362,
                  "z": -0.08417379856109619
                },
                "index0": {
                  "x": 0.32100653648376465,
                  "y": 1.4582560062408447,
                  "z": 0.0014172717928886414
                },
                "middle0": {
                  "x": 0.23821324110031128,
                  "y": 1.4216651916503906,
                  "z": -0.03455261141061783
                },
                "ring0": {
                  "x": 0.2459562122821808,
                  "y": 1.411994218826294,
                  "z": -0.058108821511268616
                },
                "pinky0": {
                  "x": 0.2542620003223419,
                  "y": 1.3991261720657349,
                  "z": -0.0632500946521759
                },
                "thumb1": {
                  "x": 0.3231234550476074,
                  "y": 1.4870688915252686,
                  "z": 0.006804481148719788
                },
                "index1": {
                  "x": 0.3063192367553711,
                  "y": 1.539879560470581,
                  "z": 0.011978678405284882
                },
                "middle1": {
                  "x": 0.3265056312084198,
                  "y": 1.59696364402771,
                  "z": 0.0362781286239624
                },
                "ring1": {
                  "x": 0.3623744249343872,
                  "y": 1.600054144859314,
                  "z": 0.051142752170562744
                },
                "pinky1": {
                  "x": 0.4039047360420227,
                  "y": 1.5746511220932007,
                  "z": 0.0539696179330349
                }
              },
              {
                "thumb0": {
                  "x": 0.24399273097515106,
                  "y": 1.5259071588516235,
                  "z": -0.08416242897510529
                },
                "index0": {
                  "x": 0.31291070580482483,
                  "y": 1.4577163457870483,
                  "z": 0.002015143632888794
                },
                "middle0": {
                  "x": 0.24102994799613953,
                  "y": 1.4221452474594116,
                  "z": -0.027408652007579803
                },
                "ring0": {
                  "x": 0.24357324838638306,
                  "y": 1.4109920263290405,
                  "z": -0.04597008228302002
                },
                "pinky0": {
                  "x": 0.2521185576915741,
                  "y": 1.4000060558319092,
                  "z": -0.06003805249929428
                },
                "thumb1": {
                  "x": 0.29363301396369934,
                  "y": 1.4837299585342407,
                  "z": -0.029323115944862366
                },
                "index1": {
                  "x": 0.27805981040000916,
                  "y": 1.537980318069458,
                  "z": -0.028420962393283844
                },
                "middle1": {
                  "x": 0.2960429787635803,
                  "y": 1.5954893827438354,
                  "z": -0.008914880454540253
                },
                "ring1": {
                  "x": 0.3326704800128937,
                  "y": 1.6008700132369995,
                  "z": 0.002501748502254486
                },
                "pinky1": {
                  "x": 0.3753993511199951,
                  "y": 1.5785894393920898,
                  "z": 0.005057960748672485
                }
              },
              {
                "thumb0": {
                  "x": 0.24333342909812927,
                  "y": 1.524792194366455,
                  "z": -0.0865907296538353
                },
                "index0": {
                  "x": 0.3129613697528839,
                  "y": 1.4562381505966187,
                  "z": -0.0016501620411872864
                },
                "middle0": {
                  "x": 0.24126078188419342,
                  "y": 1.4207433462142944,
                  "z": -0.03040505200624466
                },
                "ring0": {
                  "x": 0.24427108466625214,
                  "y": 1.4110091924667358,
                  "z": -0.05585002154111862
                },
                "pinky0": {
                  "x": 0.2540760040283203,
                  "y": 1.3996398448944092,
                  "z": -0.06638970226049423
                },
                "thumb1": {
                  "x": 0.2988586127758026,
                  "y": 1.4802755117416382,
                  "z": -0.01768478751182556
                },
                "index1": {
                  "x": 0.2843734622001648,
                  "y": 1.5334227085113525,
                  "z": -0.018065690994262695
                },
                "middle1": {
                  "x": 0.3020216226577759,
                  "y": 1.591678261756897,
                  "z": -0.0008467063307762146
                },
                "ring1": {
                  "x": 0.33821648359298706,
                  "y": 1.5971150398254395,
                  "z": 0.009548567235469818
                },
                "pinky1": {
                  "x": 0.37925681471824646,
                  "y": 1.574869155883789,
                  "z": 0.01181802898645401
                }
              },
              {
                "thumb0": {
                  "x": 0.24348056316375732,
                  "y": 1.5250537395477295,
                  "z": -0.08641981333494186
                },
                "index0": {
                  "x": 0.3119733929634094,
                  "y": 1.4570502042770386,
                  "z": -0.0008733049035072327
                },
                "middle0": {
                  "x": 0.24382025003433228,
                  "y": 1.4203548431396484,
                  "z": -0.02986966073513031
                },
                "ring0": {
                  "x": 0.2423401176929474,
                  "y": 1.4101364612579346,
                  "z": -0.05342600494623184
                },
                "pinky0": {
                  "x": 0.24452506005764008,
                  "y": 1.3988125324249268,
                  "z": -0.06353417783975601
                },
                "thumb1": {
                  "x": 0.29818248748779297,
                  "y": 1.480431079864502,
                  "z": -0.014109738171100616
                },
                "index1": {
                  "x": 0.28443312644958496,
                  "y": 1.5332505702972412,
                  "z": -0.015167802572250366
                },
                "middle1": {
                  "x": 0.30208635330200195,
                  "y": 1.591322422027588,
                  "z": 0.0011389926075935364
                },
                "ring1": {
                  "x": 0.33838948607444763,
                  "y": 1.597083330154419,
                  "z": 0.011615626513957977
                },
                "pinky1": {
                  "x": 0.378812700510025,
                  "y": 1.5743428468704224,
                  "z": 0.013586968183517456
                }
              },
              {
                "thumb0": {
                  "x": 0.24600160121917725,
                  "y": 1.525395393371582,
                  "z": -0.08462410420179367
                },
                "index0": {
                  "x": 0.31237494945526123,
                  "y": 1.4570995569229126,
                  "z": 0.001524128019809723
                },
                "middle0": {
                  "x": 0.24521678686141968,
                  "y": 1.4213283061981201,
                  "z": -0.025556765496730804
                },
                "ring0": {
                  "x": 0.24571366608142853,
                  "y": 1.4094358682632446,
                  "z": -0.04193269461393356
                },
                "pinky0": {
                  "x": 0.25431621074676514,
                  "y": 1.3991565704345703,
                  "z": -0.059510961174964905
                },
                "thumb1": {
                  "x": 0.2984676957130432,
                  "y": 1.4803212881088257,
                  "z": -0.006589874625205994
                },
                "index1": {
                  "x": 0.2862071692943573,
                  "y": 1.533981204032898,
                  "z": -0.007259547710418701
                },
                "middle1": {
                  "x": 0.30567827820777893,
                  "y": 1.5894179344177246,
                  "z": 0.007562197744846344
                },
                "ring1": {
                  "x": 0.3419780135154724,
                  "y": 1.5946248769760132,
                  "z": 0.017858080565929413
                },
                "pinky1": {
                  "x": 0.3819822669029236,
                  "y": 1.5716450214385986,
                  "z": 0.02040894329547882
                }
              },
              {
                "thumb0": {
                  "x": 0.24707621335983276,
                  "y": 1.5245736837387085,
                  "z": -0.08293181657791138
                },
                "index0": {
                  "x": 0.3129417300224304,
                  "y": 1.456788182258606,
                  "z": 0.0028297603130340576
                },
                "middle0": {
                  "x": 0.2473314255475998,
                  "y": 1.420763611793518,
                  "z": -0.021865196526050568
                },
                "ring0": {
                  "x": 0.2470487505197525,
                  "y": 1.4091746807098389,
                  "z": -0.04243878275156021
                },
                "pinky0": {
                  "x": 0.25511038303375244,
                  "y": 1.398033857345581,
                  "z": -0.056073665618896484
                },
                "thumb1": {
                  "x": 0.2987164258956909,
                  "y": 1.481272578239441,
                  "z": -0.001376323401927948
                },
                "index1": {
                  "x": 0.28567150235176086,
                  "y": 1.5437556505203247,
                  "z": 0.0006185173988342285
                },
                "middle1": {
                  "x": 0.30840829014778137,
                  "y": 1.5886106491088867,
                  "z": 0.009762167930603027
                },
                "ring1": {
                  "x": 0.34423956274986267,
                  "y": 1.5937693119049072,
                  "z": 0.019827477633953094
                },
                "pinky1": {
                  "x": 0.3841412663459778,
                  "y": 1.570730209350586,
                  "z": 0.02302171289920807
                }
              },
              {
                "thumb0": {
                  "x": 0.24471049010753632,
                  "y": 1.523425579071045,
                  "z": -0.07824499905109406
                },
                "index0": {
                  "x": 0.3094637095928192,
                  "y": 1.4591087102890015,
                  "z": -0.0026387274265289307
                },
                "middle0": {
                  "x": 0.2667575478553772,
                  "y": 1.421100378036499,
                  "z": -0.012989446520805359
                },
                "ring0": {
                  "x": 0.2607111632823944,
                  "y": 1.4048038721084595,
                  "z": -0.028443075716495514
                },
                "pinky0": {
                  "x": 0.26224130392074585,
                  "y": 1.3931635618209839,
                  "z": -0.04925287514925003
                },
                "thumb1": {
                  "x": 0.2888593375682831,
                  "y": 1.4804638624191284,
                  "z": 0.02162676304578781
                },
                "index1": {
                  "x": 0.2961447834968567,
                  "y": 1.5668693780899048,
                  "z": 0.027596011757850647
                },
                "middle1": {
                  "x": 0.32413449883461,
                  "y": 1.5801045894622803,
                  "z": 0.015757650136947632
                },
                "ring1": {
                  "x": 0.35537347197532654,
                  "y": 1.5799630880355835,
                  "z": 0.023450329899787903
                },
                "pinky1": {
                  "x": 0.3920455873012543,
                  "y": 1.5540555715560913,
                  "z": 0.02986977994441986
                }
              },
              {
                "thumb0": {
                  "x": 0.22309067845344543,
                  "y": 1.5165067911148071,
                  "z": -0.05256115645170212
                },
                "index0": {
                  "x": 0.32582414150238037,
                  "y": 1.4865808486938477,
                  "z": -0.017026826739311218
                },
                "middle0": {
                  "x": 0.3088896572589874,
                  "y": 1.4349931478500366,
                  "z": -0.005855299532413483
                },
                "ring0": {
                  "x": 0.30894240736961365,
                  "y": 1.409900426864624,
                  "z": -0.018697001039981842
                },
                "pinky0": {
                  "x": 0.3027331829071045,
                  "y": 1.3896719217300415,
                  "z": -0.04123516380786896
                },
                "thumb1": {
                  "x": 0.2834172248840332,
                  "y": 1.4857136011123657,
                  "z": 0.05766961723566055
                },
                "index1": {
                  "x": 0.33581119775772095,
                  "y": 1.5707045793533325,
                  "z": 0.05777590721845627
                },
                "middle1": {
                  "x": 0.3600578010082245,
                  "y": 1.5717352628707886,
                  "z": 0.04311450198292732
                },
                "ring1": {
                  "x": 0.38679882884025574,
                  "y": 1.56221342086792,
                  "z": 0.049623627215623856
                },
                "pinky1": {
                  "x": 0.41710129380226135,
                  "y": 1.5278569459915161,
                  "z": 0.052993882447481155
                }
              }
            ],
            "words": "axis"
          },
          {
            "key": "edeefb76-cebb-fff4-bdfb-850a5519436d",
            "gesture": [
                {
                    "thumb0": {
                        "x": -0.1006360575556755,
                        "y": 1.509734034538269,
                        "z": -0.19286887347698212
                    },
                    "index0": {
                        "x": -0.10868673771619797,
                        "y": 1.522994875907898,
                        "z": -0.1910293996334076
                    },
                    "middle0": {
                        "x": -0.154411181807518,
                        "y": 1.5925570726394653,
                        "z": -0.18107813596725464
                    },
                    "ring0": {
                        "x": -0.18507172167301178,
                        "y": 1.5873680114746094,
                        "z": -0.1894911825656891
                    },
                    "pinky0": {
                        "x": -0.2008625566959381,
                        "y": 1.5593267679214478,
                        "z": -0.21519050002098083
                    },
                    "thumb1": {
                        "x": 0.08693982660770416,
                        "y": 1.5162849426269531,
                        "z": -0.17562800645828247
                    },
                    "index1": {
                        "x": 0.08835028111934662,
                        "y": 1.526757001876831,
                        "z": -0.17002001404762268
                    },
                    "middle1": {
                        "x": 0.12974199652671814,
                        "y": 1.5945638418197632,
                        "z": -0.15932780504226685
                    },
                    "ring1": {
                        "x": 0.15812472999095917,
                        "y": 1.589416742324829,
                        "z": -0.16985003650188446
                    },
                    "pinky1": {
                        "x": 0.17622952163219452,
                        "y": 1.5618162155151367,
                        "z": -0.19256719946861267
                    }
                },
                {
                    "thumb0": {
                        "x": -0.09622588753700256,
                        "y": 1.5116329193115234,
                        "z": -0.19086134433746338
                    },
                    "index0": {
                        "x": -0.1074826791882515,
                        "y": 1.5435850620269775,
                        "z": -0.1889456957578659
                    },
                    "middle0": {
                        "x": -0.15224646031856537,
                        "y": 1.5931038856506348,
                        "z": -0.18346881866455078
                    },
                    "ring0": {
                        "x": -0.17977172136306763,
                        "y": 1.5869873762130737,
                        "z": -0.19491985440254211
                    },
                    "pinky0": {
                        "x": -0.19515419006347656,
                        "y": 1.5582404136657715,
                        "z": -0.22002887725830078
                    },
                    "thumb1": {
                        "x": 0.08340279012918472,
                        "y": 1.518046259880066,
                        "z": -0.16928210854530334
                    },
                    "index1": {
                        "x": 0.0966879278421402,
                        "y": 1.5599071979522705,
                        "z": -0.16128301620483398
                    },
                    "middle1": {
                        "x": 0.12827251851558685,
                        "y": 1.5949968099594116,
                        "z": -0.1602579653263092
                    },
                    "ring1": {
                        "x": 0.1564994603395462,
                        "y": 1.5904079675674438,
                        "z": -0.169688880443573
                    },
                    "pinky1": {
                        "x": 0.1731189489364624,
                        "y": 1.5624934434890747,
                        "z": -0.19216382503509521
                    }
                },
                {
                    "thumb0": {
                        "x": -0.09130522608757019,
                        "y": 1.5128968954086304,
                        "z": -0.1812082678079605
                    },
                    "index0": {
                        "x": -0.11898484081029892,
                        "y": 1.5729244947433472,
                        "z": -0.1781100034713745
                    },
                    "middle0": {
                        "x": -0.14994515478610992,
                        "y": 1.5937479734420776,
                        "z": -0.18595531582832336
                    },
                    "ring0": {
                        "x": -0.17253842949867249,
                        "y": 1.5859708786010742,
                        "z": -0.2002626657485962
                    },
                    "pinky0": {
                        "x": -0.187430277466774,
                        "y": 1.5563910007476807,
                        "z": -0.2238866686820984
                    },
                    "thumb1": {
                        "x": 0.07438565790653229,
                        "y": 1.5201557874679565,
                        "z": -0.1453082263469696
                    },
                    "index1": {
                        "x": 0.11872229725122452,
                        "y": 1.5927762985229492,
                        "z": -0.13758262991905212
                    },
                    "middle1": {
                        "x": 0.12887950241565704,
                        "y": 1.599764108657837,
                        "z": -0.15872842073440552
                    },
                    "ring1": {
                        "x": 0.15501107275485992,
                        "y": 1.592997670173645,
                        "z": -0.17037168145179749
                    },
                    "pinky1": {
                        "x": 0.17162951827049255,
                        "y": 1.5638068914413452,
                        "z": -0.19384825229644775
                    }
                },
                {
                    "thumb0": {
                        "x": -0.0924939513206482,
                        "y": 1.515579104423523,
                        "z": -0.1661432385444641
                    },
                    "index0": {
                        "x": -0.12855978310108185,
                        "y": 1.582162857055664,
                        "z": -0.17116177082061768
                    },
                    "middle0": {
                        "x": -0.14833886921405792,
                        "y": 1.5934542417526245,
                        "z": -0.1879415363073349
                    },
                    "ring0": {
                        "x": -0.16149146854877472,
                        "y": 1.5805474519729614,
                        "z": -0.2079886794090271
                    },
                    "pinky0": {
                        "x": -0.1778007298707962,
                        "y": 1.5511354207992554,
                        "z": -0.22772887349128723
                    },
                    "thumb1": {
                        "x": 0.08573035150766373,
                        "y": 1.524802803993225,
                        "z": -0.12410099059343338
                    },
                    "index1": {
                        "x": 0.12706992030143738,
                        "y": 1.595832347869873,
                        "z": -0.13016363978385925
                    },
                    "middle1": {
                        "x": 0.12991571426391602,
                        "y": 1.6026732921600342,
                        "z": -0.1555226445198059
                    },
                    "ring1": {
                        "x": 0.14607009291648865,
                        "y": 1.5934382677078247,
                        "z": -0.1744292676448822
                    },
                    "pinky1": {
                        "x": 0.1627075970172882,
                        "y": 1.5652211904525757,
                        "z": -0.19793739914894104
                    }
                },
                {
                    "thumb0": {
                        "x": -0.0927690640091896,
                        "y": 1.5159331560134888,
                        "z": -0.16416549682617188
                    },
                    "index0": {
                        "x": -0.12983518838882446,
                        "y": 1.5826871395111084,
                        "z": -0.17038214206695557
                    },
                    "middle0": {
                        "x": -0.14593976736068726,
                        "y": 1.592025637626648,
                        "z": -0.18854394555091858
                    },
                    "ring0": {
                        "x": -0.15649668872356415,
                        "y": 1.5768370628356934,
                        "z": -0.20993776619434357
                    },
                    "pinky0": {
                        "x": -0.17051224410533905,
                        "y": 1.546248197555542,
                        "z": -0.22960525751113892
                    },
                    "thumb1": {
                        "x": 0.08737555146217346,
                        "y": 1.5254439115524292,
                        "z": -0.12468306720256805
                    },
                    "index1": {
                        "x": 0.12773998081684113,
                        "y": 1.5953665971755981,
                        "z": -0.13346219062805176
                    },
                    "middle1": {
                        "x": 0.13182328641414642,
                        "y": 1.6024965047836304,
                        "z": -0.15781816840171814
                    },
                    "ring1": {
                        "x": 0.14829926192760468,
                        "y": 1.5927549600601196,
                        "z": -0.17672643065452576
                    },
                    "pinky1": {
                        "x": 0.16468042135238647,
                        "y": 1.5636029243469238,
                        "z": -0.19959115982055664
                    }
                },
                {
                    "thumb0": {
                        "x": -0.07626879960298538,
                        "y": 1.4985111951828003,
                        "z": -0.13488546013832092
                    },
                    "index0": {
                        "x": -0.09633312374353409,
                        "y": 1.560060977935791,
                        "z": -0.17540380358695984
                    },
                    "middle0": {
                        "x": -0.08625622093677521,
                        "y": 1.557201862335205,
                        "z": -0.19822904467582703
                    },
                    "ring0": {
                        "x": -0.08928339183330536,
                        "y": 1.5413658618927002,
                        "z": -0.21452970802783966
                    },
                    "pinky0": {
                        "x": -0.09938095510005951,
                        "y": 1.5139912366867065,
                        "z": -0.22906556725502014
                    },
                    "thumb1": {
                        "x": 0.09299639612436295,
                        "y": 1.519771933555603,
                        "z": -0.10914760828018188
                    },
                    "index1": {
                        "x": 0.13243800401687622,
                        "y": 1.588484525680542,
                        "z": -0.1261100172996521
                    },
                    "middle1": {
                        "x": 0.13487625122070312,
                        "y": 1.5936304330825806,
                        "z": -0.15025827288627625
                    },
                    "ring1": {
                        "x": 0.15020571649074554,
                        "y": 1.5830349922180176,
                        "z": -0.16861198842525482
                    },
                    "pinky1": {
                        "x": 0.16903989017009735,
                        "y": 1.5544573068618774,
                        "z": -0.18753018975257874
                    }
                },
                {
                    "thumb0": {
                        "x": -0.06911329925060272,
                        "y": 1.473771572113037,
                        "z": -0.15027756989002228
                    },
                    "index0": {
                        "x": 0.0013462058268487453,
                        "y": 1.4664173126220703,
                        "z": -0.20609691739082336
                    },
                    "middle0": {
                        "x": 0.02126241847872734,
                        "y": 1.4511979818344116,
                        "z": -0.20425838232040405
                    },
                    "ring0": {
                        "x": 0.02276468463242054,
                        "y": 1.4311354160308838,
                        "z": -0.20834890007972717
                    },
                    "pinky0": {
                        "x": 0.01143602840602398,
                        "y": 1.4056705236434937,
                        "z": -0.20373636484146118
                    },
                    "thumb1": {
                        "x": 0.12108956277370453,
                        "y": 1.4750279188156128,
                        "z": -0.06530927121639252
                    },
                    "index1": {
                        "x": 0.1483107954263687,
                        "y": 1.5170857906341553,
                        "z": -0.1209670826792717
                    },
                    "middle1": {
                        "x": 0.14244388043880463,
                        "y": 1.5105252265930176,
                        "z": -0.14328336715698242
                    },
                    "ring1": {
                        "x": 0.15142880380153656,
                        "y": 1.4929933547973633,
                        "z": -0.1576250195503235
                    },
                    "pinky1": {
                        "x": 0.16655761003494263,
                        "y": 1.4604367017745972,
                        "z": -0.1651131510734558
                    }
                },
                {
                    "thumb0": {
                        "x": -0.018149420619010925,
                        "y": 1.3950109481811523,
                        "z": -0.24248898029327393
                    },
                    "index0": {
                        "x": 0.07668186724185944,
                        "y": 1.3771120309829712,
                        "z": -0.2393062561750412
                    },
                    "middle0": {
                        "x": 0.09325825423002243,
                        "y": 1.3719546794891357,
                        "z": -0.22770923376083374
                    },
                    "ring0": {
                        "x": 0.09407475590705872,
                        "y": 1.3570871353149414,
                        "z": -0.21591933071613312
                    },
                    "pinky0": {
                        "x": 0.07971782237291336,
                        "y": 1.3392940759658813,
                        "z": -0.20103731751441956
                    },
                    "thumb1": {
                        "x": 0.1750926673412323,
                        "y": 1.379569172859192,
                        "z": -0.050350122153759
                    },
                    "index1": {
                        "x": 0.15127979218959808,
                        "y": 1.3562225103378296,
                        "z": -0.11125995963811874
                    },
                    "middle1": {
                        "x": 0.13460442423820496,
                        "y": 1.342621088027954,
                        "z": -0.11620345711708069
                    },
                    "ring1": {
                        "x": 0.1306896060705185,
                        "y": 1.320952296257019,
                        "z": -0.11479965597391129
                    },
                    "pinky1": {
                        "x": 0.13904796540737152,
                        "y": 1.2907674312591553,
                        "z": -0.10288693010807037
                    }
                },
                {
                    "thumb0": {
                        "x": 0.02714318409562111,
                        "y": 1.3407983779907227,
                        "z": -0.25190067291259766
                    },
                    "index0": {
                        "x": 0.12115391343832016,
                        "y": 1.3189444541931152,
                        "z": -0.23357099294662476
                    },
                    "middle0": {
                        "x": 0.13629305362701416,
                        "y": 1.3150582313537598,
                        "z": -0.22222492098808289
                    },
                    "ring0": {
                        "x": 0.13505539298057556,
                        "y": 1.3041415214538574,
                        "z": -0.20974120497703552
                    },
                    "pinky0": {
                        "x": 0.1192125454545021,
                        "y": 1.2921420335769653,
                        "z": -0.1907065510749817
                    },
                    "thumb1": {
                        "x": 0.19352076947689056,
                        "y": 1.3554205894470215,
                        "z": -0.05074092745780945
                    },
                    "index1": {
                        "x": 0.15856027603149414,
                        "y": 1.330320119857788,
                        "z": -0.10479042679071426
                    },
                    "middle1": {
                        "x": 0.13945938646793365,
                        "y": 1.3195207118988037,
                        "z": -0.10651617497205734
                    },
                    "ring1": {
                        "x": 0.13239581882953644,
                        "y": 1.3021669387817383,
                        "z": -0.10245727002620697
                    },
                    "pinky1": {
                        "x": 0.1352267861366272,
                        "y": 1.275221586227417,
                        "z": -0.08734799176454544
                    }
                },
                {
                    "thumb0": {
                        "x": 0.04875543713569641,
                        "y": 1.3307764530181885,
                        "z": -0.23585057258605957
                    },
                    "index0": {
                        "x": 0.13900168240070343,
                        "y": 1.3223899602890015,
                        "z": -0.20360884070396423
                    },
                    "middle0": {
                        "x": 0.1518033891916275,
                        "y": 1.3230479955673218,
                        "z": -0.18931269645690918
                    },
                    "ring0": {
                        "x": 0.14955216646194458,
                        "y": 1.3159478902816772,
                        "z": -0.17491687834262848
                    },
                    "pinky0": {
                        "x": 0.13232548534870148,
                        "y": 1.306991457939148,
                        "z": -0.15367597341537476
                    },
                    "thumb1": {
                        "x": 0.0936148539185524,
                        "y": 1.3549820184707642,
                        "z": -0.159460186958313
                    },
                    "index1": {
                        "x": 0.02945365011692047,
                        "y": 1.3718589544296265,
                        "z": -0.19656245410442352
                    },
                    "middle1": {
                        "x": 0.009886683896183968,
                        "y": 1.3733657598495483,
                        "z": -0.19634592533111572
                    },
                    "ring1": {
                        "x": -0.003678343491628766,
                        "y": 1.3620027303695679,
                        "z": -0.18699434399604797
                    },
                    "pinky1": {
                        "x": -0.01031113974750042,
                        "y": 1.3403027057647705,
                        "z": -0.16817590594291687
                    }
                },
                {
                    "thumb0": {
                        "x": 0.03493519499897957,
                        "y": 1.4067245721817017,
                        "z": -0.18503707647323608
                    },
                    "index0": {
                        "x": 0.07643691450357437,
                        "y": 1.3794444799423218,
                        "z": -0.2409304976463318
                    },
                    "middle0": {
                        "x": 0.10881531238555908,
                        "y": 1.3592761754989624,
                        "z": -0.23230841755867004
                    },
                    "ring0": {
                        "x": 0.12337750196456909,
                        "y": 1.3357263803482056,
                        "z": -0.2132635861635208
                    },
                    "pinky0": {
                        "x": 0.11837664991617203,
                        "y": 1.316366195678711,
                        "z": -0.17966891825199127
                    },
                    "thumb1": {
                        "x": 0.030544906854629517,
                        "y": 1.4572606086730957,
                        "z": -0.1746511161327362
                    },
                    "index1": {
                        "x": -0.04141687601804733,
                        "y": 1.484127402305603,
                        "z": -0.2024887502193451
                    },
                    "middle1": {
                        "x": -0.06011320650577545,
                        "y": 1.4863321781158447,
                        "z": -0.19823744893074036
                    },
                    "ring1": {
                        "x": -0.0704190582036972,
                        "y": 1.4728587865829468,
                        "z": -0.18818248808383942
                    },
                    "pinky1": {
                        "x": -0.07336141914129257,
                        "y": 1.4507253170013428,
                        "z": -0.16916298866271973
                    }
                },
                {
                    "thumb0": {
                        "x": 0.09840366244316101,
                        "y": 1.3665488958358765,
                        "z": -0.19531023502349854
                    },
                    "index0": {
                        "x": 0.16120941936969757,
                        "y": 1.3302862644195557,
                        "z": -0.1838408261537552
                    },
                    "middle0": {
                        "x": 0.18080738186836243,
                        "y": 1.328537940979004,
                        "z": -0.16261550784111023
                    },
                    "ring0": {
                        "x": 0.1857295036315918,
                        "y": 1.323746919631958,
                        "z": -0.13910916447639465
                    },
                    "pinky0": {
                        "x": 0.16988898813724518,
                        "y": 1.3303934335708618,
                        "z": -0.11527188122272491
                    },
                    "thumb1": {
                        "x": 0.06598573923110962,
                        "y": 1.4303964376449585,
                        "z": -0.16806185245513916
                    },
                    "index1": {
                        "x": -0.006502349395304918,
                        "y": 1.4517940282821655,
                        "z": -0.20299068093299866
                    },
                    "middle1": {
                        "x": -0.025418952107429504,
                        "y": 1.4534320831298828,
                        "z": -0.19988346099853516
                    },
                    "ring1": {
                        "x": -0.0354999341070652,
                        "y": 1.4389548301696777,
                        "z": -0.19037513434886932
                    },
                    "pinky1": {
                        "x": -0.03779788315296173,
                        "y": 1.4169094562530518,
                        "z": -0.1713344007730484
                    }
                },
                {
                    "thumb0": {
                        "x": 0.08484499901533127,
                        "y": 1.322288155555725,
                        "z": -0.17670707404613495
                    },
                    "index0": {
                        "x": 0.14765077829360962,
                        "y": 1.2860256433486938,
                        "z": -0.1652376353740692
                    },
                    "middle0": {
                        "x": 0.16724872589111328,
                        "y": 1.2842772006988525,
                        "z": -0.14401234686374664
                    },
                    "ring0": {
                        "x": 0.17217083275318146,
                        "y": 1.2794861793518066,
                        "z": -0.12050599604845047
                    },
                    "pinky0": {
                        "x": 0.15633034706115723,
                        "y": 1.2861326932907104,
                        "z": -0.09666872024536133
                    },
                    "thumb1": {
                        "x": 0.08308357000350952,
                        "y": 1.4138264656066895,
                        "z": -0.14932972192764282
                    },
                    "index1": {
                        "x": 0.01085693296045065,
                        "y": 1.429710030555725,
                        "z": -0.18754974007606506
                    },
                    "middle1": {
                        "x": -0.0106729194521904,
                        "y": 1.428480863571167,
                        "z": -0.1865498572587967
                    },
                    "ring1": {
                        "x": -0.021949494257569313,
                        "y": 1.4139939546585083,
                        "z": -0.17493751645088196
                    },
                    "pinky1": {
                        "x": -0.024093618616461754,
                        "y": 1.3929795026779175,
                        "z": -0.1547214537858963
                    }
                },
                {
                    "thumb0": {
                        "x": -0.00649387389421463,
                        "y": 1.302610158920288,
                        "z": -0.2776651084423065
                    },
                    "index0": {
                        "x": 0.05631190538406372,
                        "y": 1.2663476467132568,
                        "z": -0.266195684671402
                    },
                    "middle0": {
                        "x": 0.07590986043214798,
                        "y": 1.2645992040634155,
                        "z": -0.244970440864563
                    },
                    "ring0": {
                        "x": 0.08083195984363556,
                        "y": 1.2598081827163696,
                        "z": -0.22146403789520264
                    },
                    "pinky0": {
                        "x": 0.06499145925045013,
                        "y": 1.2664546966552734,
                        "z": -0.19762679934501648
                    },
                    "thumb1": {
                        "x": 0.11263097077608109,
                        "y": 1.4074853658676147,
                        "z": -0.13972888886928558
                    },
                    "index1": {
                        "x": 0.037225570529699326,
                        "y": 1.4187318086624146,
                        "z": -0.1779547780752182
                    },
                    "middle1": {
                        "x": 0.01424042321741581,
                        "y": 1.4187707901000977,
                        "z": -0.1728551983833313
                    },
                    "ring1": {
                        "x": 0.0009164814837276936,
                        "y": 1.4034645557403564,
                        "z": -0.16106022894382477
                    },
                    "pinky1": {
                        "x": -0.0006936150602996349,
                        "y": 1.3832648992538452,
                        "z": -0.14023417234420776
                    }
                },
                {
                    "thumb0": {
                        "x": 0.023073475807905197,
                        "y": 1.296432375907898,
                        "z": -0.26779356598854065
                    },
                    "index0": {
                        "x": 0.08587924391031265,
                        "y": 1.2601697444915771,
                        "z": -0.2563241124153137
                    },
                    "middle0": {
                        "x": 0.10547719895839691,
                        "y": 1.2584213018417358,
                        "z": -0.23509886860847473
                    },
                    "ring0": {
                        "x": 0.11039930582046509,
                        "y": 1.2536303997039795,
                        "z": -0.21159249544143677
                    },
                    "pinky0": {
                        "x": 0.09455880522727966,
                        "y": 1.2602769136428833,
                        "z": -0.1877552568912506
                    },
                    "thumb1": {
                        "x": 0.1509920358657837,
                        "y": 1.3639085292816162,
                        "z": -0.11493070423603058
                    },
                    "index1": {
                        "x": 0.07485861331224442,
                        "y": 1.3676344156265259,
                        "z": -0.15457230806350708
                    },
                    "middle1": {
                        "x": 0.053152553737163544,
                        "y": 1.3668326139450073,
                        "z": -0.1528180092573166
                    },
                    "ring1": {
                        "x": 0.04119832441210747,
                        "y": 1.3467767238616943,
                        "z": -0.14650380611419678
                    },
                    "pinky1": {
                        "x": 0.03830835223197937,
                        "y": 1.3340555429458618,
                        "z": -0.1184113547205925
                    }
                },
                {
                    "thumb0": {
                        "x": 0.01035410724580288,
                        "y": 1.2952836751937866,
                        "z": -0.2658490836620331
                    },
                    "index0": {
                        "x": 0.07315988838672638,
                        "y": 1.2590211629867554,
                        "z": -0.25437963008880615
                    },
                    "middle0": {
                        "x": 0.09275783598423004,
                        "y": 1.257272720336914,
                        "z": -0.23315441608428955
                    },
                    "ring0": {
                        "x": 0.09767994284629822,
                        "y": 1.2524818181991577,
                        "z": -0.2096480131149292
                    },
                    "pinky0": {
                        "x": 0.08183944225311279,
                        "y": 1.259128212928772,
                        "z": -0.18581068515777588
                    },
                    "thumb1": {
                        "x": 0.17468228936195374,
                        "y": 1.3402948379516602,
                        "z": -0.0964885726571083
                    },
                    "index1": {
                        "x": 0.10793944448232651,
                        "y": 1.3167082071304321,
                        "z": -0.1542069911956787
                    },
                    "middle1": {
                        "x": 0.08223923295736313,
                        "y": 1.3315716981887817,
                        "z": -0.14376378059387207
                    },
                    "ring1": {
                        "x": 0.07011207938194275,
                        "y": 1.3175640106201172,
                        "z": -0.13345760107040405
                    },
                    "pinky1": {
                        "x": 0.06613168865442276,
                        "y": 1.3054519891738892,
                        "z": -0.10664786398410797
                    }
                },
                {
                    "thumb0": {
                        "x": 0.012359930202364922,
                        "y": 1.3471028804779053,
                        "z": -0.24486905336380005
                    },
                    "index0": {
                        "x": 0.10180846601724625,
                        "y": 1.3423391580581665,
                        "z": -0.21659192442893982
                    },
                    "middle0": {
                        "x": 0.11835538595914841,
                        "y": 1.3366405963897705,
                        "z": -0.20731262862682343
                    },
                    "ring0": {
                        "x": 0.11851518601179123,
                        "y": 1.3240233659744263,
                        "z": -0.1935170292854309
                    },
                    "pinky0": {
                        "x": 0.10182011127471924,
                        "y": 1.3139944076538086,
                        "z": -0.16819429397583008
                    },
                    "thumb1": {
                        "x": 0.19919143617153168,
                        "y": 1.343870997428894,
                        "z": -0.08174126595258713
                    },
                    "index1": {
                        "x": 0.1368163824081421,
                        "y": 1.322485089302063,
                        "z": -0.13938933610916138
                    },
                    "middle1": {
                        "x": 0.11613157391548157,
                        "y": 1.3208264112472534,
                        "z": -0.1399952918291092
                    },
                    "ring1": {
                        "x": 0.10400182008743286,
                        "y": 1.3091604709625244,
                        "z": -0.12806254625320435
                    },
                    "pinky1": {
                        "x": 0.09796177595853806,
                        "y": 1.2975300550460815,
                        "z": -0.09912165254354477
                    }
                },
                {
                    "thumb0": {
                        "x": 0.008400579914450645,
                        "y": 1.3553704023361206,
                        "z": -0.2420029640197754
                    },
                    "index0": {
                        "x": 0.09901700913906097,
                        "y": 1.3495289087295532,
                        "z": -0.2200310230255127
                    },
                    "middle0": {
                        "x": 0.11791379004716873,
                        "y": 1.3432245254516602,
                        "z": -0.20672060549259186
                    },
                    "ring0": {
                        "x": 0.1192721277475357,
                        "y": 1.3275766372680664,
                        "z": -0.19244864583015442
                    },
                    "pinky0": {
                        "x": 0.1003727912902832,
                        "y": 1.316219687461853,
                        "z": -0.16729006171226501
                    },
                    "thumb1": {
                        "x": 0.16307248175144196,
                        "y": 1.379043459892273,
                        "z": -0.05616062879562378
                    },
                    "index1": {
                        "x": 0.13776928186416626,
                        "y": 1.3614325523376465,
                        "z": -0.11112968623638153
                    },
                    "middle1": {
                        "x": 0.1250099241733551,
                        "y": 1.3456313610076904,
                        "z": -0.12276016920804977
                    },
                    "ring1": {
                        "x": 0.11821134388446808,
                        "y": 1.3245420455932617,
                        "z": -0.12078648805618286
                    },
                    "pinky1": {
                        "x": 0.12088298797607422,
                        "y": 1.3006683588027954,
                        "z": -0.10398086905479431
                    }
                },
                {
                    "thumb0": {
                        "x": 0.002828007098287344,
                        "y": 1.3651403188705444,
                        "z": -0.23357483744621277
                    },
                    "index0": {
                        "x": 0.08874119818210602,
                        "y": 1.3585431575775146,
                        "z": -0.21896091103553772
                    },
                    "middle0": {
                        "x": 0.10736329853534698,
                        "y": 1.3528976440429688,
                        "z": -0.20494773983955383
                    },
                    "ring0": {
                        "x": 0.10946840047836304,
                        "y": 1.337571144104004,
                        "z": -0.19152039289474487
                    },
                    "pinky0": {
                        "x": 0.09255921095609665,
                        "y": 1.3223793506622314,
                        "z": -0.16759392619132996
                    },
                    "thumb1": {
                        "x": 0.13822732865810394,
                        "y": 1.4032138586044312,
                        "z": -0.04642561823129654
                    },
                    "index1": {
                        "x": 0.13606756925582886,
                        "y": 1.4008687734603882,
                        "z": -0.09735998511314392
                    },
                    "middle1": {
                        "x": 0.13154175877571106,
                        "y": 1.3830510377883911,
                        "z": -0.11028066277503967
                    },
                    "ring1": {
                        "x": 0.13456687331199646,
                        "y": 1.3638861179351807,
                        "z": -0.1170150563120842
                    },
                    "pinky1": {
                        "x": 0.1492258608341217,
                        "y": 1.3435688018798828,
                        "z": -0.11271853744983673
                    }
                },
                {
                    "thumb0": {
                        "x": -0.010752922855317593,
                        "y": 1.384040117263794,
                        "z": -0.21967670321464539
                    },
                    "index0": {
                        "x": 0.07252361625432968,
                        "y": 1.372910499572754,
                        "z": -0.22442734241485596
                    },
                    "middle0": {
                        "x": 0.09316267818212509,
                        "y": 1.3650875091552734,
                        "z": -0.21329763531684875
                    },
                    "ring0": {
                        "x": 0.09660032391548157,
                        "y": 1.349892497062683,
                        "z": -0.2003675103187561
                    },
                    "pinky0": {
                        "x": 0.08164016902446747,
                        "y": 1.3345470428466797,
                        "z": -0.1760696917772293
                    },
                    "thumb1": {
                        "x": 0.12754985690116882,
                        "y": 1.3959286212921143,
                        "z": -0.09535259753465652
                    },
                    "index1": {
                        "x": 0.12539009749889374,
                        "y": 1.3935834169387817,
                        "z": -0.14628693461418152
                    },
                    "middle1": {
                        "x": 0.12086429446935654,
                        "y": 1.3757658004760742,
                        "z": -0.15920765697956085
                    },
                    "ring1": {
                        "x": 0.12388941645622253,
                        "y": 1.3566007614135742,
                        "z": -0.1659420132637024
                    },
                    "pinky1": {
                        "x": 0.13854840397834778,
                        "y": 1.336283564567566,
                        "z": -0.16164544224739075
                    }
                },
                {
                    "thumb0": {
                        "x": -0.0213927049189806,
                        "y": 1.4216814041137695,
                        "z": -0.1322736293077469
                    },
                    "index0": {
                        "x": 0.017399776726961136,
                        "y": 1.435024380683899,
                        "z": -0.19556182622909546
                    },
                    "middle0": {
                        "x": 0.03675825893878937,
                        "y": 1.4225178956985474,
                        "z": -0.2103610336780548
                    },
                    "ring0": {
                        "x": 0.0432799831032753,
                        "y": 1.4010989665985107,
                        "z": -0.21564045548439026
                    },
                    "pinky0": {
                        "x": 0.03854631632566452,
                        "y": 1.3709352016448975,
                        "z": -0.21115057170391083
                    },
                    "thumb1": {
                        "x": 0.10696657001972198,
                        "y": 1.4224756956100464,
                        "z": -0.06916990876197815
                    },
                    "index1": {
                        "x": 0.12956327199935913,
                        "y": 1.45713210105896,
                        "z": -0.12747353315353394
                    },
                    "middle1": {
                        "x": 0.1369536966085434,
                        "y": 1.4579267501831055,
                        "z": -0.1484586000442505
                    },
                    "ring1": {
                        "x": 0.1582362949848175,
                        "y": 1.446977972984314,
                        "z": -0.15910710394382477
                    },
                    "pinky1": {
                        "x": 0.17335332930088043,
                        "y": 1.4137458801269531,
                        "z": -0.16187559068202972
                    }
                },
                {
                    "thumb0": {
                        "x": -0.029658153653144836,
                        "y": 1.4320696592330933,
                        "z": -0.12938955426216125
                    },
                    "index0": {
                        "x": -0.040893398225307465,
                        "y": 1.481466293334961,
                        "z": -0.16849568486213684
                    },
                    "middle0": {
                        "x": -0.0375484861433506,
                        "y": 1.4776681661605835,
                        "z": -0.19493423402309418
                    },
                    "ring0": {
                        "x": -0.04279140755534172,
                        "y": 1.4660617113113403,
                        "z": -0.21250499784946442
                    },
                    "pinky0": {
                        "x": -0.04784461110830307,
                        "y": 1.4322185516357422,
                        "z": -0.22305744886398315
                    },
                    "thumb1": {
                        "x": 0.10277310758829117,
                        "y": 1.4403423070907593,
                        "z": -0.08877776563167572
                    },
                    "index1": {
                        "x": 0.1283741593360901,
                        "y": 1.4874370098114014,
                        "z": -0.13916465640068054
                    },
                    "middle1": {
                        "x": 0.13514412939548492,
                        "y": 1.4883478879928589,
                        "z": -0.1621459722518921
                    },
                    "ring1": {
                        "x": 0.1545541137456894,
                        "y": 1.4774552583694458,
                        "z": -0.17370952665805817
                    },
                    "pinky1": {
                        "x": 0.16915950179100037,
                        "y": 1.441980242729187,
                        "z": -0.1824846714735031
                    }
                },
                {
                    "thumb0": {
                        "x": -0.05142549052834511,
                        "y": 1.463862419128418,
                        "z": -0.12769168615341187
                    },
                    "index0": {
                        "x": -0.06831762939691544,
                        "y": 1.5161436796188354,
                        "z": -0.16045856475830078
                    },
                    "middle0": {
                        "x": -0.07100323587656021,
                        "y": 1.5148714780807495,
                        "z": -0.1861603856086731
                    },
                    "ring0": {
                        "x": -0.07571880519390106,
                        "y": 1.5020344257354736,
                        "z": -0.20408877730369568
                    },
                    "pinky0": {
                        "x": -0.08126239478588104,
                        "y": 1.4682080745697021,
                        "z": -0.21850532293319702
                    },
                    "thumb1": {
                        "x": 0.09750351309776306,
                        "y": 1.4636305570602417,
                        "z": -0.09712674468755722
                    },
                    "index1": {
                        "x": 0.12292063981294632,
                        "y": 1.514808177947998,
                        "z": -0.14721733331680298
                    },
                    "middle1": {
                        "x": 0.12976911664009094,
                        "y": 1.5149431228637695,
                        "z": -0.1724538505077362
                    },
                    "ring1": {
                        "x": 0.15010055899620056,
                        "y": 1.5051020383834839,
                        "z": -0.18481862545013428
                    },
                    "pinky1": {
                        "x": 0.16501976549625397,
                        "y": 1.468522310256958,
                        "z": -0.1964472532272339
                    }
                },
                {
                    "thumb0": {
                        "x": -0.06633088737726212,
                        "y": 1.487500786781311,
                        "z": -0.13650187849998474
                    },
                    "index0": {
                        "x": -0.08700503408908844,
                        "y": 1.5383793115615845,
                        "z": -0.16774265468120575
                    },
                    "middle0": {
                        "x": -0.09171755611896515,
                        "y": 1.5398660898208618,
                        "z": -0.1923600435256958
                    },
                    "ring0": {
                        "x": -0.09712082147598267,
                        "y": 1.5272663831710815,
                        "z": -0.2119101881980896
                    },
                    "pinky0": {
                        "x": -0.10471807420253754,
                        "y": 1.496494174003601,
                        "z": -0.2274370640516281
                    },
                    "thumb1": {
                        "x": 0.08678154647350311,
                        "y": 1.4760717153549194,
                        "z": -0.09758870303630829
                    },
                    "index1": {
                        "x": 0.1101139634847641,
                        "y": 1.5288807153701782,
                        "z": -0.14781154692173004
                    },
                    "middle1": {
                        "x": 0.11526237428188324,
                        "y": 1.5313316583633423,
                        "z": -0.173527330160141
                    },
                    "ring1": {
                        "x": 0.13418491184711456,
                        "y": 1.5223009586334229,
                        "z": -0.1884823590517044
                    },
                    "pinky1": {
                        "x": 0.1517004519701004,
                        "y": 1.48764967918396,
                        "z": -0.20346349477767944
                    }
                }
            ],
            "words": "view"
        },
        {
          "key": "4a08f949-57a7-7604-33d2-dfdc4315d0b1",
          "gesture": [
              {
                  "thumb0": {
                      "x": -0.08177201449871063,
                      "y": 1.512742042541504,
                      "z": -0.15137448906898499
                  },
                  "index0": {
                      "x": -0.1110219955444336,
                      "y": 1.5666406154632568,
                      "z": -0.16907572746276855
                  },
                  "middle0": {
                      "x": -0.12641561031341553,
                      "y": 1.5775007009506226,
                      "z": -0.19071951508522034
                  },
                  "ring0": {
                      "x": -0.13514180481433868,
                      "y": 1.5648938417434692,
                      "z": -0.21187734603881836
                  },
                  "pinky0": {
                      "x": -0.1445341557264328,
                      "y": 1.5339603424072266,
                      "z": -0.23201367259025574
                  },
                  "thumb1": {
                      "x": 0.07399855554103851,
                      "y": 1.5162760019302368,
                      "z": -0.12380672991275787
                  },
                  "index1": {
                      "x": 0.11427830904722214,
                      "y": 1.5808446407318115,
                      "z": -0.1313038170337677
                  },
                  "middle1": {
                      "x": 0.12190841883420944,
                      "y": 1.5863239765167236,
                      "z": -0.1534271389245987
                  },
                  "ring1": {
                      "x": 0.13948415219783783,
                      "y": 1.575137972831726,
                      "z": -0.1708204746246338
                  },
                  "pinky1": {
                      "x": 0.15705172717571259,
                      "y": 1.5441944599151611,
                      "z": -0.1900496482849121
                  }
              },
              {
                  "thumb0": {
                      "x": -0.08737495541572571,
                      "y": 1.5154948234558105,
                      "z": -0.1482263207435608
                  },
                  "index0": {
                      "x": -0.11526264995336533,
                      "y": 1.5682560205459595,
                      "z": -0.17073498666286469
                  },
                  "middle0": {
                      "x": -0.12356196343898773,
                      "y": 1.5749925374984741,
                      "z": -0.19287888705730438
                  },
                  "ring0": {
                      "x": -0.13133560121059418,
                      "y": 1.5625377893447876,
                      "z": -0.21363185346126556
                  },
                  "pinky0": {
                      "x": -0.1399628072977066,
                      "y": 1.5332298278808594,
                      "z": -0.23159095644950867
                  },
                  "thumb1": {
                      "x": 0.07767881453037262,
                      "y": 1.5182360410690308,
                      "z": -0.12043244391679764
                  },
                  "index1": {
                      "x": 0.11744259297847748,
                      "y": 1.5804556608200073,
                      "z": -0.1349315345287323
                  },
                  "middle1": {
                      "x": 0.12364659458398819,
                      "y": 1.5843050479888916,
                      "z": -0.1590084731578827
                  },
                  "ring1": {
                      "x": 0.13966931402683258,
                      "y": 1.5723263025283813,
                      "z": -0.176240473985672
                  },
                  "pinky1": {
                      "x": 0.15854738652706146,
                      "y": 1.5414621829986572,
                      "z": -0.1937023252248764
                  }
              },
              {
                  "thumb0": {
                      "x": -0.0886344462633133,
                      "y": 1.5140862464904785,
                      "z": -0.1470026969909668
                  },
                  "index0": {
                      "x": -0.11611378192901611,
                      "y": 1.5668015480041504,
                      "z": -0.17136836051940918
                  },
                  "middle0": {
                      "x": -0.12218878418207169,
                      "y": 1.5727475881576538,
                      "z": -0.19307827949523926
                  },
                  "ring0": {
                      "x": -0.1311405748128891,
                      "y": 1.5624240636825562,
                      "z": -0.21342933177947998
                  },
                  "pinky0": {
                      "x": -0.1404094099998474,
                      "y": 1.5342189073562622,
                      "z": -0.2311006784439087
                  },
                  "thumb1": {
                      "x": 0.07955679297447205,
                      "y": 1.5176372528076172,
                      "z": -0.11907535791397095
                  },
                  "index1": {
                      "x": 0.11810743808746338,
                      "y": 1.5788370370864868,
                      "z": -0.13630428910255432
                  },
                  "middle1": {
                      "x": 0.12485185265541077,
                      "y": 1.5832833051681519,
                      "z": -0.16020220518112183
                  },
                  "ring1": {
                      "x": 0.1399516612291336,
                      "y": 1.5708614587783813,
                      "z": -0.17811870574951172
                  },
                  "pinky1": {
                      "x": 0.15873168408870697,
                      "y": 1.5399655103683472,
                      "z": -0.19533877074718475
                  }
              },
              {
                  "thumb0": {
                      "x": -0.08612854778766632,
                      "y": 1.492275595664978,
                      "z": -0.13733837008476257
                  },
                  "index0": {
                      "x": -0.1074385941028595,
                      "y": 1.541553020477295,
                      "z": -0.1753961145877838
                  },
                  "middle0": {
                      "x": -0.10694613307714462,
                      "y": 1.5438315868377686,
                      "z": -0.19802838563919067
                  },
                  "ring0": {
                      "x": -0.11230667680501938,
                      "y": 1.5325037240982056,
                      "z": -0.21732212603092194
                  },
                  "pinky0": {
                      "x": -0.11728815734386444,
                      "y": 1.503926396369934,
                      "z": -0.23075386881828308
                  },
                  "thumb1": {
                      "x": 0.09190744906663895,
                      "y": 1.507025122642517,
                      "z": -0.11792006343603134
                  },
                  "index1": {
                      "x": 0.1306944042444229,
                      "y": 1.5639055967330933,
                      "z": -0.14550960063934326
                  },
                  "middle1": {
                      "x": 0.1362450122833252,
                      "y": 1.565207600593567,
                      "z": -0.1709691435098648
                  },
                  "ring1": {
                      "x": 0.14960098266601562,
                      "y": 1.5503779649734497,
                      "z": -0.18838892877101898
                  },
                  "pinky1": {
                      "x": 0.16681624948978424,
                      "y": 1.5174931287765503,
                      "z": -0.2026589810848236
                  }
              },
              {
                  "thumb0": {
                      "x": -0.09068558365106583,
                      "y": 1.4757657051086426,
                      "z": -0.14301258325576782
                  },
                  "index0": {
                      "x": -0.10214885324239731,
                      "y": 1.5154318809509277,
                      "z": -0.19609957933425903
                  },
                  "middle0": {
                      "x": -0.09509038180112839,
                      "y": 1.5115917921066284,
                      "z": -0.21815404295921326
                  },
                  "ring0": {
                      "x": -0.09538332372903824,
                      "y": 1.496232032775879,
                      "z": -0.23456290364265442
                  },
                  "pinky0": {
                      "x": -0.10031601786613464,
                      "y": 1.4665025472640991,
                      "z": -0.24232041835784912
                  },
                  "thumb1": {
                      "x": 0.11523386836051941,
                      "y": 1.5036157369613647,
                      "z": -0.123304083943367
                  },
                  "index1": {
                      "x": 0.14851394295692444,
                      "y": 1.5455124378204346,
                      "z": -0.17168207466602325
                  },
                  "middle1": {
                      "x": 0.14582817256450653,
                      "y": 1.540398120880127,
                      "z": -0.1960354894399643
                  },
                  "ring1": {
                      "x": 0.15398657321929932,
                      "y": 1.522422432899475,
                      "z": -0.20976901054382324
                  },
                  "pinky1": {
                      "x": 0.16833536326885223,
                      "y": 1.488287091255188,
                      "z": -0.21783417463302612
                  }
              },
              {
                  "thumb0": {
                      "x": -0.10359129309654236,
                      "y": 1.4723336696624756,
                      "z": -0.14305320382118225
                  },
                  "index0": {
                      "x": -0.11216065287590027,
                      "y": 1.5077275037765503,
                      "z": -0.20209351181983948
                  },
                  "middle0": {
                      "x": -0.10240083187818527,
                      "y": 1.5012166500091553,
                      "z": -0.22403201460838318
                  },
                  "ring0": {
                      "x": -0.100983627140522,
                      "y": 1.4852166175842285,
                      "z": -0.23813867568969727
                  },
                  "pinky0": {
                      "x": -0.105520099401474,
                      "y": 1.4558918476104736,
                      "z": -0.24351388216018677
                  },
                  "thumb1": {
                      "x": 0.12866032123565674,
                      "y": 1.4979603290557861,
                      "z": -0.13196004927158356
                  },
                  "index1": {
                      "x": 0.15029415488243103,
                      "y": 1.52243173122406,
                      "z": -0.1922004222869873
                  },
                  "middle1": {
                      "x": 0.14213646948337555,
                      "y": 1.513295292854309,
                      "z": -0.21303175389766693
                  },
                  "ring1": {
                      "x": 0.1484166383743286,
                      "y": 1.493942141532898,
                      "z": -0.22400866448879242
                  },
                  "pinky1": {
                      "x": 0.16519823670387268,
                      "y": 1.4602168798446655,
                      "z": -0.22710175812244415
                  }
              },
              {
                  "thumb0": {
                      "x": -0.10121400654315948,
                      "y": 1.4693987369537354,
                      "z": -0.1412285566329956
                  },
                  "index0": {
                      "x": -0.10611407458782196,
                      "y": 1.503893256187439,
                      "z": -0.2017745077610016
                  },
                  "middle0": {
                      "x": -0.09472265839576721,
                      "y": 1.495855450630188,
                      "z": -0.22333873808383942
                  },
                  "ring0": {
                      "x": -0.09261737018823624,
                      "y": 1.4796817302703857,
                      "z": -0.23657070100307465
                  },
                  "pinky0": {
                      "x": -0.09750280529260635,
                      "y": 1.4508908987045288,
                      "z": -0.24102239310741425
                  },
                  "thumb1": {
                      "x": 0.12986153364181519,
                      "y": 1.4997307062149048,
                      "z": -0.13234463334083557
                  },
                  "index1": {
                      "x": 0.14988933503627777,
                      "y": 1.524498462677002,
                      "z": -0.1910320371389389
                  },
                  "middle1": {
                      "x": 0.14264294505119324,
                      "y": 1.5160589218139648,
                      "z": -0.21190211176872253
                  },
                  "ring1": {
                      "x": 0.15085344016551971,
                      "y": 1.4993788003921509,
                      "z": -0.22224363684654236
                  },
                  "pinky1": {
                      "x": 0.16910859942436218,
                      "y": 1.4686418771743774,
                      "z": -0.22443166375160217
                  }
              },
              {
                  "thumb0": {
                      "x": -0.09741440415382385,
                      "y": 1.4706908464431763,
                      "z": -0.1400916427373886
                  },
                  "index0": {
                      "x": -0.10081087052822113,
                      "y": 1.505514144897461,
                      "z": -0.20041468739509583
                  },
                  "middle0": {
                      "x": -0.08967393636703491,
                      "y": 1.497086524963379,
                      "z": -0.22204242646694183
                  },
                  "ring0": {
                      "x": -0.08808877319097519,
                      "y": 1.481065273284912,
                      "z": -0.23515790700912476
                  },
                  "pinky0": {
                      "x": -0.09438814967870712,
                      "y": 1.4535030126571655,
                      "z": -0.2399338185787201
                  },
                  "thumb1": {
                      "x": 0.1249716505408287,
                      "y": 1.5010043382644653,
                      "z": -0.13255417346954346
                  },
                  "index1": {
                      "x": 0.1434791088104248,
                      "y": 1.5256073474884033,
                      "z": -0.19155645370483398
                  },
                  "middle1": {
                      "x": 0.13602885603904724,
                      "y": 1.5179438591003418,
                      "z": -0.21208876371383667
                  },
                  "ring1": {
                      "x": 0.14372140169143677,
                      "y": 1.5014550685882568,
                      "z": -0.22269167006015778
                  },
                  "pinky1": {
                      "x": 0.1626117080450058,
                      "y": 1.4703227281570435,
                      "z": -0.2260514497756958
                  }
              },
              {
                  "thumb0": {
                      "x": -0.09702622890472412,
                      "y": 1.4714171886444092,
                      "z": -0.14017203450202942
                  },
                  "index0": {
                      "x": -0.09902622550725937,
                      "y": 1.5063669681549072,
                      "z": -0.2003059685230255
                  },
                  "middle0": {
                      "x": -0.08840376883745193,
                      "y": 1.4982120990753174,
                      "z": -0.22216427326202393
                  },
                  "ring0": {
                      "x": -0.08674456179141998,
                      "y": 1.4822534322738647,
                      "z": -0.23537828028202057
                  },
                  "pinky0": {
                      "x": -0.09337712079286575,
                      "y": 1.4548572301864624,
                      "z": -0.24096930027008057
                  },
                  "thumb1": {
                      "x": 0.11596260219812393,
                      "y": 1.4365565776824951,
                      "z": -0.18862226605415344
                  },
                  "index1": {
                      "x": 0.07161686569452286,
                      "y": 1.3880006074905396,
                      "z": -0.2347869724035263
                  },
                  "middle1": {
                      "x": 0.05109743773937225,
                      "y": 1.377611756324768,
                      "z": -0.2266998291015625
                  },
                  "ring1": {
                      "x": 0.0477776974439621,
                      "y": 1.3633804321289062,
                      "z": -0.21273109316825867
                  },
                  "pinky1": {
                      "x": 0.059561826288700104,
                      "y": 1.345107913017273,
                      "z": -0.19080188870429993
                  }
              },
              {
                  "thumb0": {
                      "x": -0.09315218031406403,
                      "y": 1.4496177434921265,
                      "z": -0.14591695368289948
                  },
                  "index0": {
                      "x": -0.0523817203938961,
                      "y": 1.4509810209274292,
                      "z": -0.20246680080890656
                  },
                  "middle0": {
                      "x": -0.03348113223910332,
                      "y": 1.4374923706054688,
                      "z": -0.2085522562265396
                  },
                  "ring0": {
                      "x": -0.026020752266049385,
                      "y": 1.4191112518310547,
                      "z": -0.20894165337085724
                  },
                  "pinky0": {
                      "x": -0.03131705895066261,
                      "y": 1.3923981189727783,
                      "z": -0.2027243673801422
                  },
                  "thumb1": {
                      "x": 0.10048529505729675,
                      "y": 1.3519543409347534,
                      "z": -0.20685185492038727
                  },
                  "index1": {
                      "x": 0.026296555995941162,
                      "y": 1.2982782125473022,
                      "z": -0.21756881475448608
                  },
                  "middle1": {
                      "x": 0.008458761498332024,
                      "y": 1.2913181781768799,
                      "z": -0.2061721682548523
                  },
                  "ring1": {
                      "x": 0.00821620412170887,
                      "y": 1.2796733379364014,
                      "z": -0.19197261333465576
                  },
                  "pinky1": {
                      "x": 0.024396441876888275,
                      "y": 1.272638201713562,
                      "z": -0.17043691873550415
                  }
              },
              {
                  "thumb0": {
                      "x": -0.06860222667455673,
                      "y": 1.3566020727157593,
                      "z": -0.1442771852016449
                  },
                  "index0": {
                      "x": 0.000619212631136179,
                      "y": 1.3383255004882812,
                      "z": -0.1536380648612976
                  },
                  "middle0": {
                      "x": 0.017015669494867325,
                      "y": 1.3276777267456055,
                      "z": -0.14236214756965637
                  },
                  "ring0": {
                      "x": 0.020611876621842384,
                      "y": 1.3126674890518188,
                      "z": -0.13265205919742584
                  },
                  "pinky0": {
                      "x": 0.009262409061193466,
                      "y": 1.293936848640442,
                      "z": -0.11933714896440506
                  },
                  "thumb1": {
                      "x": 0.10788661986589432,
                      "y": 1.3403829336166382,
                      "z": -0.2052782028913498
                  },
                  "index1": {
                      "x": 0.02744932286441326,
                      "y": 1.2991827726364136,
                      "z": -0.21554188430309296
                  },
                  "middle1": {
                      "x": 0.010378793813288212,
                      "y": 1.2942289113998413,
                      "z": -0.2071474939584732
                  },
                  "ring1": {
                      "x": 0.010780639946460724,
                      "y": 1.2847377061843872,
                      "z": -0.1970689594745636
                  },
                  "pinky1": {
                      "x": 0.02311772294342518,
                      "y": 1.2743905782699585,
                      "z": -0.17184093594551086
                  }
              },
              {
                  "thumb0": {
                      "x": -0.06523758918046951,
                      "y": 1.2741782665252686,
                      "z": -0.14367392659187317
                  },
                  "index0": {
                      "x": 0.008597475476562977,
                      "y": 1.271403431892395,
                      "z": -0.13039296865463257
                  },
                  "middle0": {
                      "x": 0.023731298744678497,
                      "y": 1.2704994678497314,
                      "z": -0.11723291128873825
                  },
                  "ring0": {
                      "x": 0.02550540491938591,
                      "y": 1.2648484706878662,
                      "z": -0.1020146831870079
                  },
                  "pinky0": {
                      "x": 0.014839444309473038,
                      "y": 1.2485071420669556,
                      "z": -0.0866243839263916
                  },
                  "thumb1": {
                      "x": 0.10610099136829376,
                      "y": 1.3428925275802612,
                      "z": -0.20452651381492615
                  },
                  "index1": {
                      "x": 0.023825453594326973,
                      "y": 1.3061503171920776,
                      "z": -0.2149234265089035
                  },
                  "middle1": {
                      "x": 0.006528770551085472,
                      "y": 1.3018803596496582,
                      "z": -0.20758134126663208
                  },
                  "ring1": {
                      "x": 0.00620555505156517,
                      "y": 1.2921490669250488,
                      "z": -0.19663843512535095
                  },
                  "pinky1": {
                      "x": 0.018173905089497566,
                      "y": 1.2806754112243652,
                      "z": -0.1724870800971985
                  }
              },
              {
                  "thumb0": {
                      "x": -0.005968318320810795,
                      "y": 1.3195178508758545,
                      "z": -0.15504947304725647
                  },
                  "index0": {
                      "x": 0.06508591771125793,
                      "y": 1.3346096277236938,
                      "z": -0.14077350497245789
                  },
                  "middle0": {
                      "x": 0.08221343159675598,
                      "y": 1.334917664527893,
                      "z": -0.1294550895690918
                  },
                  "ring0": {
                      "x": 0.0864725112915039,
                      "y": 1.3273800611495972,
                      "z": -0.11591313034296036
                  },
                  "pinky0": {
                      "x": 0.07746975123882294,
                      "y": 1.310249924659729,
                      "z": -0.09945735335350037
                  },
                  "thumb1": {
                      "x": 0.0999898910522461,
                      "y": 1.3410215377807617,
                      "z": -0.20105430483818054
                  },
                  "index1": {
                      "x": 0.01723511703312397,
                      "y": 1.305124044418335,
                      "z": -0.2105344831943512
                  },
                  "middle1": {
                      "x": 0.00007048435509204865,
                      "y": 1.3005056381225586,
                      "z": -0.20344334840774536
                  },
                  "ring1": {
                      "x": -0.0006257845088839531,
                      "y": 1.290164828300476,
                      "z": -0.1920776069164276
                  },
                  "pinky1": {
                      "x": 0.011062964797019958,
                      "y": 1.2789143323898315,
                      "z": -0.1672356277704239
                  }
              },
              {
                  "thumb0": {
                      "x": 0.06674716621637344,
                      "y": 1.4480646848678589,
                      "z": -0.1747952103614807
                  },
                  "index0": {
                      "x": 0.13242079317569733,
                      "y": 1.4830271005630493,
                      "z": -0.1514616310596466
                  },
                  "middle0": {
                      "x": 0.14801868796348572,
                      "y": 1.4871480464935303,
                      "z": -0.14075565338134766
                  },
                  "ring0": {
                      "x": 0.15604828298091888,
                      "y": 1.4770272970199585,
                      "z": -0.12975946068763733
                  },
                  "pinky0": {
                      "x": 0.15167829394340515,
                      "y": 1.4553982019424438,
                      "z": -0.115321084856987
                  },
                  "thumb1": {
                      "x": 0.08592426776885986,
                      "y": 1.3373875617980957,
                      "z": -0.1978357434272766
                  },
                  "index1": {
                      "x": 0.008346798829734325,
                      "y": 1.304592490196228,
                      "z": -0.20840764045715332
                  },
                  "middle1": {
                      "x": -0.008564157411456108,
                      "y": 1.3014901876449585,
                      "z": -0.20018023252487183
                  },
                  "ring1": {
                      "x": -0.009843224659562111,
                      "y": 1.2914272546768188,
                      "z": -0.18853619694709778
                  },
                  "pinky1": {
                      "x": 0.0009547523222863674,
                      "y": 1.2804192304611206,
                      "z": -0.16217903792858124
                  }
              },
              {
                  "thumb0": {
                      "x": 0.07440724968910217,
                      "y": 1.493503451347351,
                      "z": -0.17256414890289307
                  },
                  "index0": {
                      "x": 0.13704778254032135,
                      "y": 1.5343544483184814,
                      "z": -0.14398610591888428
                  },
                  "middle0": {
                      "x": 0.151506245136261,
                      "y": 1.538722038269043,
                      "z": -0.13102716207504272
                  },
                  "ring0": {
                      "x": 0.15699541568756104,
                      "y": 1.528428077697754,
                      "z": -0.11853412538766861
                  },
                  "pinky0": {
                      "x": 0.1537424623966217,
                      "y": 1.5048543214797974,
                      "z": -0.1058788150548935
                  },
                  "thumb1": {
                      "x": 0.09960956126451492,
                      "y": 1.3106375932693481,
                      "z": -0.18880215287208557
                  },
                  "index1": {
                      "x": 0.022032063454389572,
                      "y": 1.27784264087677,
                      "z": -0.19937404990196228
                  },
                  "middle1": {
                      "x": 0.0051211221143603325,
                      "y": 1.2747403383255005,
                      "z": -0.1911466419696808
                  },
                  "ring1": {
                      "x": 0.003842055331915617,
                      "y": 1.2646772861480713,
                      "z": -0.17950263619422913
                  },
                  "pinky1": {
                      "x": 0.014640012755990028,
                      "y": 1.253669261932373,
                      "z": -0.153145432472229
                  }
              },
              {
                  "thumb0": {
                      "x": 0.049743227660655975,
                      "y": 1.4483329057693481,
                      "z": -0.1703205406665802
                  },
                  "index0": {
                      "x": 0.11670604348182678,
                      "y": 1.4849804639816284,
                      "z": -0.14495894312858582
                  },
                  "middle0": {
                      "x": 0.1303175389766693,
                      "y": 1.4898030757904053,
                      "z": -0.13003817200660706
                  },
                  "ring0": {
                      "x": 0.1351640820503235,
                      "y": 1.4794104099273682,
                      "z": -0.11678338050842285
                  },
                  "pinky0": {
                      "x": 0.12788647413253784,
                      "y": 1.4574204683303833,
                      "z": -0.10182975232601166
                  },
                  "thumb1": {
                      "x": 0.09960956126451492,
                      "y": 1.3106375932693481,
                      "z": -0.18880215287208557
                  },
                  "index1": {
                      "x": 0.022032063454389572,
                      "y": 1.27784264087677,
                      "z": -0.19937404990196228
                  },
                  "middle1": {
                      "x": 0.0051211221143603325,
                      "y": 1.2747403383255005,
                      "z": -0.1911466419696808
                  },
                  "ring1": {
                      "x": 0.003842055331915617,
                      "y": 1.2646772861480713,
                      "z": -0.17950263619422913
                  },
                  "pinky1": {
                      "x": 0.014640012755990028,
                      "y": 1.253669261932373,
                      "z": -0.153145432472229
                  }
              },
              {
                  "thumb0": {
                      "x": -0.049079716205596924,
                      "y": 1.3163748979568481,
                      "z": -0.1659010648727417
                  },
                  "index0": {
                      "x": 0.028990304097533226,
                      "y": 1.2953981161117554,
                      "z": -0.17896488308906555
                  },
                  "middle0": {
                      "x": 0.040496595203876495,
                      "y": 1.3242660760879517,
                      "z": -0.14786231517791748
                  },
                  "ring0": {
                      "x": 0.049004971981048584,
                      "y": 1.3110027313232422,
                      "z": -0.13241977989673615
                  },
                  "pinky0": {
                      "x": 0.03813965618610382,
                      "y": 1.295214295387268,
                      "z": -0.11114285886287689
                  },
                  "thumb1": {
                      "x": 0.1124640554189682,
                      "y": 1.3505969047546387,
                      "z": -0.19753497838974
                  },
                  "index1": {
                      "x": 0.04217829182744026,
                      "y": 1.3004947900772095,
                      "z": -0.22821149230003357
                  },
                  "middle1": {
                      "x": 0.021403511986136436,
                      "y": 1.289291262626648,
                      "z": -0.22138850390911102
                  },
                  "ring1": {
                      "x": 0.018501827493309975,
                      "y": 1.2793506383895874,
                      "z": -0.20700708031654358
                  },
                  "pinky1": {
                      "x": 0.024647044017910957,
                      "y": 1.2682578563690186,
                      "z": -0.17391958832740784
                  }
              },
              {
                  "thumb0": {
                      "x": -0.07584121823310852,
                      "y": 1.302260160446167,
                      "z": -0.14629226922988892
                  },
                  "index0": {
                      "x": -0.0029266513884067535,
                      "y": 1.2975631952285767,
                      "z": -0.14393839240074158
                  },
                  "middle0": {
                      "x": 0.014907650649547577,
                      "y": 1.2950890064239502,
                      "z": -0.13233476877212524
                  },
                  "ring0": {
                      "x": 0.021023260429501534,
                      "y": 1.2838207483291626,
                      "z": -0.11612054705619812
                  },
                  "pinky0": {
                      "x": 0.009109610691666603,
                      "y": 1.271613359451294,
                      "z": -0.09346181154251099
                  },
                  "thumb1": {
                      "x": 0.1260329633951187,
                      "y": 1.3447400331497192,
                      "z": -0.20603600144386292
                  },
                  "index1": {
                      "x": 0.04372343048453331,
                      "y": 1.3089032173156738,
                      "z": -0.22735030949115753
                  },
                  "middle1": {
                      "x": 0.024157028645277023,
                      "y": 1.3014434576034546,
                      "z": -0.22179925441741943
                  },
                  "ring1": {
                      "x": 0.0206511989235878,
                      "y": 1.291625738143921,
                      "z": -0.20831945538520813
                  },
                  "pinky1": {
                      "x": 0.029156740754842758,
                      "y": 1.2870630025863647,
                      "z": -0.1795434206724167
                  }
              },
              {
                  "thumb0": {
                      "x": -0.07082607597112656,
                      "y": 1.331208348274231,
                      "z": -0.15298596024513245
                  },
                  "index0": {
                      "x": 0.00553505914285779,
                      "y": 1.3263251781463623,
                      "z": -0.15445134043693542
                  },
                  "middle0": {
                      "x": 0.02400517649948597,
                      "y": 1.322334885597229,
                      "z": -0.1410159021615982
                  },
                  "ring0": {
                      "x": 0.028968481346964836,
                      "y": 1.3112951517105103,
                      "z": -0.12367439270019531
                  },
                  "pinky0": {
                      "x": 0.01804305613040924,
                      "y": 1.2972111701965332,
                      "z": -0.1016526147723198
                  },
                  "thumb1": {
                      "x": 0.12628428637981415,
                      "y": 1.3465861082077026,
                      "z": -0.20454604923725128
                  },
                  "index1": {
                      "x": 0.04149638116359711,
                      "y": 1.3172661066055298,
                      "z": -0.22188767790794373
                  },
                  "middle1": {
                      "x": 0.023078182712197304,
                      "y": 1.3118139505386353,
                      "z": -0.21732331812381744
                  },
                  "ring1": {
                      "x": 0.021165888756513596,
                      "y": 1.2991797924041748,
                      "z": -0.20762775838375092
                  },
                  "pinky1": {
                      "x": 0.030561158433556557,
                      "y": 1.2884896993637085,
                      "z": -0.18269093334674835
                  }
              },
              {
                  "thumb0": {
                      "x": -0.06047765538096428,
                      "y": 1.3584431409835815,
                      "z": -0.1487874984741211
                  },
                  "index0": {
                      "x": -0.00017638970166444778,
                      "y": 1.3374650478363037,
                      "z": -0.17755404114723206
                  },
                  "middle0": {
                      "x": 0.017318829894065857,
                      "y": 1.3240299224853516,
                      "z": -0.1705276072025299
                  },
                  "ring0": {
                      "x": 0.023078281432390213,
                      "y": 1.309590458869934,
                      "z": -0.15644507110118866
                  },
                  "pinky0": {
                      "x": 0.014699028804898262,
                      "y": 1.2923622131347656,
                      "z": -0.13927718997001648
                  },
                  "thumb1": {
                      "x": 0.12885822355747223,
                      "y": 1.3551886081695557,
                      "z": -0.20269495248794556
                  },
                  "index1": {
                      "x": 0.04522440582513809,
                      "y": 1.3304407596588135,
                      "z": -0.22217115759849548
                  },
                  "middle1": {
                      "x": 0.026392994448542595,
                      "y": 1.3251252174377441,
                      "z": -0.21751806139945984
                  },
                  "ring1": {
                      "x": 0.02380331978201866,
                      "y": 1.3123457431793213,
                      "z": -0.2072424441576004
                  },
                  "pinky1": {
                      "x": 0.03357866778969765,
                      "y": 1.2957230806350708,
                      "z": -0.1857960969209671
                  }
              },
              {
                  "thumb0": {
                      "x": -0.04258906841278076,
                      "y": 1.3818236589431763,
                      "z": -0.12699100375175476
                  },
                  "index0": {
                      "x": -0.006383390165865421,
                      "y": 1.3769208192825317,
                      "z": -0.1683359444141388
                  },
                  "middle0": {
                      "x": 0.007195379119366407,
                      "y": 1.3611990213394165,
                      "z": -0.17298558354377747
                  },
                  "ring0": {
                      "x": 0.01241026446223259,
                      "y": 1.3421953916549683,
                      "z": -0.17241805791854858
                  },
                  "pinky0": {
                      "x": 0.001747847069054842,
                      "y": 1.3214621543884277,
                      "z": -0.16937968134880066
                  },
                  "thumb1": {
                      "x": 0.1282786726951599,
                      "y": 1.3718475103378296,
                      "z": -0.19913478195667267
                  },
                  "index1": {
                      "x": 0.04800470918416977,
                      "y": 1.351114273071289,
                      "z": -0.22486048936843872
                  },
                  "middle1": {
                      "x": 0.029021769762039185,
                      "y": 1.3448512554168701,
                      "z": -0.2230694741010666
                  },
                  "ring1": {
                      "x": 0.025603946298360825,
                      "y": 1.3300915956497192,
                      "z": -0.2149444967508316
                  },
                  "pinky1": {
                      "x": 0.03327378258109093,
                      "y": 1.311004638671875,
                      "z": -0.19431553781032562
                  }
              },
              {
                  "thumb0": {
                      "x": -0.03667237609624863,
                      "y": 1.4024854898452759,
                      "z": -0.13557711243629456
                  },
                  "index0": {
                      "x": -0.010988305322825909,
                      "y": 1.4104880094528198,
                      "z": -0.17590048909187317
                  },
                  "middle0": {
                      "x": 0.002377808094024658,
                      "y": 1.39552903175354,
                      "z": -0.18383745849132538
                  },
                  "ring0": {
                      "x": 0.008423654362559319,
                      "y": 1.377972960472107,
                      "z": -0.18872737884521484
                  },
                  "pinky0": {
                      "x": -0.001653104554861784,
                      "y": 1.3567737340927124,
                      "z": -0.19027048349380493
                  },
                  "thumb1": {
                      "x": 0.12464408576488495,
                      "y": 1.3866559267044067,
                      "z": -0.19951850175857544
                  },
                  "index1": {
                      "x": 0.047823403030633926,
                      "y": 1.3707492351531982,
                      "z": -0.22649379074573517
                  },
                  "middle1": {
                      "x": 0.028330305591225624,
                      "y": 1.364412784576416,
                      "z": -0.22666725516319275
                  },
                  "ring1": {
                      "x": 0.024302052333950996,
                      "y": 1.3489142656326294,
                      "z": -0.21949397027492523
                  },
                  "pinky1": {
                      "x": 0.03176037222146988,
                      "y": 1.3277277946472168,
                      "z": -0.20010045170783997
                  }
              },
              {
                  "thumb0": {
                      "x": -0.03840051591396332,
                      "y": 1.4183305501937866,
                      "z": -0.12903331220149994
                  },
                  "index0": {
                      "x": -0.03151940554380417,
                      "y": 1.4471657276153564,
                      "z": -0.15973734855651855
                  },
                  "middle0": {
                      "x": -0.01994531974196434,
                      "y": 1.439163088798523,
                      "z": -0.17770753800868988
                  },
                  "ring0": {
                      "x": -0.0165236983448267,
                      "y": 1.4260929822921753,
                      "z": -0.1922500878572464
                  },
                  "pinky0": {
                      "x": -0.025011934340000153,
                      "y": 1.3999887704849243,
                      "z": -0.20196518301963806
                  },
                  "thumb1": {
                      "x": 0.13006141781806946,
                      "y": 1.4112367630004883,
                      "z": -0.19112475216388702
                  },
                  "index1": {
                      "x": 0.06372404843568802,
                      "y": 1.4005705118179321,
                      "z": -0.22813057899475098
                  },
                  "middle1": {
                      "x": 0.04246468469500542,
                      "y": 1.3897957801818848,
                      "z": -0.23307183384895325
                  },
                  "ring1": {
                      "x": 0.037493254989385605,
                      "y": 1.3709965944290161,
                      "z": -0.227870374917984
                  },
                  "pinky1": {
                      "x": 0.04622331261634827,
                      "y": 1.343996524810791,
                      "z": -0.21373432874679565
                  }
              }
          ],
          "words": "view"
      }          
    ]
} 